import './styles.scss';
import React, {Dispatch, useEffect, useRef, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";

/* Bootstrap */
import {Button, ButtonGroup, Form, InputGroup, OverlayTrigger, Tooltip} from "react-bootstrap";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {IViewerState} from "../../../../types/redux/viewer";
import {RootState} from "../../../../redux";
import {
  setCurrentSearchResult,
  setHighlightAllResults,
  setSearchPanelOpen,
  setSearchTerm
} from "../../../../redux/slices/viewer-slice";

const DocumentSearch = ():JSX.Element => {
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);
  const [input, setInput] = useState<string>('');
  const inputRef:React.MutableRefObject<any> = useRef<HTMLInputElement | null>();

  useEffect(()=>{
    return ()=>{
      dispatch(setSearchTerm(''));
      dispatch(setSearchPanelOpen(false));
    }
  }, [dispatch])

  /* set focus on input field */
  useEffect(()=> {
    if (inputRef.current && viewerState.searchPanelOpen) {
      inputRef.current.focus()
    }
    if (!viewerState.searchPanelOpen){
      dispatch(setSearchTerm(''));
      setInput('');
    }
  }, [viewerState.searchPanelOpen, dispatch])

  const handleSubmit = () => {
    inputRef.current.focus();
    inputRef.current.setSelectionRange(0,input.length);
    let query: string = input.trim();
    if (query.length > 0) {
      dispatch(setSearchTerm(query))
    }
  }

  const incrementSearchResult = (direction: number): void => {
    let incremented: number = viewerState.currentSearchResult + direction;
    if (incremented === -1) {
      incremented = viewerState.numSearchResults - 1
    } else if (incremented === viewerState.numSearchResults) {
      incremented = 0
    }
    dispatch(setCurrentSearchResult(incremented))
  }

  return (
    <div className={'document-search mx-2'}>
      <div>
        <OverlayTrigger overlay={
            <Tooltip className={viewerState.ocrAvailable ? 'hidden' : ''}>{Constants.TOOLTIP_PDF_SEARCH}</Tooltip>
        }>
          <span>
            <Button
              disabled={!viewerState.ocrAvailable || (viewerState.contentView !== Constants.PDF)}
              className={'text-black border-0 ' + (viewerState.searchPanelOpen ? '': 'bg-transparent')}
              onClick={()=>dispatch(setSearchPanelOpen(!viewerState.searchPanelOpen))}
            >
              <i className={'bi bi-search toggle-btn' + (viewerState.searchPanelOpen ? ' text-light': '')}/>
            </Button>
          </span>
        </OverlayTrigger>
      </div>

      <div className={'search-panel bg-light position-absolute py-2 px-3 border rounded shadow w-50' + (!viewerState.searchPanelOpen ? ' hidden': '')}>
        <div className={'d-flex '}>

          <InputGroup className={'term-input border rounded d-flex'}>
            <Form.Control
              autoFocus
              ref={inputRef}
              className={'border-0 rounded-start'}
              aria-describedby={'search-input'}
              placeholder={'search pdf'}
              value={input}
              onChange={e=>setInput(e.target.value)}
              onKeyDown={e=>{
                if (e.code === 'Enter') {
                  handleSubmit()
                }
              }}
            />
            <Button
              className={'search-btn bg-white text-black border-0 rounded-end'}
              onClick={handleSubmit}
            >
              <i className={'bi bi-search'}/>
            </Button>
          </InputGroup>

          <div className={'results d-flex '}>
            <div className={'text-nowrap px-3 pt-2'}>{(viewerState.numSearchResults === 0) ? 0 : viewerState.currentSearchResult + 1} of {viewerState.numSearchResults}</div>
            <ButtonGroup aria-label={'next previous'}>
              <Button
                variant={'light'}
                disabled={viewerState.numSearchResults === 0}
                className={'previous border pt-2'}
                onClick={()=>incrementSearchResult(-1)}
              >
                <i className={'bi bi-arrow-left'}/>
              </Button>
              <Button
                variant={'light'}
                className={'next border pt-2'}
                disabled={viewerState.numSearchResults === 0}
                onClick={()=>incrementSearchResult(1)}
              >
                <i className={'bi bi-arrow-right'}/>
              </Button>
            </ButtonGroup>

            <Form.Group className={"highlight-all ms-2 pe-1 mt-1 text-nowrap"} controlId="plain-text">
              <Form.Check
                className={''}
                type={'checkbox'}
                label={'Highlight All'}
                checked={viewerState.highlightAllResults}
                onChange={e=> dispatch(setHighlightAllResults(e.target.checked))}
              />

            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DocumentSearch;
