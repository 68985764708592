/* Types, Constants, Utils */
import {ISearchEntry} from "../../types/redux/search";
import * as Constants from "../../constants";

/* Redux */
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  ISavedState,
  TSavedBibliography,
  TSavedDocument,
  TSavedDocumentTag,
  UpdateSaveAction
} from "../../types/redux/saved";
import Utils from "../../utils";

const initialState = ():ISavedState => {
  return {
    documents: [],
    bibliographies: [],
    searches: [],
    history: []
  }
}

export const savedSlice = createSlice({
  name: UpdateSaveAction,
  initialState: initialState,
  reducers: {
    /* Documents */
    setSavedDocuments: (state: ISavedState, action: PayloadAction<TSavedDocument[]>) => {
      state.documents = action.payload;
    },

    addToSavedDocuments: (state: ISavedState, action: PayloadAction<TSavedDocument[]>) => {
      state.documents = [...state.documents, ...action.payload];
    },

    removeFromSavedDocuments: (state: ISavedState, action: PayloadAction<string[]>) => {
      state.documents = Utils.removeFromList(state.documents, action.payload, Constants.SAVED_DOCUMENT_KEY);
    },

    updateSavedDocumentTags: (state: ISavedState, action: PayloadAction<{savedDocumentIds: string[], savedTags: TSavedDocumentTag[], action: string }>) => {
      const savedDocumentIds: string[] = action.payload.savedDocumentIds;
      const savedTags: TSavedDocumentTag[] = action.payload.savedTags;
      const actionToTake: string = action.payload.action;

      let tagIndex: number;

      savedDocumentIds.forEach(savedDocumentId => {
        savedTags.forEach(tag => {
          const index: number | undefined = state.documents.findIndex(savedDocument => String(savedDocument.id) === savedDocumentId);
          if (index !== -1) {
            const clone: TSavedDocument = {...state.documents[index]};
            switch (actionToTake) {
              case Constants.ADD:
                clone.tags.push(tag)
                break;

              case Constants.UPDATE:
                tagIndex = clone.tags.findIndex(cloneTag => cloneTag.id === tag.id);
                if (tagIndex !== -1) {
                  clone.tags[tagIndex] = tag;
                }
                break;

              case Constants.REMOVE:
                tagIndex = clone.tags.findIndex(cloneTag => cloneTag.id === tag.id);
                if (tagIndex !== -1) {
                  clone.tags.splice(tagIndex, 1)
                }
                break;

            }
            state.documents.splice(index, 1, clone);
          }
        })
      })
    },

    updateSavedDocuments: (state: ISavedState, action: PayloadAction<TSavedDocument[]>) => {
      const documents: TSavedDocument[] = action.payload;
      documents.forEach((document:TSavedDocument) => {
        const index: number = state.documents.findIndex((savedDocument:TSavedDocument) => String(document.id) === String(savedDocument.id));
        if (index !== -1) {
          state.documents[index].notes = document.notes;
        }
      })
    },

    /* Bibliographies */
    updateSavedBibliographies: (state: ISavedState, action: PayloadAction<TSavedBibliography[]>) => {
      state.bibliographies = action.payload.map((bibliography: TSavedBibliography) => {
        return {
          id: bibliography.id,
          biblioId: String(bibliography.biblioId) /* backend converts to int but bibliography document id is string */
        }
      });
    },

    addToSavedBibliographies: (state: ISavedState, action: PayloadAction<TSavedBibliography[]>) => {
      const toSave: TSavedBibliography[] = action.payload.map((bibliography: TSavedBibliography) => {
        return {
          id: bibliography.id,
          biblioId: String(bibliography.biblioId) /* backend converts to int but bibliography document id is string */
        }
      });
      state.bibliographies = [...state.bibliographies, ...toSave];
    },

    removeFromSavedBibliographies: (state: ISavedState, action: PayloadAction<string[]>) => {
      state.bibliographies = Utils.removeFromList(state.bibliographies, action.payload, Constants.SAVED_BIBLIOGRAPHY_KEY);
    },

    /* Search History */
    addToSearchHistory: (state: ISavedState, action: PayloadAction<ISearchEntry>) => {
      state.history.unshift(action.payload)
    },

    /* Saved Searches*/
    updateSavedSearches: (state: ISavedState, action: PayloadAction<ISearchEntry[]>) => {
      state.searches = action.payload;
    },

    addToSavedSearch: (state: ISavedState, action: PayloadAction<ISearchEntry>) => {
      state.searches.unshift(action.payload)
    },

    removeFromSavedSearches: (state: ISavedState, action: PayloadAction<string[]>) => {
      state.searches = Utils.removeFromList(state.searches, action.payload, Constants.ID);;
    },

    addToSavedHistory: (state: ISavedState, action: PayloadAction<ISearchEntry[]>) => {
      const historyCln: ISearchEntry[] = [...state.history];
      action.payload.forEach(updatedEntry => {
        const historyEntryToUpdate: ISearchEntry | undefined = historyCln.find((historyEntry: ISearchEntry) => {
          return historyEntry.searchDateTime === updatedEntry.searchDateTime
        });
        if (historyEntryToUpdate) {
          historyEntryToUpdate.id = updatedEntry.id; /* -1 => backend issued id on save search */
        }
      })
      state.history = historyCln;
    },

    removeFromSavedHistory: (state: ISavedState, action: PayloadAction<ISearchEntry[]>) => {
      action.payload.forEach(updatedEntry => {
        const historyEntryToUpdate: ISearchEntry | undefined = state.history.find((historyEntry: ISearchEntry) => historyEntry.searchDateTime === updatedEntry.searchDateTime);
        if (historyEntryToUpdate) {
          historyEntryToUpdate.id = -1; /* -1 => search not saved on backend */
        }
      })
    },

    clearAllSaved: (state: ISavedState) => {
      state = {...initialState()};
      return state;
    }
  }
});


export const {
  updateSavedDocuments,
  setSavedDocuments,
  addToSavedDocuments,
  removeFromSavedDocuments,
  updateSavedDocumentTags,
  updateSavedBibliographies,
  addToSavedBibliographies,
  removeFromSavedBibliographies,
  addToSearchHistory,
  addToSavedSearch,
  addToSavedHistory,
  removeFromSavedSearches,
  removeFromSavedHistory,
  updateSavedSearches,
  clearAllSaved,
} = savedSlice.actions;

export default savedSlice.reducer;
