import './styles.scss';
import React from 'react'

import RichText from '../RichText'
import {Accordion} from "react-bootstrap";

export const Collapse: React.FC<
  {
    fields: any
  }
> = props => {
  const {
    fields
  } = props

  return (
    <div className={'collapse-block my-2'}>
      <Accordion>
        <Accordion.Item eventKey={'0'}>
          <Accordion.Header><RichText content={fields.collapseToggle}/></Accordion.Header>
          <Accordion.Body><RichText content={fields.collapseContent}/></Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}
