import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ILoginModal, UpdateLoginModalAction} from "../../types/redux/login-modal";

const initialState: ILoginModal = {
  active: false
};

export const LoginModalSlice = createSlice({
  name: UpdateLoginModalAction,
  initialState: initialState,
  reducers: {
    setLoginModalActive: (state: ILoginModal, action: PayloadAction<boolean>) => {
     state.active = action.payload;
    }
  }
});

export const {
  setLoginModalActive
} = LoginModalSlice.actions;
export default LoginModalSlice.reducer;
