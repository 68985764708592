import './styles.scss';
import React, {useContext} from "react";

/* types, constants, utils */
import * as Constants from "../../../../../constants";
import {IViewerRecordContext, ViewerRecordContext} from "../../../../../types/document";

/* bootstrap */
import {Button} from "react-bootstrap";

/* components */
import ViewerCard from "../../../viewer-card";
import {IViewerState} from "../../../../../types/redux/viewer";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux";

interface IProps {
  handleSelect(document: any): void
  setNumOfRecords(value:number): void;
}

const ViewerDuplicatesList = (props: IProps):JSX.Element => {
  const {
    handleSelect
  } = props;

  const initialSelectedRecord: any = useContext<IViewerRecordContext>(ViewerRecordContext).initialRecord;
  const possibleDuplicates: any[] = useSelector<RootState, IViewerState>(state => state.viewer).possibleDuplicates;

  const card = (result: any, index: number):JSX.Element => {
    return <Button
      key={`dc-${index}`}
      className={'viewer-card-wrapper mb-2 text-dark p-0 bg-transparent text-start w-100'}
      active={initialSelectedRecord && (initialSelectedRecord.id === result.id)}
      onClick={()=>handleSelect(result)}
    >
      <ViewerCard
        index={index}
        record={result}
        page={1}
        resultsPerPage={Constants.VW_NUM_ROWS}
      />
    </Button>
  }

  return (
    <div className={'viewer-more-like-list overflow-scroll pt-2'}>
      <div className={'cards d-flex flex-column pb-2'} >

        {/* initial card */}
        {card(initialSelectedRecord, -1)}

        {possibleDuplicates.map((result: any, index: number) => {
          return card(result, index)
        })}
      </div>
    </div>
  )
}
export default ViewerDuplicatesList;
