import ApiUtils from "./api-utils";
import {AxiosResponse} from "axios";
import {idlAPIClient} from "./axios";

/* URLs */
const TAGS_URL: string = '/tags';

const updateTag = async (id: string, text: string):Promise<any> => {
  return ApiUtils.makePatch([{
    id,
    text
  }], TAGS_URL)
}

const applyTag = async (tagId: string, savedDocumentIds: any[]):Promise<any> => {
  return ApiUtils.makePatch([{
    id: tagId,
    savedDocuments: savedDocumentIds
  }], TAGS_URL)
}

const createTag = async (tag: string, savedDocumentIds: any[]):Promise<any> => {
  return ApiUtils.makePost([{
    text: tag,
    savedDocuments: savedDocumentIds
  }], TAGS_URL)
}

const getAllTags = async ():Promise<any> => {
  const token: string = ApiUtils.getToken();
  if (token) {
    const response: AxiosResponse<any, any> = await idlAPIClient.get<any>(TAGS_URL);
    return response.data;
  }
}

const getTags = async (savedDocumentIds: string[] | null = null):Promise<any> => {
  const token: string = ApiUtils.getToken();
  if (token) {
    const params: any = {};
    if (savedDocumentIds) {
      params.documentId = savedDocumentIds.join(',')
    }
    const response: AxiosResponse<any, any> = await idlAPIClient.get<any>(
      TAGS_URL,
      {params}
    );
    return response.data;
  }
}

const deleteTag = async (tagId: number, savedDocumentIds: string[] | null = null):Promise<any> => {
  let params: any;
  if (savedDocumentIds) {
    params = {savedDocumentIds: savedDocumentIds.join(',')};
  } else {
    params = null;
  }
  const url: string = TAGS_URL + '/' + String(tagId);
  return ApiUtils.doDelete(params, url)
}

const TagsNotesService = {
  applyTag,
  createTag,
  updateTag,
  getAllTags,
  getTags,
  deleteTag
}

export default TagsNotesService;
