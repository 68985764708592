/* Advanced Search */

export const ADVANCED_SEARCH: string  = 'advanced_search'; // see data/filters.json
export const SIMPLE_SEARCH: string    = 'simple_search'
export const OPERATOR: string         = 'operator';
export const FIELD: string            = 'field';
export const FIELD_SEARCH: string     = 'field_search';
export const MATCH: string            = 'match';
export const TERM: string             = 'term';
export const ALL: string              = 'all';
export const CONTAINS_EXACT: string   = 'contains-exact';
export const CONTAINS: string         = 'contains';
export const AND: string              = 'AND';
export const OR: string               = 'OR';
export const NOT: string              = 'NOT';
export const INVALID_DATE: string     = 'Invalid Date';

export const START_DATE_PLACEHOLDER: string   = 'Start Date';
export const END_DATE_PLACEHOLDER: string     = 'End Date';

export type TConstraint = {
  label: string | null
  value: string | null;
}

export type TFieldConstraint = {
  label: string;
  description: {
    text: string;
    example: string;
  } | null;
  value: string | null;
}

export type TAdvConstraint = {
  operator: TConstraint;
  field: TFieldConstraint;
  match: TConstraint;
  term:  string | null;
}

/*
export type TQueryItem = {
  operator: string | null;
  field: string | null;
  match: string | null;
  term:  string | null;
}
*/

export type TAdvSearchDate = {
  id: string,
  label: string,
  industry: string,
  field: string,
  start: string | null,
  end:  string | null,
}

export const CATEGORIES:string = 'categories';
export const ADV_SEARCH_DATES: TAdvSearchDate[] = [
  {
    id: 'doc_date',
    label: 'Document date',
    industry: 'all-industries',
    field: 'dddate',
    start: null,
    end: null
  },
  {
    id: 'date_added',
    label: 'Date added to UCSF',
    industry: 'all-industries',
    field: 'ddudate',
    start: null,
    end: null
  },
  {
    id: 'date_sent',
    label: 'Date sent',
    industry: 'opioids',
    field: 'datesentdate',
    start: null,
    end: null
  },
  {
    id: 'date_received',
    label: 'Date received',
    industry: 'opioids',
    field: 'datereceiveddate',
    start: null,
    end: null
  }
]

export const ADV_SEARCH_DROPDOWNS: any = {
  operator: [
    {
      label: 'AND',
      value: 'AND'
    },
    {
      label: 'OR',
      value: 'OR'
    },
    {
      label: 'NOT',
      value: 'NOT',
    }
  ],

  field: [
    {
      label: 'All Fields',
      description: null,
      value: 'all',
    },
    {
      label: 'Topic',
      description: {
        text: 'Topic describes subject(s) or keyword(s) of a document.',
        example: ' “Youth” and “"EVENT SPONSORSHIP"'
      },
      value: 'topic'
    },
    {
      label: 'Author',
      description: {
        text: 'Author lists the person(s) who initially created the document or the template used to create the document; it may not reflect the final or sole author of the document',
        example: null,
      },
      value: 'author'
    },
    {

      label: 'Document Type',
      description: {
        text: 'Document Type is the kind of document. Examples: letter, memo, report, “computer printout”, and video',
        example: null
      },
      value: 'type'
    },
    {
      label: 'Bates Number',
      description: {
        text: 'Bates Number describes an identifying number or alpha-numeric code assigned to a document.',
        example: 'MEW00012-0014, wlccbu020336-0342, 2070278438 and 524113724-524113725'
      },
      value: 'bates'
    }
  ],

  field_search: [
    {
      label: 'Access',
      description: {
        text: 'Access describes the location where material can be listened to, viewed, or ordered. In addition to digitized paper documents, the Industry Documents Library contains videos, audios, archived websites and other non-paper materials.',
        example: null
      },
      value: 'access'
    },
    {
      label: 'Adverse Ruling',
      description: {
        text: 'Adverse Ruling tells us whether a document\'s claimed privilege has been overruled by a court. This field may include a case title and action number, or the Bates Number for such a document',
        example: null
      },
      value: 'adverseruling'
    },
    {
      label: 'Area',
      description: {
        text: 'Area describes the physical location where a document was found in the offices of the providing tobacco company. Examples: “Advertising Archive” and "REUTER,BARBARA/OFFICE"',
        example: null
      },
      value: 'area'
    },
    {
      label: 'Ark',
      description: {
        text: 'Ark Identifier Ark Identfier',
        example: null
      },
      value: 'ark'
    },
    {
      label: 'Attachment',
      description: {
        text: 'Attachment lists the bates number of the attached document.',
        example: null
      },
      value: 'attachment'
    },
    {
      label: 'Attachment Number',
      description: {
        text: 'Attachment Number tells us the number of attachment documents.',
        example: null
      },
      value: 'attachmentnum'
    },
    {
      label: 'Attending',
      description: {
        text: 'Attending lists any person(s) or organization(s) present at a meeting mentioned in a document.',
        example: null
      },
      value: 'attending'
    },
    {
      label: 'Author',
      description: {
        text: 'Author lists the person(s) who initially created the document or the template used to create the document; it may not reflect the final or sole author of the document',
        example: null
      },
      value: 'author'
    },
    {
      label: 'Availability',
      description: {
        text: 'Availability tells us whether a document is viewable. Certain documents are not provided by companies because they are privileged or confidential. More information about these types of documents. Documents that are viewable will have the value “public”. Examples: “no restrictions; public” and “public; formerly confidential”',
        example: null
      },
      value: 'availability'
    },
    {
      label: 'Bates',
      description: {
        text: 'Bates Number describes an identifying number or alpha-numeric code assigned to a document. Examples are MEW00012-0014, wlccbu020336-0342, 2070278438 and 524113724-524113725',
        example: null
      },
      value: 'bates'
    },
    {
      label: 'Bates Alternate',
      description: {
        text: 'Bates Alternate describes an identifying number or alpha-numeric code assigned to a document, in addition to its Bates Number.',
        example: null
      },
      value: 'batesalternate'
    },
    {
      label: 'Bates Master',
      description: {
        text: 'Bates Master describes a range of Bates Numbers identifying a group of documents found attached to, or physically close to, each other during the discovery process. For example, the Bates Master of a letter with a report attached includes the range of Bates Numbers for both the letter and the report.',
        example: null
      },
      value: 'batesmaster'
    },
    {
      label: 'Box',
      description: {
        text: 'Box tells us the box number of a physical document, either in the Minnesota Depository (for tobacco industries documents) or in the source collection (for other industries documents).',
        example: null
      },
      value: 'box'
    },
    {
      label: 'Brand',
      description: {
        text: 'Brand lists the brand names of products mentioned in or associated with the document. Examples: Marlboro, Kool, Swisher, Grizzly and blu',
        example: null
      },
      value: 'brand'
    },
    {
      label: 'Case',
      description: {
        text: 'Case is an eight digit ID number and/or name of the court case for which a document was produced. For tobacco industries documents, the many identifiers and corresponding cases are listed on the Case Names page.',
        example: 'SKOLNICK, 10004290, “Hermans v Merck & Co.” and “State of Oklahoma vs. Purdue Pharma LP et al.; Case No. CJ-2017-816.”'
      },
      value: 'case'
    },
    {
      label: 'Chemical',
      description: {
        text: 'Chemical lists the names of chemical(s).',
        example: '"benzene" and "toluene"'
      },
      value: 'chemical'
    },
    {
      label: 'Cited',
      description: {
        text: 'Cited tells us if a document has been used in a scholarly article or publication.',
        example: 'cited:yes (searches for documents that have been previously cited) and cited:no (hides previously cited documents from your search)'
      },
      value: 'cited'
    },
    {
      label: 'Collection',
      description: {
        text: 'Collection is a group of documents produced during litigation, focused on a particular topic, or assembled by a certain person or organization.',
        example: '“Philip Morris”, “Trinkets and Trash Marketing” and “Prempro Products Liability”'
      },
      value: 'collection'
    },
    {
      label: 'Conversation',
      description: {
        text: 'Conversation specifies the email conversation designation.',
        example: null
      },
      value: 'conversation'
    },
    {
      label: 'Copied',
      description: {
        text: 'Copied tells us the person(s) or organization(s) copied on the document.',
        example: null
      },
      value: 'copied'
    },
    {
      label: 'Country',
      description: {
        text: 'Country lists the country or countries mentioned in a document.',
        example: 'Thailand, Guatemala and UK'
      },
      value: 'country'
    },
    {
      label: 'Court',
      description: {
        text: 'Court tells us the name of the court where document was filed.',
        example: '"UNITED STATES DISTRICT COURT FOR THE DISTRICT OF COLUMBIA"'
      },
      value: 'court'
    },
    {
      label: 'Custodian',
      description: {
        text: 'Custodian tells us the person or entity with original ownership, custody, or control over a specific document',
        example: null
      },
      value: 'custodian'
    },
    {
      label: 'Custodian',
      description: {
        text: 'Custodian tells us the person or entity with original ownership, custody, or control over a specific document',
        example: null
      },
      value: 'custodian'
    },
    {
      label: 'Date Added Industry',
      description: {
        text: 'Date Added Industry tells us the date a document was added to a tobacco industries document website. Format: YYYYMMDD',
        example: null
      },
      value: 'dateaddedindustry'
    },
    {
      label: 'Date Added UCSF',
      description: {
        text: 'Date Added UCSF tells us the date a document was added to this website. Format: YYYYMMDD',
        example: null
      },
      value: 'dateaddeducsf'
    },
    {
      label: 'Date Modified Industry',
      description: {
        text: 'Date Modified Industry tells us the date a document or its metadata information was changed or revised by the tobacco company. Format: YYYYMMDD',
        example: null
      },
      value: 'datemodifiedindustry'
    },
    {
      label: 'Date Modified UCSF',
      description: {
        text: 'Date Modified Industry tells us the date a document or its metadata information was changed or revised by the tobacco company. Format: YYYYMMDD',
        example: null
      },
      value: 'datemodifieducsf'
    },
    {
      label: 'Date Privilege Logged',
      description: {
        text: 'Date Privilege Logged tells us the date a document was first placed on a privilege log. Format: YYYYMMDD',
        example: null
      },
      value: 'dateprivilegelogged'
    },
    {
      label: 'Date Produced',
      description: {
        text: 'Date Produced tells us the date a document was first produced in litigation. Format: YYYYMMDD',
        example: null
      },
      value: 'dateproduced'
    },
    {
      label: 'Date Received',
      description: {
        text: 'Date Received tells us the date an e-mail was received.',
        example: null
      },
      value: 'datereceived'
    },
    {
      label: 'Date Sent',
      description: {
        text: 'Date Sent tells us the date an e-mail was sent.',
        example: null
      },
      value: 'datesent'
    },
    {
      label: 'Date Shipped',
      description: {
        text: 'Date Shipped tells us the date a document was sent to the Minnesota Depository. Format: YYYYMMDD',
        example: null
      },
      value: 'dateshipped'
    },
    {
      label: 'Deposition Date',
      description: {
        text: 'Deposition Date tells us the date a deposition was taken. Format: YYYYMMDD',
        example: null
      },
      value: 'depositiondate'
    },
    {
      label: 'Description',
      description: {
        text: 'Description contains notes about a document‘s content.',
        example: null
      },
      value: 'description'
    },
    {
      label: 'Document Date',
      description: {
        text: 'Document Date tells us the initial creation date of the document or of the template used to generate the document. For certain document types such as emails, this date may be inaccurate and user should look to Date Sent and/or verify on the document itself. Format: YYYYMMDD',
        example: null
      },
      value: 'documentdate'
    },
    {
      label: 'Drug',
      description: {
        text: 'Drug lists the brand or generic names of drug(s).',
        example: '"Zyprexa" and "gabapentin"'
      },
      value: 'drug'
    },
    {
      label: 'Exhibit Number',
      description: {
        text: 'Exhibit Number identifies documents listed as trial exhibits in certain cases.',
        example: '“10004026 05679” and “1597”'
      },
      value: 'exhibitnumber'
    },
    {
      label: 'File',
      description: {
        text: 'File tells us the title of the file folder in which a document was originally kept.',
        example: '“PM INC. OFFICER - DIRECTOR LISTINGS” and “RJRTI FLAVOR CONCENTRATES”'
      },
      value: 'file'
    },
    {
      label: 'File Path',
      description: {
        text: 'File Path tells us the file path of the original document.',
        example: null
      },
      value: 'filepath'
    },
    {
      label: 'Folder',
      description: {
        text: 'Folder indicates the paper folder in which the original document is filed, within the archival collection listed in the Source field.',
        example: null
      },
      value: 'folder'
    },
    {
      label: 'Food',
      description: {
        text: 'Food lists the non-brand food mentioned in or associated with the document.',
        example: 'sugar, sugary beverage, soda, trans fat'
      },
      value: 'food'
    },
    {
      label: 'Format',
      description: {
        text: 'Document Format tells us whether a document is a scanned hardcopy, an email, or a non-email electronic document.',
        example: null
      },
      value: 'format'
    },
    {
      label: 'Genre',
      description: {
        text: 'Genre describes the type of audio or video.',
        example: null
      },
      value: 'genre'
    },
    {
      label: 'Grant',
      description: {
        text: 'Grant lists any contracts, grants, grant applications, or special project numbers mentioned in Center for Tobacco Research documents.',
        example: null
      },
      value: 'grant'
    },
    {
      label: 'ID',
      description: {
        text: 'ID is a unique 8-digit alpha-numeric code assigned to a document by UCSF',
        example: 'xfmb0002 and fjcw0217'
      },
      value: 'id'
    },
    {
      label: 'Language',
      description: {
        text: 'Language identifies the language that some non-English documents were written in.',
        example: '“German”, “Dutch” and “Spanish”'
      },
      value: 'language'
    },
    {
      label: 'Mentioned',
      description: {
        text: 'Mentioned tells us the person(s) or organizations(s) mentioned in a document.',
        example: null
      },
      value: 'mentioned'
    },
    {
      label: 'Metadata',
      description: {
        text: 'Metadata is used to search all fields in the catalog record (metadata) of a document',
        example: 'title, author, or date -- but does NOT include the text of the document itself'
      },
      value: 'metadata'
    },
    {
      label: 'Minnesota Request Number',
      description: {
        text: 'Minnesota Request Number identifies the request for a specific document in the case of Minnesota et al vs. Philip Morris et al.',
        example: '"MINNESOTA 1RFP110", "WALLACE 9RFP66" and 115'
      },
      value: 'minnesotarequestnumber'
    },
    {
      label: 'Organization',
      description: {
        text: 'Organization lists any organization(s) mentioned or copied on a document, that received of a document, or was present at a meeting mentioned in a document.',
        example: null
      },
      value: 'organization'
    },
    {
      label: 'Original Format',
      description: {
        text: 'Original Format tells us the original document format. Applicable to the Opioids (Drug) industry.',
        example: null
      },
      value: 'originalformat'
    },
    {
      label: 'Other Number',
      description: {
        text: 'Other Number identifies another Bates or identifying number associated with a document. Lorillard documents may have ACC (Lorillard Accession Numbers); MRD (Market Research Department Report Numbers); PAT (Patent Numbers); and PRO (Lorillard Research Project Numbers) before the number. ',
        example: 'PRO1902 and MRD88041'
      },
      value: 'othernumber'
    },
    {
      label: 'Other Request',
      description: {
        text: 'Other Request identifies the request for a document in a case.',
        example: '“MILLEDGE 3INT”'
      },
      value: 'otherrequest'
    },
    {
      label: 'Pages',
      description: {
        text: 'Pages tells us the number of pages in a document.',
        example: null
      },
      value: 'pages'
    },
    {
      label: 'Person',
      description: {
        text: 'Person lists any person(s) mentioned or copied on a document, that received of a document, or was present at a meeting mentioned in a document.',
        example: null
      },
      value: 'person'
    },
    {
      label: 'Privilege Code',
      description: {
        text: 'Privilege Code is an alpha-numeric code that explains the type of privilege claimed for a document. See <a href="/help/privileged-confidential/" >privilege codes.</a>',
        example: null
      },
      value: 'privilegecode'
    },
    {
      label: 'Privilege Code',
      description: {
        text: 'Privilege Code is an alpha-numeric code that explains the type of privilege claimed for a document. See <a href="/tobacco/help/privileged-confidential/" >privilege codes.</a>',
        example: null
      },
      value: 'privilegecode'
    },
    {
      label: 'Recipient',
      description: {
        text: 'Recipient lists any person(s) or organization(s) who received a document.',
        example: null
      },
      value: 'recipient'
    },
    {
      label: 'Recommend',
      description: {
        text: 'Recommend lists recommended documents.',
        example: null
      },
      value: 'recommend'
    },
    {
      label: 'Redacted',
      description: {
        text: 'Redacted tells us if a document is redacted.',
        example: null
      },
      value: 'redacted'
    },
    {
      label: 'Redacted By',
      description: {
        text: 'Redacted By tells us who redacted this document.',
        example: null
      },
      value: 'redactedby'
    },
    {
      label: 'Redaction',
      description: {
        text: 'Redaction tells us the reason or basis for a redaction of text in the document.',
        example: null
      },
      value: 'redaction'
    },
    {
      label: 'Referenced Document',
      description: {
        text: 'Referenced Document identifies a document associated with another document.',
        example: null
      },
      value: 'referenceddocument'
    },
    {
      label: 'Related',
      description: {
        text: 'Related identifies related documents within IDL.',
        example: null
      },
      value: 'related'
    },
    {
      label: 'Request Number',
      description: {
        text: 'Request Number identifies the request for production of a document in the case, usually in this format: CASE CODE/REQUEST SET-REQUEST NUMBER. More information about the codes is available on the tobacco company document websites.',
        example: 'STMN/R1-007 (STMN is the State of Minnesota case, R1 is the plaintiffs\' first set of requests, and the 007 means this is the seventh request in that set)'
      },
      value: 'requestnumber'
    },
    {
      label: 'Rights',
      description: {
        text: 'Rights describes the copyright status and conditions for personal or commercial use of the document.',
        example: null
      },
      value: 'rights'
    },
    {
      label: 'Series',
      description: {
        text: 'Series indicates the section of the archival collection (listed in the Source field) to which the document belongs.',
        example: null
      },
      value: 'series'
    },
    {
      label: 'Source',
      description: {
        text: 'Source tells where the document comes from. Examples: an advocacy organization, an archival collection in a library, or other type of donor',
        example: null
      },
      value: 'source'
    },
    {
      label: 'Subject',
      description: {
        text: 'Subject field for fossil fuel collection to facet',
        example: null
      },
      value: 'subject'
    },
    {
      label: 'Text Only',
      description: {
        text: 'Text Only searches the text of the document, NOT the catalog record (metadata).',
        example: null
      },
      value: 'text'
    },
    {
      label: 'TID',
      description: {
        text: 'TID is UCSF\'s original 8-digit alpha-numeric code for industry documents.',
        example: 'wuf75c00'
      },
      value: 'tid'
    },
    {
      label: 'Time Received',
      description: {
        text: 'Time Received tells us the time an e-mail was received.',
        example: null
      },
      value: 'timereceived'
    },
    {
      label: 'Timesent',
      description: {
        text: 'Timesent tells us the time the message was sent',
        example: null
      },
      value: 'timesent'
    },
    {
      label: 'Title',
      description: {
        text: 'Title is the title of a document.',
        example: null
      },
      value: 'title'
    },
    {
      label: 'Topic',
      description: {
        text: 'Topic describes subject(s) or keyword(s) of a document.',
        example: '“Youth” and “"EVENT SPONSORSHIP"'
      },
      value: 'topic'
    },
    {
      label: 'Document Type',
      description: {
        text: 'Document Type is the kind of document.',
        example: 'letter, memo, report, “computer printout”, and video'
      },
      value: 'type'
    },
    {
      label: 'Witness',
      description: {
        text: 'Witness is the name of a witness giving a deposition.',
        example: null
      },
      value: 'witness'
    }
  ],

  match: [
    {
      label: 'Contains',
      value: 'contains'
    },
    {
      label: 'Contains Exact',
      value: 'contains-exact'
    }
  ]
}

export const ADV_SEARCH_DEFAULT_CONSTRAINT: TAdvConstraint = {
  operator:ADV_SEARCH_DROPDOWNS.operator[0],
  field: ADV_SEARCH_DROPDOWNS.field[0],
  match: ADV_SEARCH_DROPDOWNS.match[0],
  term: ''
}
