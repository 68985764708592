
import './styles.scss';
import React, {Dispatch} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import {IMyLibraryMenuItem} from "./interface";

/* Bootstrap */
import {Nav} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import {AnyAction} from "@reduxjs/toolkit";
import {IMyLibraryState} from "../../../../types/redux/my-library";
import {setLibrarySection} from "../../../../redux/slices/my-library-slice";
import {searchResetSaved} from "../../../../redux/slices/search-slice";
import {clearResults, clearSelectedResults} from "../../../../redux/slices/result-slice";

const MyLibraryMenu = ():JSX.Element => {
  const section: string | null = useSelector<RootState, IMyLibraryState>(state => state.my_library).section;
  const dispatch:Dispatch<AnyAction> = useDispatch();

  const items: IMyLibraryMenuItem[] = Constants.MY_LIBRARY_MENU_ITEMS;

  const handleItemClick = (e:React.MouseEvent, key: string | null):void => {
    e.preventDefault();
    if (key && (key !== section)) {
      dispatch(searchResetSaved())
      dispatch(clearResults());
      dispatch(clearSelectedResults());
      dispatch(setLibrarySection(key));
    }
  }

  return (
    <div className={'my-library-menu'}>
      <Nav className={'justify-content-left bg-white mb-0 rounded-md-bottom position-relative shadow-sm'}>
        {items.map((item:IMyLibraryMenuItem, index:number) => {
          return (
            <Nav.Item
              role={'presentation'}
              key={"sm-item"+index}
              className={"rounded-1"}>

              <Nav.Link
                className={'mx-4 py-2 px-0 text-black' + (section === item.section ? ' active': '')}
                onMouseUp={e=>handleItemClick(e, item.section)}
                role="tab"
                eventKey={index}
              >
                {item.label}
              </Nav.Link>
              <div className={(section === item.section) ? 'active_select' : '' }/>
            </Nav.Item>
          )
        })}
      </Nav>
    </div>
  )
}

export default MyLibraryMenu;
