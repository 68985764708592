/* todo: jain - use report issue component wrapped in modal */

import './styles.scss';
import React, {Dispatch,  useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import Utils from "../../../utils";


/* Bootstrap */
import {Button, Col, Container, Dropdown, Form, InputGroup, Modal,  Row} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {AnyAction} from "@reduxjs/toolkit";
import {addToast} from "../../../redux/slices/toast-slice";
import {IResultsState} from "../../../types/redux/result";
import {RootState} from "../../../redux";

/* API */
import EmailService from "../../../api/send";


interface IProps {
  show: boolean;
  onHide(): void;
}

interface IEmailInputs {
  recipients: string;
  cc_recipients: string;
  subject: string;
  firstname: string;
  lastname: string;
  email: string;
  issue: string;
  description: string;
  selected: string;
}

const REPORT_ISSUE_TITLE: string = "Report an Issue";
const DEFAULT_SELECT: string = '- Select -';

const ReportIssueModal = (props: IProps):JSX.Element => {
  const {
    onHide
  } = props;

  const resultsState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const [validated, setValidated] = useState(false);

  const [inputs, setInputs] = useState<IEmailInputs>({
    recipients: '',
    cc_recipients: '',
    subject: "Industry Documents Library - Report Issue " + Date.now(),
    firstname: '',
    lastname: '',
    email: '',
    issue: '',
    description: '',
    selected: resultsState.selected.map(result => result.id).join(', ')
  });

  const handleSubmit = (event: any):void => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity()) {
      EmailService.emailReportIssue({...inputs}).then(()=>{
        onHide();

        /* notify user of success */
        dispatch(addToast({
          bg: 'bg-success',
          msg: Constants.REPORT_ISSUE_SENT,
          id: -1
        }))
      }).catch(e=>{
        console.log('error in sending report issue: ' + e.message)
        // todo: jain - handle error
      })
    }
    setValidated(true);
  };

  const handleChange = (field: string, value: string | boolean):void => {
    const inputsCln: IEmailInputs = {...inputs};
    // @ts-ignore
    inputsCln[field] = value;
    setInputs(inputsCln);
  };

  return (
    <Modal
      {...props}
      keyboard={false}
      centered
      className={"report-issue-modal"}
    >
      <Modal.Header closeButton>
        <Modal.Title className={'fs-6'}>{REPORT_ISSUE_TITLE}</Modal.Title>
      </Modal.Header>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Container className={'px-5 py-3'}>
            <Form.Group className={"mb-3"} controlId="validationmMessage">
              <Form.Label className={'small pb-2'}>Please describe as detailed as possible to aid us in the troubleshooting process.</Form.Label>
              <Row className={'mx-0 py-2 mb-3'}>
                <Col md={6} className={'p-0 pe-3 firstname'}>
                  {/* First Name */}
                  <Form.Label >First Name:</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={'text'}
                      aria-label={"firstname"}
                      aria-describedby={"firstname-input"}
                      value={inputs.firstname}
                      onChange={e=>setInputs({...inputs, firstname: e.target.value})}
                    />
                  </InputGroup>
                </Col>

                <Col md={6} className={'p-0 ps-3 lastname'}>
                  {/* Last Name */}
                  <Form.Label >Last Name:</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={'text'}
                      aria-label={"lastname"}
                      aria-describedby="lastname-input"
                      value={inputs.lastname}
                      onChange={e=>setInputs({...inputs, lastname: e.target.value})}
                    />
                  </InputGroup>
                </Col>
              </Row>

              <Row className={'mx-0 mb-4 mt-2'}>
                {/* Email */}
                <Col lg={12} className={'p-0'}>
                  <Form.Label >Email:</Form.Label>
                  <InputGroup>
                    <Form.Control
                      className={'rounded'}
                      type={'email'}
                      required
                      aria-label={"email"}
                      aria-describedby="email-input"
                      value={inputs.lastname}
                      isInvalid={Utils.validateEmail(inputs.email)}
                      onChange={e=>setInputs({...inputs, lastname: e.target.value})}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>

              <Row className={'mx-0'}>
                <Col md={6} sm={12} className={'issue-dropdown-col p-0 mb-4 pe-3'}>
                  {/* Issue */}
                  <Form.Label>Issue:</Form.Label>

                  <Dropdown id={"issue-dropdown"} onSelect={(eventKey: string | null)=>setInputs({...inputs, issue:eventKey ? eventKey : ''})}>
                    <Dropdown.Toggle
                      variant="success"
                      id={"dropdown-issue-toggle"}
                      className={"border-0 bg-white w-100 lg btn btn-light p-0 text-start d-block"}
                    >
                      <Form.Control
                        className={'px-2 py-2 m-0'}
                        type="text"
                        role="button"
                        required
                        tabIndex={-1}
                        isInvalid={validated && (inputs.issue.length === 0)}
                        value={inputs.issue ? inputs.issue : ''}
                        onChange={()=>{}}
                      />
                      <Form.Control.Feedback type="invalid" className={'text-smaller mt-2'}>
                        A description of the issue is required.
                      </Form.Control.Feedback>
                      <span className={'p-2 ps-3 fs-6 position-absolute top-0 ' + (inputs.issue === '' ? "d-block": 'd-none')}>{DEFAULT_SELECT}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={'mt-0'} >
                      <Dropdown.Item href="#" eventKey={'OCR Text Correction'}>OCR Text Correction</Dropdown.Item>
                      <Dropdown.Item href="#" eventKey={'Metadata Correction'}>Metadata Correction</Dropdown.Item>
                      <Dropdown.Item href="#" eventKey={'Sensitive or confidential information'}>Report sensitive or confidential information</Dropdown.Item>
                      <Dropdown.Item href="#" eventKey={'Other'}>Other</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>

              <Row className={'mx-0'}>
                <Col lg={12} className={'p-0'}>
                  {/* Description */}
                  <Form.Label >Description:</Form.Label>
                  <InputGroup className={"mb-3 p-0"}>
                    <Form.Control
                      as="textarea"
                      type="text"
                      className={'rounded'}
                      rows={3}
                      required
                      value={inputs.description}
                      onChange={e=>handleChange('description', e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      A description of the issue is required.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>
            </Form.Group>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" className={'bg-light border-0 text-dark text-small'} onClick={onHide}>Cancel</Button>
          <Button variant="primary" type={'submit'} className={'text-small'}>Send</Button>
        </Modal.Footer>
      </Form>

    </Modal>
  )
}
export default ReportIssueModal;
