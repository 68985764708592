import Utils from "../utils";
import {IDropdownItem} from "../types/redux/viewer";


export const RECORDS: string          = 'records';
export const CITE: string             = 'cite';
export const SAVE: string             = 'save';
export const UNSAVE: string           = 'unsave';
export const DOWNLOAD: string         = 'download';
export const SHARE: string            = 'share';
export const TAGS_NOTES: string       = 'add to library';
export const REPORT_ISSUES: string    = 'report-issues';

export const BROWSE: string           = 'browse';
export const BATES: string            = 'bates';
export const MORE_LIKE_THIS: string   = 'more-like-this';
export const POSSIBLE_DUPLICATES: string   = 'possible-duplicates';
export const RECORD_TOOL_PARAM: string  = 'rtool';
export const PREV: string               = 'prev';
export const NEXT: string               = 'next';
export const PREVIOUS: string           = 'previous';
export const VW_NUM_ROWS: number        = 10;
export const MORE_LIKE_ROWS: number     = 10;
export const DUPLICATES_ROWS: number     = 10;

export const AMA: string               = 'AMA';
export const APA: string               = 'APA';
export const MLA: string               = 'MLA';
export const VANCOUVER: string         = 'Vancouver';

export const ENDNOTE: string            = 'endnote';
export const REFWORKS: string           = 'refworks';
export const EXCEL: string              = 'excel';
export const CSV: string                = 'csv';
export const PLAIN_TEXT: string         = 'plain-text';
export const ADD_NOTE_PLACEHOLDER       = 'Add a note to this document';

export const VIEWER_RESULTS_PER_PAGE: number    = 10;
export const RECORD_NOT_FOUND: string           = 'record-not-found';
export const VIEWER_MEDIA_HEIGHT: number        = 792; // pdf default height ( ratio .7777)
export const VIEWER_MEDIA_WIDTH: number         = 612; // pdf default width
export const VIDEO_DEFAULT_HEIGHT: number       = 480;
export const VIDEO_DEFAULT_WIDTH: number        = 640;

export const VIEWER_PAGE_KEY: string         = 'doc-pg';
export const VIEWER_ZOOM_KEY: string         = 'zoom';

export const INVALID_PAGE_NUMBER: string     = 'Page input number can not be greater than the total pages or less than 1.';

export const PDF_MAX_ZOOM: number            = 4.0;
export const PDF_MIN_ZOOM: number            = .2;
export const PDF_ZOOM_INCREMENT: number      = .5;

export const PAGE_SPINNER_PREFIX: string     = 'page-spinner_';

export const TEXT: string   = 'text';
export const OCR: string    = 'ocr';
export const PDF_PAGE_LOAD_SIZE: number           = 10; /* todo: jain - refine these */
export const PDF_THUMBNAIL_LOAD_SIZE: number      = 14;

export const FIT_WIDTH: string       = 'fit-width';
export const PAGE_FIT: string        = 'page-fit';
export const ACTUAL_SIZE: string      = 'actual-size';

export const ZOOM_DROPDOWN_ITEMS: IDropdownItem[] =[
  {
    key: FIT_WIDTH,
    label: Utils.toTitleCase(FIT_WIDTH)
  },
  {
    key: ACTUAL_SIZE,
    label: Utils.toTitleCase(ACTUAL_SIZE)
  },
  {
    key: PAGE_FIT,
    label: Utils.toTitleCase(PAGE_FIT)
  },
  {
    key: '.1',
    label: '10%'
  },
  {
    key: '.25',
    label: '25%'
  },
  {
    key: '.5',
    label: '50%'
  },
  {
    key: '.75',
    label: '75%'
  },
  {
    key: '1',
    label: '100%'
  },
  {
    key: '1.25',
    label: '125%'
  },
  {
    key: '2',
    label: '200%'
  }
]

