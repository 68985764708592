import './styles.scss';
import React, {useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../constants";

/* Bootstrap */
import {Button, Col, Container, Row} from "react-bootstrap";

/* Components */
import Login from "./login";
import Registration from "./registration";
import ResetPasswordCmp from "./reset-passord-cmp";

interface IProps {
  context: string;
}

const LoginRegistration = (props: IProps): JSX.Element => {
  const {
    context
  } = props;

  const [resetPasswordActive, setResetPasswordActive] = useState<boolean>((window.location.pathname === Constants.RESET_PASSWORD_PATHNAME));
  const [registering, setRegistering] = useState<boolean>(false);

  return (
    <Container className={'login-registration p-0'}>
      <Row className={'pt-3'}>
        <Col md={5}>
          {registering &&
            <div>
              <h2><b>Already Have an Account?</b></h2>
              <Button
                className={'btn-link text-decoration-none font-bold text-larger h5'}
                onClick={()=>setRegistering(false)}
              >Log In →</Button>
            </div>}

          {!registering &&
            !resetPasswordActive &&
            <Login
              setResetPasswordActive={setResetPasswordActive}
              context={context}/>}

          {resetPasswordActive &&
             <ResetPasswordCmp
               setResetPasswordActive={setResetPasswordActive}
             /> }
        </Col>

        <Col md={2} align="center">
          <div className={'hr'}/>
        </Col>

        <Col md={5}>
          {!registering &&
            <div>
              <h2><b>Not registered?</b></h2>
              <Button
                className={'btn-link text-decoration-none font-bold h5'}
                onClick={()=>{
                  setResetPasswordActive(false);
                  setRegistering(true)
                }}
              >Create an account →</Button>
          </div>}

          {registering &&
            <Registration
              context={context}
              setRegistering={setRegistering}
            />}
        </Col>
      </Row>
    </Container>
  )
}
export default LoginRegistration;
