import React from "react";

interface IProps {
  pageNum: number
}

const ThumbnailPlaceholder = (props: IProps): JSX.Element => {
  const {
    pageNum
  } = props;

  return (
    <div className={'position-relative'}>
      <div className={'thumbnail-placeholder bg-white mb-1 border mx-auto'}
           style={{height:'116px', width:'90px'}}>
      </div>
      <p className={'text-grey-dark text-xs mb-2'}>page {pageNum}</p>
    </div>
  )
}

export default ThumbnailPlaceholder;