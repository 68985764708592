import {TFilterOption} from "../../types/search";

export const filters = (filterOptions: TFilterOption[]): string => {
  const fqGroups:  { [key: string]: TFilterOption[]; } = {}
  filterOptions.forEach(filter=>{

    const filterCln: TFilterOption = {...filter}
    if (filterCln.field==='collection_facet') {
      filterCln.value = "\""+filterCln.value+"\"";
    }

    if (fqGroups[filter.fq_group]) {
      fqGroups[filter.fq_group].push(filterCln);
    } else {
      fqGroups[filter.fq_group] = [filterCln]
    }
  })

  let fqString: string = '';
  for (let fqGroup in fqGroups) {
    let filterString: string = '';
    for (let i=0; i<fqGroups[fqGroup].length; i++) {
      const filterOption: TFilterOption = fqGroups[fqGroup][i];
      filterString += ( ((i > 0) ? ' OR ' : '') + (filterOption.field ? '(' + filterOption.field + ':' : '') + filterOption.value + (filterOption.field ? ')' : ''));
    }
    fqString += '&fq=' + encodeURIComponent(filterString)
  }

  return fqString;
}