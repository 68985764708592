import './styles.scss';
import React from "react";
import {Form} from "react-bootstrap";

interface IProps {
  id: string;
  checked: boolean;
  toggle(id:string):void;
}

const Switch = (props: IProps):JSX.Element => {
  const {
    id,
    checked,
    toggle,
  } = props;

  return (
    <div className={'switch' + (checked?' active': '')}>
      <Form.Check
        type='switch'
        id={id}
        label={checked ? "On" : "Off" }
        checked={checked}
        onChange={()=>{}}
        onClick={e=>toggle(id)}
      />
    </div>
  )
}

export default Switch;
