import './styles.scss';
import React, {Dispatch} from "react";
import {Button} from "react-bootstrap";

import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {IViewerState} from "../../../../types/redux/viewer";
import {RootState} from "../../../../redux";
import {setPanelCollapsed, setViewerFullscreen} from "../../../../redux/slices/viewer-slice";

const FullscreenButton = ():JSX.Element => {
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);

  return(
    <div className={'fullscreen-button me-1'}>
      <Button
        onClick={()=>{
          const fullscreen: boolean = !viewerState.fullscreen;
          dispatch(setPanelCollapsed(fullscreen))
          dispatch(setViewerFullscreen(fullscreen))
        }}
        className={'border-0' + (viewerState.fullscreen ? '' : ' text-black bg-transparent')}
      >
        {!viewerState.fullscreen && <i className={'bi bi-arrows-fullscreen'}/>}
        {viewerState.fullscreen && <i className={'bi bi-fullscreen-exit'}/>}
      </Button>
    </div>
  )
}
export default FullscreenButton;
