import './styles.scss';
import React from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";

/* Bootstrap */
import { Container, Modal} from "react-bootstrap";

/* API */
import LoginRegistration from "../../login-registration";

interface IProps {
  show: boolean;
  onHide(): void;
}

const LoginModal = (props: IProps):JSX.Element => {
  return (
    <Modal
      {...props}
      backdrop={'static'}
      keyboard={false}
      centered
      className={"login-modal"}
    >
      <Modal.Header closeButton className={'border-0 pb-0'}/>
      <Modal.Body className={'pt-0'}>
        <Container className={'p-0 px-4 pb-3'}>
            <LoginRegistration context={Constants.MODAL}/>
        </Container>
      </Modal.Body>
    </Modal>
  )
}
export default LoginModal;
