import './styles.scss';
import React, {useContext, useEffect, useRef, useState} from "react";

/* Type, Constants, Utils */
import * as Constants from "../../../constants";
import {IRecordsContext, RecordsContext} from "../../../types/document";

/* Bootstrap */
import {Button} from "react-bootstrap";

/* Redux */
import {ISavedState, ITagState, TSavedDocument, TSavedDocumentTag} from "../../../types/redux/saved";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";

/* Components */
import TagsModal from "../tags/tag-modal";
import Note from "../notes";

interface IProps {
  onHide(): void;
}

const TagsNoteModal = (props: IProps):JSX.Element => {
  const {
    onHide
  } = props;

  /* redux */
  const tagsState: ITagState = useSelector<RootState, ITagState>(state => state.tags);
  const savedDocuments: TSavedDocument[] = useSelector<RootState, ISavedState>(state => state.saved).documents;

  /* context */
  const selectedRecords: any = useContext<IRecordsContext>(RecordsContext).records;

  /* state */
  const [appliedTags, setAppliedTags] = useState<TSavedDocumentTag[]>([])

  /* ref */
  const modalRef:React.MutableRefObject<any> = useRef();

  useEffect(()=> {
    const mouseDownListener = (e: MouseEvent): void => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onHide()
      }
    }
    document.addEventListener('mousedown', mouseDownListener);

    return () => {
      document.removeEventListener('mousedown', mouseDownListener);
    }
  },[onHide])

  useEffect(()=>{
    /* find the tags common in all the selected documents */

    const tagDict: { [key: string]: number; } = {}
    /* associate selected records - solr id to save record id ( backend ) */
    selectedRecords.forEach((record:any) => {
      const associatedSavedRecord: TSavedDocument | undefined = savedDocuments.find(document=>document.documentId === record.id);
      if (associatedSavedRecord) {
        const tags: TSavedDocumentTag[] = associatedSavedRecord.tags;
        tags.forEach(tag=>{
          if (!tagDict.hasOwnProperty(tag.id)) {
            tagDict[tag.id] = 1;
          } else {
            tagDict[tag.id] = tagDict[tag.id] + 1;
          }
        })
      }
    });

    /* if number of instances of a tag === number of selected records => all share that tag */
    const commonTags: TSavedDocumentTag[] = [];
    for (let key in tagDict) {
      if (tagDict[key] === selectedRecords.length) {
        const tag: TSavedDocumentTag | undefined = tagsState.tags.find(tag=>String(tag.id) === key);
        if (tag) {
          commonTags.push(tag)
        }
      }
    }
    setAppliedTags(commonTags);
  }, [savedDocuments, tagsState, selectedRecords, onHide])

  return (
    <div
      className={'tag-note-modal position-absolute bg-white shadow p-4 pt-3 text-small'}
      ref={modalRef}
    >
      <div className={'d-flex w-100'}>
        <h6 className={'m-0'}>Add optional tags or a note to selected documents</h6>
        <Button
          className={'close-btn border-0 btn-icon-only position-absolute'}
          onClick={onHide}
        >
          <i className={'bi bi-x text-dark h4'}/>
        </Button>
      </div>

      <div className={'mt-3 d-flex'}>
        <span className={'pt-2 pe-3'}><b>Tags:</b></span>
        <span><TagsModal appliedTags={appliedTags}/></span>
      </div>

      <div className={'mt-3 mb-2 me-0 d-flex'}>
        <span className={'me-2'}><b>Notes:</b></span>
        <span className={'w-100 me-3'}><Note context={Constants.TOOLBAR}/></span>
      </div>
    </div>
  )
}
export default TagsNoteModal;
