import {idlAPIClientLegacy} from "./axios";
import {AxiosResponse} from "axios";

const emailDocument = async (data: any):Promise<any> => {
  const response:AxiosResponse<any, any> = await idlAPIClientLegacy.post<any>(
    'email/sendmail',
    data);
  return response.data;
}

const emailReportIssue = async (_data: any):Promise<any> => {
  /* todo: jain - temp till api is known */
  return new Promise((resolve, _reject) => {
    resolve(0);
  });
}

const EmailService = {
  emailDocument,
  emailReportIssue
}

export default EmailService;
