import {IBreadCrumbState, UpdateBreadCrumbAction, TBreadcrumb} from "../../types/redux/breadcrumb";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import * as Constants from "../../constants";

const initialState: IBreadCrumbState = {
  breadcrumbs: [{
    label:  '<i class="bi bi-house-door"/>',
    href: '/' + Constants.HOME.toLowerCase(),
    level: 0,
    active: true
  }]
};

const resetToCrumb = (state: IBreadCrumbState, crumb: TBreadcrumb) => {
  for (let i=0; i<state.breadcrumbs.length; i++) {
    state.breadcrumbs[i].active = false;
  }

  const pos: number = state.breadcrumbs.findIndex(stateCrumb => stateCrumb.level >= crumb.level);
  if (pos > -1) {
    state.breadcrumbs = state.breadcrumbs.slice(0, pos);
  }
  state.breadcrumbs.push(crumb);
}

export const breadcrumbSlice = createSlice({
  name: UpdateBreadCrumbAction,
  initialState: initialState,
  reducers: {
    addCrumb: (state: IBreadCrumbState, action: PayloadAction<TBreadcrumb>) => {
      resetToCrumb(state, action.payload)
    },

    removeToCrumb: (state: IBreadCrumbState, action: PayloadAction<TBreadcrumb>) => {
      resetToCrumb(state, action.payload);
    },

    clearCrumbs: (state: IBreadCrumbState) => {
      state.breadcrumbs = [];
    }
  }
});

export const {
  addCrumb,
  removeToCrumb,
  clearCrumbs
} = breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;
