import './styles.scss';
import React, {useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";

/* Bootstrap */
import {Button} from "react-bootstrap";

import Form from "react-bootstrap/Form";
import PasswordField from "../../../../components/fields-buttons/password-field";

const SettingsMyAccount = ():JSX.Element => {
  /* state */
  const [name, setName] = useState<string | null>(null);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [newsletter, setNewsletter] = useState<boolean>(false);
  const [validated] = useState<boolean>(false);
  const [fieldErrors ] = useState<any[] | null>(null);
  const [errorMessage ] = useState<string | null>(null);

  const handleKeydown =  (e: React.KeyboardEvent):void=>{
    if (e.code === Constants.ENTER) {
      e.preventDefault();
    }
  }

  const getErrors = (field: string): JSX.Element => {
    if (!fieldErrors) {
      return <></>
    }

    const errors: any[] = fieldErrors.filter( e => (e.field === field) );
    return <div>
      {errors.map(error=>{
        return <p className={'text-danger my-2'}>{error.message}</p>
      })}
    </div>
  }

  return (
    <div className={'my-account content px-5 pt-5 pb-5 bg-white rounded-md'}>
      <h2>My Account</h2>

      {/* Name and Email */}
      <h5 className={'overview font-light mt-5'}><b>Name and email</b></h5>
      <Form
          noValidate
          validated={validated}
          className={'account-form mt-4'}
      >
        {/* name */}
        <Form.Group
            className={'mt-3 field-width'}
            controlId={'SettingsForm.ControlName'}
        >
          <Form.Label className={'mb-1 font-light text-secondary'}>Name</Form.Label>
          <Form.Control
              type={'text'}
              value={name ? name : ''}
              onKeyDown={e=>handleKeydown(e)}
              onChange={e=>setName(e.target.value)}
          />
        </Form.Group>

        {/* email */}
        <Form.Group
            className={'mt-3 field-width'}
            controlId={'SettingsForm.ControlEmail'}
        >
          <Form.Label className={'mb-1 font-light text-secondary'}>Email</Form.Label>
          <Form.Control
              type={'email'}
              value={email}
              onKeyDown={e=>handleKeydown(e)}
              onChange={e=>setEmail(e.target.value)}
          />
          <Form.Control.Feedback type={'invalid'}>
            Please enter a valid email.
          </Form.Control.Feedback>
        </Form.Group>

        {fieldErrors && getErrors('email')}

        <div className={'mt-4 d-flex flex-column'}>
          <Form.Group>
            <Form.Check
                className={"form-check mt-0 float-start "}
                type={'checkbox'}
                checked={newsletter}
                onChange={e=>setNewsletter(e.target.checked)}
                label={<span className={'text-smaller ms-1'}><b>Subscribe to the Industry Documents Library Newsletter</b></span>}
            />
          </Form.Group>
          <p className={'text-smaller text-italic mt-0 ms-4 ps-1'}>The Industry documents Library newsletter is sent approximately once per month. We do not share your information.</p>
        </div>

        {/* Submission error ( non validation error )*/}
        {errorMessage &&
            <p className={'text-danger'}>{errorMessage}</p>}

        {/* Change email or name */}
        <Button className={'rounded py-1 px-3'} type={'submit'}>
          Save Changes
        </Button>

        {/* password */}
        <Form.Group className={'mt-5 field-width'} controlId={'SettingsForm.ControlPassword'}>
          <PasswordField
              label={'Password'}
              setPassword={setPassword}
              password={password}
              handleKeydown={handleKeydown}
              isInvalid={()=>false}
              errorMessage={'A password at least 5 characters long is required.'}
          />
          <div className={'text-end'}>
            <Button className={'btn-link link-underline-none text-primary text-smaller'}>Change</Button>
          </div>
        </Form.Group>

        {fieldErrors && getErrors('password')}
      </Form>

      {/* Delete Account */}
      <h5 className={'font-light mt-5'}><b>Delete Account</b></h5>
      <p className={'text-xnormal'}>Would you like to delete your account? Deleting your account will remove all of you saved content and cannot be undone.</p>
      <Button className={'btn-link p-0 pb-5'}>
        I want to delete my account
      </Button>
    </div>
  )
}

export default SettingsMyAccount;
