import './styles.scss';
import React, {Dispatch, useEffect, useRef, useState} from "react";

/* Bootstrap */
import {Form} from "react-bootstrap";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import {IViewerState} from "../../../../types/redux/viewer";
import {
  setZoom,
} from "../../../../redux/slices/viewer-slice";

const Zoom = ():JSX.Element => {
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);

  const [sliderValue, setSliderValue] = useState<number>((viewerState.zoom - 1) / 3 * 100);
  const indicatorRef:React.MutableRefObject<any> = useRef(sliderValue * 1.2);
  const updateZoom = (value: number):void => {
    indicatorRef.current = value * 1.2;
    setSliderValue(value)
    dispatch(setZoom((value/100 * 3) + 1));
  }

  useEffect(()=>{
    const value: number = (viewerState.zoom - 1) / 3 * 100;
    indicatorRef.current = value * 1.2;
    setSliderValue(value)
  }, [viewerState.zoom])

  return (
    <div className={'zoom'}>
      <div className={'mt-1'}>
        <div className={'range-indicator'} style={{width: indicatorRef.current}}/>
        <Form.Range
          value={sliderValue}
          onChange={e=> updateZoom(parseInt(e.target.value))}/>
        <span className={'position-absolute ms-2'}>{ Math.round(((sliderValue - 1) * 3) + 103)}%</span>
      </div>
    </div>
 )
}
export default Zoom;
