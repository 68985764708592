export type TBreadcrumb = {
  label: string;
  active: boolean;
  href: string;
  level: number;
}

export interface IBreadCrumbState {
  breadcrumbs: TBreadcrumb[]
}

export const UpdateBreadCrumbAction: string = "update-results-action";
