import {
  idlAPIClient
} from "./axios";
import {AxiosResponse} from "axios";

import ApiUtils from "./api-utils";
import {TSavedDocument} from "../types/redux/saved";

const SAVED_DOCUMENTS_URL:      string = '/saved-documents';
const SAVED_BIBLIOGRAPHIES_URL: string = '/saved-biblios';
const SAVED_SEARCHES_URL:       string = '/saved-searches';

const getSavedDocuments = async (page: number, size: number, sort: string, tagIds: string[]):Promise<any> => {
  const token: string = ApiUtils.getToken();
  if (token) {
    const params: any = {
      page,
      size,
      sort
    }
    if (tagIds.length > 0) {
      params.tagIds = tagIds.toString();
    }
    const response: AxiosResponse<any, any> = await idlAPIClient.get<any>(
      SAVED_DOCUMENTS_URL,
      {
        params
      }
    );
    return response.data;
  }
}

const getSavedDocumentsViaTags = async (tagIds: number):Promise<any> => {
  const token: string = ApiUtils.getToken();
  if (token) {
    const params: any = {
      tagIds
    }
    const response: AxiosResponse<any, any> = await idlAPIClient.get<any>(
      SAVED_DOCUMENTS_URL,
      {
        params
      }
    );
    return response.data;
  }
}

const getSavedDocumentsViaIds = async (documentIds: string[]):Promise<any> => {
  const token: string =  ApiUtils.getToken();
  if (token) {
    const params: any = {
      documentIds: documentIds.join(',')
    }
    const response: AxiosResponse<any, any> = await idlAPIClient.get<any>(
        SAVED_DOCUMENTS_URL,
        {params}
    );
    return response.data;
  }
}

const saveDocuments = async (data: any):Promise<any> => {
  return ApiUtils.makePost(data, SAVED_DOCUMENTS_URL)
}


const appendDocumentNotes = async (ids: string[], notes: string):Promise<any> => {
  const data: any[] = ids.map(id=>  {
    return {
      id,
      notes
    }})
  return ApiUtils.makePatch(data, SAVED_DOCUMENTS_URL)
}


const deleteDocuments = async (ids: number[]):Promise<any> => {
  const params: any =  {
    savedDocumentIds: ids.join(',')
  }
  return ApiUtils.doDelete(params, SAVED_DOCUMENTS_URL)
}

/* Saved Bibliographies */
const getSavedBibliographiesViaIds = async (ids: string[]):Promise<any> => {
  const token: string = ApiUtils.getToken();
  if (token) {
    const params: any =  {
      savedBiblios: ids.join(',')
    }
    const response: AxiosResponse<any, any> = await idlAPIClient.get<any>(
      SAVED_BIBLIOGRAPHIES_URL,
      {params}
    );
    return response.data;
  }
}

const getSavedBibliographies = async (page: number, size: number, sort: string):Promise<any> => {
  const token: string = ApiUtils.getToken();
  if (token) {
    const params: any = {
      page,
      size,
      sort
    }
    const response: AxiosResponse<any, any> = await idlAPIClient.get<any>(
      SAVED_BIBLIOGRAPHIES_URL,
      {
        params
      }
    );
    return response.data;
  }
}

const saveBibliographies = async (data: any):Promise<any> => {
  return ApiUtils.makePost(data, SAVED_BIBLIOGRAPHIES_URL)
}

const deleteBibliographies = async (ids: number[]):Promise<any> => {
  const params: any =  {
    savedBiblioIds: ids.join(',')
  }
  return ApiUtils.doDelete(params, SAVED_BIBLIOGRAPHIES_URL)
}

/* Saved Searches */
const getSavedSearches = async (page: number, size: number, sort: string):Promise<any> => {
  const token: string = ApiUtils.getToken();
  if (token) {
    const params: any = {
      page,
      size,
      sort
    }
    const response: AxiosResponse<any, any> = await idlAPIClient.get<any>(
        SAVED_SEARCHES_URL,
        {
          params
        }
    );
    return response.data;
  }
}

const getSavedSearchesViaIds = async (ids: string[]):Promise<any> => {
  const token: string = ApiUtils.getToken();
  if (token) {
    const params: any =  {
      savedBiblios: ids.join(',')
    }
    const response: AxiosResponse<any, any> = await idlAPIClient.get<any>(
      SAVED_SEARCHES_URL,
      {params}
    );
    return response.data;
  }
}

const saveSearches = async (data: any):Promise<any> => {
  return ApiUtils.makePost(data, SAVED_SEARCHES_URL)
}

const deleteSearches = async (ids: number[]):Promise<any> => {
  const params: any =  {
    savedSearchIds: ids.join(',')
  }
  return ApiUtils.doDelete(params, SAVED_SEARCHES_URL)
}

const setDocumentNote = async (document: TSavedDocument):Promise<any> => {
  return ApiUtils.makePut([document], SAVED_DOCUMENTS_URL)
}

const SavedService = {
  getSavedDocuments,
  getSavedDocumentsViaIds,
  getSavedDocumentsViaTags,
  saveDocuments,
  deleteDocuments,
  getSavedBibliographies,
  getSavedBibliographiesViaIds,
  saveBibliographies,
  deleteBibliographies,
  getSavedSearches,
  getSavedSearchesViaIds,
  saveSearches,
  deleteSearches,
  setDocumentNote,
  appendDocumentNotes
}

export default SavedService;
