import React from "react";
import * as Constants from "../../../../constants";
import {IViewerState} from "../../../../types/redux/viewer";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux";

interface IProps {
  pageNum: number,
  totalPages: number
}

const PagePlaceholder = (props: IProps): JSX.Element => {
  const {
    pageNum,
    totalPages
  } = props;

  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);

  return (
    <div className={'page-placeholder bg-white border border-dark' + ((totalPages !== pageNum) ? ' mb-2' : '')}
         style={{
           height:String(Constants.VIEWER_MEDIA_HEIGHT * viewerState.zoom) + 'px',
           width:String(Constants.VIEWER_MEDIA_WIDTH * viewerState.zoom) + 'px',
           position: 'relative',
           display: 'flex',
           justifyContent: 'center'
         }}
    >
      <p className={'text-grey-dark'} style={{position: 'absolute', bottom: '.25rem'}}>page {pageNum}</p>
    </div>
  )
}

export default PagePlaceholder;