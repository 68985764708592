import {
  solrBaseClient
} from "./axios";
import {AxiosResponse} from "axios";

const solrContentTableQuery = async (api: string, signal: AbortSignal | undefined):Promise<any> => {
  const response:AxiosResponse<any, any> = await solrBaseClient.get<any>(api);
  return response.data;
}

const ContentTablesService = {
  solrContentTableQuery
}

export default ContentTablesService;
