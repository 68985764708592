/*

  Utils

 */
import * as Constants from "../constants";

const Utils = {
  getIndustryTitle: (industry: string): { industry_title: string, archive_title: string } => {
    switch (industry.toLowerCase()) {
      case Constants.ALL_INDUSTRIES:
        return {
          industry_title: Constants.ALL_INDUSTRIES_TITLE,
          archive_title: ''
        };

      case Constants.TOBACCO:
        return {
          industry_title: Constants.TOBACCO_TITLE,
          archive_title: Constants.TOBACCO_ARCHIVE_TITLE
        };


      case Constants.OPIOIDS:
        return {
          industry_title: Constants.OPIOIDS_TITLE,
          archive_title: Constants.OPIOIDS_ARCHIVE_TITLE
        };

      case Constants.CHEMICAL:
        return {
          industry_title: Constants.CHEMICAL_TITLE,
          archive_title: Constants.CHEMICAL_ARCHIVE_TITLE
        };

      case Constants.DRUG:
        return {
          industry_title: Constants.DRUG_TITLE,
          archive_title: Constants.DRUG_ARCHIVE_TITLE
        };

      case Constants.FOOD:
        return {
          industry_title: Constants.FOOD_TITLE,
          archive_title: Constants.FOOD_ARCHIVE_TITLE
        };

      case Constants.FOSSIL_FUEL:
        return {
          industry_title: Constants.FOSSIL_FUEL_TITLE,
          archive_title: Constants.FOSSIL_FUEL_ARCHIVE_TITLE
        };
    }
    return {
      industry_title: '',
      archive_title: ''
    };
  },

  removeTags: (str: string) => {
    return str.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, ' ');
  },

  convertBytes: (bytes: string): string => {
    const units = ['bytes', 'KiB', 'MiB', 'GiB'];
    let l = 0, n = parseInt(bytes, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  },

  formatNumber: (num: number): string => {
    return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  formatDate: (date: Date, includeTime: boolean = true): string => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const formatAMPM = (d: Date): string => {
      let hours = d.getHours();
      const minutes: number = d.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be 12
      const minutesStr = minutes < 10 ? '0' + minutes : minutes;
      return hours + ':' + minutesStr + ' ' + ampm;
    }
    const pad2 = (n: number): string | number => {
      return n < 10 ? '0' + n : n
    }
    return date.getFullYear().toString() + ' '
      + monthNames[date.getMonth()] + ' '
      + pad2(date.getDate())
      + (includeTime ? (', ' + formatAMPM(date)) : '')
  },

  formatDateShort: (date: string): string => {
    const parts: string[] = date.split(' ');

    return parts[1] + ' ' + parts[2] + ' ' + parts[0]
  },

  capitalizeFirstLetter: (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  toTitleCase: (str: string, removeDash: boolean = true): string => {
    if (!str || (str.length === 0)) {
      return str;
    }

    /* handle special cases */
    switch (str.toLowerCase()) {
      case 'idl':
        return 'IDL';

      case 'jti':
        return 'JTI'
    }

    if (removeDash) {
      str = str.replace(/-/g, ' ');
      str = str.replace(/_/g, ' ')
    }

    const strParts: string[] = str.split(' ');
    if (!strParts[0]) {
      return str;
    }

    /* replace more than 1 space with 1 space */
    str = str.replace(/  +/g, ' ');

    return str.split(' ')
      .map(w => w[0]?.toUpperCase() + w.substring(1).toLowerCase())
      .join(' ');
  },

  limitTitleLength: (str: string, length: number): string => {
    let returnString: string = str.substring(0, length - 1);
    if (length < str.length - 1) {
      returnString += '...';
    }
    return returnString;
  },

  getBucketPath: (id: string): string => {
    return Constants.DOCUMENTSTORE_URL + '/' + id.substring(0, 4).split("").join('/') + '/' + id;
  },

  validateEmail: (email: string): boolean => {
    const match: RegExpMatchArray | null = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    return match && (match.length > 0) ? true : false;
  },

  convertToKeyOrId: (value: string): string => {
    return value ? value.toLowerCase().replaceAll(' ', '-') : value;
  },

  removeFromList: (list: any[], toRemove: any[], key: string): any[] => {
    let clone: any[] = [...list];
    let index: number;
    for (let i = 0; i < toRemove.length; i++) {
      index = clone.findIndex((item: any) => String(item[key]) === String(toRemove[i]));
      clone.splice(index, 1);
    }
    return clone
  },

  // UTC date eg: '20231018195211'
  parseUTCDate: (dateStr: string | null): Date => {
    if (dateStr) {
      const year: number = parseInt(dateStr.substring(0, 4));
      const monthIndex: number = parseInt(dateStr.substring(4, 6));
      const day: number = parseInt(dateStr.substring(6, 8));
      const hour: number = parseInt(dateStr.substring(8, 10));
      const minutes: number = parseInt(dateStr.substring(10, 12));
      const seconds: number = parseInt(dateStr.substring(12, 14));
      return new Date(year, monthIndex, day, hour, minutes, seconds);
    } else {
      return new Date();
    }
  },

  generateUUID: (): string => {
    let d: number = new Date().getTime();
    let d2: number = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string) => {
      let r: number = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
  },

  getLocalizationStr: (): string => {
    if (navigator.languages !== undefined) {
      return navigator.languages[0];
    }
    return navigator.language;
  },

  downloadFile: (blob: any, filename: string): void => {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  },

  arrayAlphabetical: (arr: any[], getter: any, order = 'asc') =>
    arr.sort(
      order === 'desc'
        ? (a, b) => getter(b).localeCompare(getter(a))
        : (a, b) => getter(a).localeCompare(getter(b))
    ),


  parseNewsArticleId: ():string => {
    const urlSegments: string[] = window.location.pathname.split('/');
    const lastSegment: string = urlSegments[urlSegments.length - 1];
    if (lastSegment === Constants.NEWS) {
      return null
    } else {
      return lastSegment
    }
  },

  parseIndustrySection: ():{industry: string , section: string | null, id: string | null} =>{
    /* note: useParams does not capture url */
    const urlSegments: string[] = window.location.pathname.split('/');
    // it is global here
    let industry: string;
    let section: string | null;
    let id: string | null;

    const globalSections: string[] = ['news','resources','about-idl','help', 'placeholder']
    if ((globalSections.indexOf(urlSegments[1]) !== -1) || (!urlSegments[2]) ) {
      industry = Constants.GLOBAL;
      section = urlSegments[1];
      id = urlSegments[2];
    } else {
      industry = urlSegments[1];
      section = urlSegments[2];
      id = urlSegments[3];
    }

    return {
      industry,
      section,
      id
    }
  },

  capitalizeWord: (phrase: string, word: string):string =>{
    if (phrase.toLowerCase().indexOf(word) !== -1 ){
      return phrase.split(' ').map(s=> s.toLowerCase() === word ? word.toUpperCase(): s).join(' ')
    }
    return phrase
  },

  bootstrapBreakpoint: ():string => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 576) {
      return 'xs';
    } else if (windowWidth >= 576 && windowWidth < 768) {
      return 'sm';
    } else if (windowWidth >= 768 && windowWidth < 992) {
      return 'md';
    } else if (windowWidth >= 992 && windowWidth < 1200) {
      return 'lg';
    } else if (windowWidth >= 1200 && windowWidth < 1400) {
      return 'xl';
    } else {
      return 'xxl';
    }
  },

  isDesktop: ():boolean =>{
    const breakPoint: string = Utils.bootstrapBreakpoint();
    return ((breakPoint === 'lg') || (breakPoint === 'xl') || (breakPoint === 'xxl'));
  }
}

export default Utils;
