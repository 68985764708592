
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IDropdownItem, IViewerState, UpdateViewerAction} from "../../types/redux/viewer";
import * as Constants from "../../constants";

const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
/* @ts-ignore */
const zoom: number = searchParams.get(Constants.VIEWER_ZOOM_KEY) ? parseFloat(searchParams.get(Constants.VIEWER_ZOOM_KEY)) : 1;
const initialState: IViewerState = {
  recordTool: Constants.METADATA,
  possibleDuplicates: [],
  fullscreen: false,
  currentPage: 0,
  totalPages: 0,
  rotation: 0,
  thumbnailsCollapsed: true,
  panelCollapsed: true,
  contentView: Constants.PDF,
  zoom:zoom,
  prevZoom: 1,
  zoom_select: Constants.ZOOM_DROPDOWN_ITEMS[0],
  searchPanelOpen: false,
  searchTerm: '',
  numSearchResults: 0,
  currentSearchResult: 0,
  ocrAvailable: false,
  highlightAllResults: false,
  documentLoaded: false,
  documentRendered: false,
  viewed: []
};

export const viewerSlice = createSlice({
  name: UpdateViewerAction,
  initialState: initialState,
  reducers: {
    setPossibleDuplicates: (state: IViewerState, action: PayloadAction<any[]>) => {
      state.possibleDuplicates = action.payload;
    },

    setCurrentPage: (state: IViewerState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },

    setTotalPages: (state: IViewerState, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },

    incrementCurrentPage: (state: IViewerState) => {
      const incremented: number = state.currentPage + 1;
      if (incremented <= state.totalPages) {
        state.currentPage = incremented;
      }
    },

    decrementCurrentPage: (state: IViewerState) => {
      const decremented: number = state.currentPage - 1;
      if (decremented > 0) {
        state.currentPage = decremented;
      }
    },

    setZoomSelect: (state: IViewerState, action: PayloadAction<IDropdownItem>) => {
      state.zoom_select = action.payload;
    },

    setPrevZoom: (state: IViewerState) => {
      state.prevZoom = state.zoom;
    },

    setZoom: (state: IViewerState, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },

    incrementZoom: (state: IViewerState) => {
      let incremented: number = state.zoom + Constants.PDF_ZOOM_INCREMENT;
      if (incremented > Constants.PDF_MAX_ZOOM) {
        incremented = Constants.PDF_MAX_ZOOM;
      }
      state.zoom = incremented;
      state.zoom_select = {
        label: String(incremented),
        key: String(incremented)
      };
    },

    decrementZoom: (state: IViewerState) => {
      let decremented: number = state.zoom - Constants.PDF_ZOOM_INCREMENT;
      if (decremented < Constants.PDF_MIN_ZOOM) {
        decremented = Constants.PDF_MIN_ZOOM;
      }
      state.zoom = decremented;
      state.zoom_select = {
        label: String(decremented),
        key: String(decremented)
      };
    },

    setThumbnailsCollapsed: (state: IViewerState, action: PayloadAction<boolean>) => {
      state.thumbnailsCollapsed = action.payload;
    },

    setContentView: (state: IViewerState, action: PayloadAction<string | null>) => {
      state.contentView = action.payload;
    },

    setPanelCollapsed: (state: IViewerState, action: PayloadAction<boolean>) => {
      state.panelCollapsed = action.payload;
    },

    setSearchPanelOpen: (state: IViewerState, action: PayloadAction<boolean>) => {
      state.searchPanelOpen = action.payload;
      state.searchTerm = '';
      state.numSearchResults = 0;
      state.currentSearchResult = 0;
    },

    setSearchTerm: (state: IViewerState, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
      state.currentSearchResult = 0;
    },

    setNumSearchResults: (state: IViewerState, action: PayloadAction<number>) => {
      state.numSearchResults = action.payload;
    },

    setCurrentSearchResult: (state: IViewerState, action: PayloadAction<number>) => {
      state.currentSearchResult = action.payload;
    },

    setOcrAvailable: (state: IViewerState, action: PayloadAction<boolean>) => {
      state.ocrAvailable = action.payload;
    },

    setHighlightAllResults : (state: IViewerState, action: PayloadAction<boolean>) => {
      state.highlightAllResults = action.payload;
    },

    setDocumentLoaded: (state: IViewerState, action: PayloadAction<boolean>) => {
      state.documentLoaded = action.payload;
    },

    setDocumentRendered: (state: IViewerState, action: PayloadAction<boolean>) => {
      state.documentRendered = action.payload;
    },

    addToViewed: (state: IViewerState, action: PayloadAction<string>) => {
      state.viewed.push(action.payload);
    },

    setViewerFullscreen: (state: IViewerState, action: PayloadAction<boolean>) => {
      state.fullscreen = action.payload;
    },

    setDocumentRotation: (state: IViewerState, action: PayloadAction<number>) => {
      state.rotation = action.payload;
    },

    setRecordTool: (state: IViewerState, action: PayloadAction<string>) => {
      state.recordTool = action.payload;
    }
  }
});

export const {
  setPossibleDuplicates,
  setTotalPages,
  incrementCurrentPage,
  decrementCurrentPage,
  setCurrentPage,
  setZoomSelect,
  setZoom,
  setPrevZoom,
  incrementZoom,
  decrementZoom,
  setThumbnailsCollapsed,
  setPanelCollapsed,
  setContentView,
  setSearchPanelOpen,
  setSearchTerm,
  setNumSearchResults,
  setCurrentSearchResult,
  setOcrAvailable,
  setHighlightAllResults,
  setDocumentLoaded,
  setDocumentRendered,
  addToViewed,
  setViewerFullscreen,
  setDocumentRotation,
  setRecordTool
} = viewerSlice.actions;

export default viewerSlice.reducer;
