import './styles.scss';
import React, {Dispatch, useContext, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import {ITagModalContext, TagsModalContext} from "../../../../types/saved";

/* Bootstrap */
import {Button, Form, InputGroup} from "react-bootstrap";

/* Redux */
import {ISavedState, ITagState, TSavedDocument, TSavedDocumentTag} from "../../../../types/redux/saved";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux";

import {IRecordsContext, RecordsContext} from "../../../../types/document";
import TagsNotesService from "../../../../api/tags-notes";
import SavedService from "../../../../api/saved";
import {AnyAction} from "@reduxjs/toolkit";
import {updateSavedDocumentTags} from "../../../../redux/slices/saved-slice";
import {reloadTags} from "../../../../redux/slices/tags-notes-slice";

interface IProps {
  close(): void;
}

const TagRemove = (props: IProps):JSX.Element => {
  const {
    close
  } = props;

  const dispatch:Dispatch<AnyAction> = useDispatch();
  const tagsState: ITagState = useSelector<RootState, ITagState>(state => state.tags);
  const savedDocuments: TSavedDocument[] = useSelector<RootState, ISavedState>(state => state.saved).documents;

  const selectedRecords: any = useContext<IRecordsContext>(RecordsContext).records;
  const tag: TSavedDocumentTag | null = useContext<ITagModalContext>(TagsModalContext).selectedTag;

  const [checked, setChecked] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [documentUsedIn, setDocumentUsedIn] = useState<string[]>([]);

  useEffect(()=> {
    if (!tag) return;
    const recordsUsedIn = async (): Promise<any> => {
      const response: any = await SavedService.getSavedDocumentsViaTags(tag.id);
      const savedDocumentIds = response.savedDocument.map((savedDocument: TSavedDocument) => String(savedDocument.id));
      setDocumentUsedIn(savedDocumentIds)
    }

    recordsUsedIn();
  }, [tag])

  const handleSubmit = async (event: any): Promise<any> => {
    event.preventDefault();
    event.stopPropagation();

    setSubmitted(true)

    if (tag && (checked !== null)) {
      let savedDocumentIds: string[] = [];
      if (checked === Constants.REMOVE_TAG) {
        selectedRecords.forEach((selectedRecord:any) =>{
          const savedDocument: TSavedDocument | undefined = savedDocuments.find((savedDocument: TSavedDocument) => (savedDocument.documentId === selectedRecord.id));
          if (savedDocument) {
            savedDocumentIds.push(String(savedDocument.id))
          }
        })
       /* const savedDocument: TSavedDocument | undefined = savedDocuments.find((savedDocument: TSavedDocument) => (savedDocument.documentId === selectedRecords.id));*/
       /* if (savedSelectedRecords.length){
          savedDocumentIds = [String(savedDocument.id)]
        }*/
      } else {
        savedDocumentIds = documentUsedIn;
      }

      TagsNotesService.deleteTag(tag.id, savedDocumentIds).then(response => {
        /* remove from redux saved document */
        dispatch(updateSavedDocumentTags({ savedDocumentIds: savedDocumentIds, savedTags: [tag], action: Constants.REMOVE}));
        dispatch(reloadTags()); // re-fetch tag list, if no document referencing it then tag is not longer available
        close();
      }).catch(e=>{
        console.log(e)
      })
    }
  };

  return (
    <div className={'tag-remove w-100'}>
      {/* header */}
      <h6 className={'pt-2 pb-1 px-0'}>
        <b>Remove tag?</b>
      </h6>

      <hr/>

      {tag &&
        <div className={'mt-3'}>
          <div
            className={'tag rounded px-3 py-2 text-small me-1'}
            style={{backgroundColor: tagsState.colorDiction[tag.id]}}
          >
            <b>{tag.text}</b>
          </div>
        </div>}

      <Form className={'my-3 text-normal'} noValidate onSubmit={e => handleSubmit(e)}>
        <InputGroup hasValidation>
          <Form.Check
            type={'radio'}
            label={
              (selectedRecords.length === 1) ?
                <div className={'pt-1'}>Remove from this record only</div> :
                <div className={'pt-1'}>Remove from selected record only</div>}
            id={'remove-record-only'}
            checked={checked === Constants.REMOVE_TAG}
            onChange={e=>setChecked(Constants.REMOVE_TAG)}
          />

          <Form.Check
            className={'mt-1 mb-2'}
            type={'radio'}
            label={<div className={'pt-1'}>
                <span className={'me-1'}>Remove everywhere</span>
                <b>({documentUsedIn.length}{(documentUsedIn.length === 1) ? ' record' : ' records'})</b>
              </div>}
            id={'remove-everywhere'}
            checked={checked === Constants.REMOVE_ALL}
            onChange={e=>setChecked(Constants.REMOVE_ALL)}
          />

          <Form.Control.Feedback type={'invalid'} className={'mt-1 mb-3 text-normal ' + ((submitted && (checked === null)) ? 'show' : '')}>
            Please select one of the options above.
          </Form.Control.Feedback>
        </InputGroup>
        <hr/>

        <div className={'d-flex justify-content-end pt-3'}>
          <Button
            variant={'secondary border-0 me-2'}
            onClick={close}
          >
            Cancel
          </Button>

          <Button type={'submit'} variant={'primary'}>
            Remove Tag
          </Button>
        </div>
      </Form>

    </div>
  )
}
export default TagRemove;
