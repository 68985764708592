
export interface ISettings {
  search: string;
  search_results: {
    duplicates: boolean;
    folders: boolean;
    restricted: boolean;
  }
  sort: string;
  num_results: number;
}

export interface INotification {
  id: number;
  targetCode: string;
  targetType: string;
  lastNotified: any;
}


export interface INotificationItem {
  active: boolean;
  label: string;
  targetCode: string;
  type: string;
}

export interface IUserState {
  loggedIn: boolean;
  notificationsLoaded: boolean;
  notifications: INotification[];
  settings: ISettings;
}

export const UpdateUserAction: string = "update-user-action";
