import './styles.scss';
import React from "react";

/* Types, Constants, Utils */
import {ITagState, TSavedDocumentTag} from "../../../../types/redux/saved";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux";

interface IProps {
  appliedTags: TSavedDocumentTag[] | undefined
}

const Tags = (props: IProps):JSX.Element => {
  const {
    appliedTags
  } = props;
  const tagsState: ITagState = useSelector<RootState, ITagState>(state => state.tags);

  return (
    <div className={'tags-container d-flex'}>
      {appliedTags &&
        appliedTags.map((tag:TSavedDocumentTag, index: number) => {
          return <div
            className={'doc-tag border rounded px-3 py-2 text-small me-1 mt-2'}
            style={{backgroundColor: tagsState.colorDiction[tag.id]}}
            key={'dtg'+index}
          >
            <b>{tag.text}</b>
          </div>
        })
       }
    </div>
  )
}
export default Tags;
