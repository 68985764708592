
/* Types, Constants, Utils */
import * as Constants from "../../constants";

/* Redux */
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IMyLibraryState, UpdateMyLibraryAction} from "../../types/redux/my-library";

const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
const initialState = ():IMyLibraryState => {
  let section: string | null = urlSearchParams.get(Constants.MY_LIBRARY_SECTION_KEY)
  if (!section || (section === 'null')) {
    section = Constants.SAVED_DOCUMENTS;
  }
  return {
    section: section
  }
}

export const myLibrarySlice = createSlice({
  name: UpdateMyLibraryAction,
  initialState: initialState,
  reducers: {
    setLibrarySection: (state: IMyLibraryState, action: PayloadAction<string | null>) => {
      const section: string | null = action.payload;
      state.section = section;

     /* if (section) {
        urlSearchParams.set(Constants.MY_LIBRARY_SECTION_KEY, section)
        window.history.pushState(null, '', "?" + urlSearchParams.toString());
      } else {
        urlSearchParams.delete(Constants.MY_LIBRARY_SECTION_KEY)
        window.history.pushState(null, '');
      }*/
    }
  }
});

export const {
  setLibrarySection
} = myLibrarySlice.actions;

export default myLibrarySlice.reducer;
