import React, {useEffect, useState} from "react";
import {ISearchState} from "../../../types/redux/search";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {isElement} from "react-dom/test-utils";

export interface IUseCardField {
  getFieldContent (value: any): JSX.Element;
}

interface ISelection {
  start: number,
  end: number
}


const useCardField = (): IUseCardField => {
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const [searchTerms, setSearchTerms] = useState<string[]>([]);

  useEffect(()=>{
    /* Collect all the terms to select */
    /* @ts-ignore */
    const terms: string[] = searchState.query.filter(q => q.term !== null).map(q=> q.term).join(' ').split(' ');
    const filteredTerms: string[] = [];
    const QUOTE: string = '"';
    for (let i=0; i<terms.length; i++) {
      if (!terms[i]) continue;

      const term: string = terms[i];
      if (term[0] === QUOTE) {
        /* found start of a quote, need to find end if one exists */
        let found: boolean = false;
        let j: number = i + 1;
        let multiWord: string = term.substring(1, term.length);
        while (!found && j < terms.length) {
          const next: string = terms[j];
          if (next[next.length - 1] === QUOTE) {
            multiWord += (' ' + next.substring(0, next.length - 1))
            found = true
          } else {
            multiWord += (' ' + next)
          }
          j++;
        }

        if (found) {
          /* matching quote found */
          filteredTerms.push(multiWord);
          i = j - 1;
        } else {
          /* no matching quote found */
          filteredTerms.push(term)
        }
      } else {
        /* no quote */
        filteredTerms.push(term)
      }
    }
    setSearchTerms(filteredTerms)
  }, [searchState.query])

  const getFieldContent = (value: any): JSX.Element => {
    if (!value) return <></>;
    if (isElement(value)) return value;

    if (Array.isArray(value)){
      value = value.join(', ')
    }
    value = String(value);

    /* Find all selections */
    const selections: ISelection[] = [];
    searchTerms.forEach(t=>{
      let results:any[] = [];
      try {
        results = value.matchAll(new RegExp(t, 'gi'));
      } catch (e) {
        //console.log('matchAll ERROR: ' + e)
      }
      for (let result of results) {
        // console.log('result[0] ' + result[0])
        selections.push({
          start: result.index,
          end: result.index + result[0].length
        })
      }
    });
    selections.sort((a, b) => (a.start > b.start) ? 1 : -1)

    /* Insert selections */
    let text: string = value;
    if (selections.length > 0) {
      text = value.substring(0, selections[0].start);
      for (let i=0; i<selections.length; i++) {
        const select: any = selections[i];
        text += ('<sel>' + value.substring(select.start, select.end) + '</sel>');
        if (i<(selections.length - 1)) {
          text += value.substring(select.end, selections[i+1].start)
        } else {
          text += value.substring(select.end, value.length)
        }
      }
    }

    return <span dangerouslySetInnerHTML={{__html: text}}/>
  }

  return {
    getFieldContent
  }
};

export default useCardField;