import './styles.scss';
import React, {useEffect, useState} from "react";

import {Button, Form} from "react-bootstrap";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';

import * as Constants from "../../../../constants";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface IProps {
  setError(value:boolean): void;
  placeholder: string;
  dataKey: string;
  data: any;
  updateDates(data: any): void;
  closePickers:boolean;
  setClosePickers(value: boolean): void;
}

const DateField = (props: IProps):JSX.Element => {
  const {
    data,
    dataKey,
    updateDates,
    closePickers,
    placeholder,
    setError,
    setClosePickers
  } = props;

  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');
  const [fieldError, setFieldError] = useState<boolean>(false);

  useEffect(()=>{
    if (closePickers) {
      setCalendarOpen(false);
      setClosePickers(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[closePickers]);

  useEffect(()=>{
    if (!data[dataKey]) {
      setDate('');
      setError(false);
    }
  }, [data, dataKey, setDate, setError])

  const handleKeydown = (e:React.KeyboardEvent):void => {
    setError(false);
    setFieldError(false);
    if ((e.key !== Constants.BACKSPACE) && (e.key !== '/') && isNaN(parseInt(e.key))) {
      e.preventDefault();
    }

    if (e.key === Constants.ENTER) {
      handleOnChange(date)
    }
  }

  const calendarDate = (): Date => {
    if (data[dataKey]) {
      const date: Date = new Date(data[dataKey].toString());
      if (date !== undefined) {
        return date;
      }
    }
    return new Date()
  }

  useEffect(()=>{
    setCalendarOpen(false);
    setClosePickers(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  const handleOnChange = (dateStr: string):void => {
    setError(false);
    setFieldError(false);

    const newDate: Date = new Date(dateStr);
    if (newDate.toDateString() !== Constants.INVALID_DATE) {
      setDate(dateStr)
      data[dataKey] = newDate.toLocaleDateString();
      updateDates(data)
    } else {
      setError(true);
      setFieldError(true);
    }
  }

  return (
    <div>
      <div className={'d-flex justify-content-evenly w-100'}>
        <Button
          variant={'light'}
          className={'date-field-btn bg-transparent border-0 w-100 py-1 px-1'}
          onClick={()=>setCalendarOpen(true)}
        >
          <Form.Control
            className={'date-field-input px-0 mx-0 bg-transparent text-center border-0' + (fieldError ? ' text-danger' : '')}
            type={'text'}
            maxLength={10}
            placeholder={placeholder}
            onKeyDown={handleKeydown}
            value={date}
            onChange={(e)=>{setDate(e.target.value)}}
          />
        </Button>
      </div>

      <div className={'calendar-container position-absolute shadow mt-2 d-flex'}>
        {calendarOpen &&
          <Calendar
            selectRange={false}
            showFixedNumberOfWeeks={true}
            value={calendarDate()}
            onChange={(value: Value)=>{
              if (value) {
                /* @ts-ignore */
                handleOnChange(value.toLocaleDateString().toString());
              }
            }}/>
          }
      </div>
    </div>
  )
}

export default DateField;
