import './styles.scss';
import React from "react";

/* Bootstrap */
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";

interface IProps {
  handleSubmit(e:React.MouseEvent): void;
  disabled: boolean;
}

const SearchSubmit = (props: IProps):JSX.Element => {
  const {
    handleSubmit
  } = props;

  return (
    <div className={'search-btn'}>
      <OverlayTrigger overlay={
        <Tooltip className={'mb-3'}>Perform search</Tooltip>}
      >
        <Button
          className={'border-0 bg-transparent rounded-0 rounded-end h-100 text-black' }
          onClick={e=>handleSubmit(e)}
        >
          <i className="bi bi-search"/>
        </Button>
      </OverlayTrigger>
    </div>
  )
}

export default SearchSubmit;
