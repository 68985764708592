import {
  CARD_GRID,
  CARD_MENU_GRID,
  COLLAPSE_BLOCK,
  CONTENT,
  CONTENT_GRID,
  EMAIL_FORM,
  HORIZONTAL_LINE,
  IFRAME_BLOCK,
  MEDIA_BLOCK, NEWS_FEED_BLOCK,
  SEARCHABLE_TABLE
} from './blocks'

export const PAGE_REQUEST_FIELDS = `
    docs {
      id
      slug
      stage 
      parent
      industries {
        id
        title
      }
      categories {
        id
        title
      }
      title
      layout {
        ${CONTENT}
        ${CARD_GRID}
        ${CARD_MENU_GRID}
        ${CONTENT_GRID}
        ${COLLAPSE_BLOCK}
        ${MEDIA_BLOCK}
        ${EMAIL_FORM}
        ${HORIZONTAL_LINE}
        ${IFRAME_BLOCK}
        ${SEARCHABLE_TABLE}
        ${NEWS_FEED_BLOCK}
      }
    }
`
export const STAGE_ID_BY_CATEGORY = `
  query Pages($industry: JSON, $category: JSON, $parent: Boolean) {
    Pages(where: { AND: [{industries: { equals: $industry }}, {categories: { equals: $category }}, {parent: {equals: $parent}} ]}) {
      ${PAGE_REQUEST_FIELDS}
    }
  }
`

export const PAGES_BY_CATEGORY = `
  query Pages($industry: JSON, $category: JSON, $parent: Boolean, limit: 300) {
    Pages(where: { AND: [{industries: { equals: $industry }}, {categories: { equals: $category }}, {parent: {equals: $parent}} ]}) {
      ${PAGE_REQUEST_FIELDS}
    }
  }
`

export const PAGE_QUERY = `
  query Pages($id: String) {
    Pages(where: { id: { equals: $id }}, limit: 1, draft:true) {
      ${PAGE_REQUEST_FIELDS}
    }
  }
`

export const PAGES_QUERY = `
  query Pages {
    Pages(limit: 300)  {
      docs {
        ${PAGE_REQUEST_FIELDS}
      }
    }
  }
`

export const STAGE_PAGES_QUERY = `
  query StagePages {
    StagePages(limit: 300)  {
      docs {
        ${PAGE_REQUEST_FIELDS}
      }
    }
  }
`

export const STAGE_PAGE_QUERY = `
  query StagePages($id: String) {
    StagePages(where: { id: { equals: $id }}, limit: 1, draft: true) {
      ${PAGE_REQUEST_FIELDS}
    }
  }
`

export const STAGE_PAGES_BY_CATEGORY = `
  query StagePages($industry: JSON, $category: JSON, $parent: Boolean) {
    StagePages(limit: 300, where: { AND: [{industries: { equals: $industry }}, {categories: { equals: $category }}, {parent: {equals: $parent}} ]}) {
      ${PAGE_REQUEST_FIELDS}
    }
  }
`



