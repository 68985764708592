/* Type, Constants, Utils */
import * as Constants from "../../../constants";

/* Redux */
import {useDispatch} from "react-redux";
import {Dispatch} from "react";
import {AnyAction} from "@reduxjs/toolkit";

import LoginRegistrationService from '../../../api/login-registration';
import {setLoggedIn} from "../../../redux/slices/user-slice";
import {setLoginModalActive} from "../../../redux/slices/login-modal-slice";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {useNavigate} from "react-router-dom";
import {setFiltersKey} from "../../../redux/slices/search-slice";
import {setLibrarySection} from "../../../redux/slices/my-library-slice";
import useSearchReset from "../../../hooks/useSearchReset";

const useLogin = (): Function => {
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const searchReset: Function = useSearchReset();

  return async (email: string, password: string, keepSignedIn: string, context: string): Promise<any> => {
    const formData: FormData = new FormData();
    formData.append("email", email);
    formData.append("password", password);


    return LoginRegistrationService.login(formData).then(response => {
      dispatch(setLoggedIn(true))
      if (keepSignedIn) {
        formData.append("remember-me", 'on');
        localStorage.setItem(Constants.REMEMBER_ME_KEY, 'true')
      }

      if (context === Constants.MODAL) {
        dispatch(setLoginModalActive(false));
      } else {
        /* navigate to My Library if from login view */
        searchReset();
        dispatch(setLibrarySection(Constants.SAVED_DOCUMENTS))
        dispatch(setFiltersKey(Constants.TAGS))

        navigate(Constants.MY_LIBRARY_PATHNAME);
      }
      return true;
    }).catch(e => {
      return false;
    })
  }
};

export default useLogin;