
import { useRef,} from "react";
import {AxiosProgressEvent} from "axios/index";
import DownloadService from "../../../../api/download";
import Utils from "../../../../utils";
import {useForceUpdate} from "../../../../hooks";
import {IDownload} from "./index";

export interface IUseDownloadFile {
  downloadFile (id: string, filename: string, allFilesZip: boolean): IDownload[];
  downloads: IDownload[];
}

const useDownloadFile = (): IUseDownloadFile => {
  const downloads = useRef<IDownload[]>([]);
  const forceUpdate = useForceUpdate();

  const handleDownloadProgress = (filename: string, progressEvent: AxiosProgressEvent): void => {
    const index: number = downloads.current.findIndex(dl => dl.filename === filename);
    if ((index !== -1 ) && (progressEvent.total)) {
      const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (percentage > 99) {
        downloads.current.splice(index,1)
      } else {
        downloads.current[index].percentage = percentage;
      }
      forceUpdate();
    }
  }

  const downloadFile = (id: string, filename: string, allFilesZip: boolean): IDownload[] => {
    const index: number = downloads.current.findIndex(dl => dl.filename === filename);
    if (index === -1) { // disallow duplicate file downloads
      downloads.current.push({
        filename,
        percentage: 0
      });
      forceUpdate();

      if (allFilesZip) {
        DownloadService.downloadAllFiles(id, filename, handleDownloadProgress)
          .then(response =>Utils.downloadFile(response.data, filename))
          .catch(e=>{
            // todo: error handling
          });
      } else {
        DownloadService.downloadFile(id, filename, handleDownloadProgress)
          .then(response =>Utils.downloadFile(response.data, filename))
          .catch(e=>{
            // todo: error handling
          });
      }
    }
    return downloads.current
  }

  return {
    downloadFile,
    downloads: downloads.current
  }
};

export default useDownloadFile;