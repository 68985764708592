import './styles.scss';
import React, {useRef, useState} from "react";

/* Bootstrap */
import {Container, Nav, Navbar} from "react-bootstrap";

const HeaderUCSF = ():JSX.Element => {
  const [toggleActive, setToggleActive] = useState<boolean>(false);
  const navbarToggleRef:React.MutableRefObject<any> = useRef(null)

  const handleToggle = ():void => {
    if (toggleActive) {
      navbarToggleRef.current.blur();
    }
    setToggleActive(!toggleActive);
  }

  return (
    <div className={'header-ucsf'}>
      <Navbar className={"py-1 navbar-toggleable-xl"} onToggle={handleToggle}>
        <Container>
          <Navbar.Brand className={"text-white m-0"} href={"/home"}>
            <img className={'me-3'} src="/assets/images/ucsf-logo-white.svg" alt="" height="30"/>
            <h2 className={'d-none d-md-inline-block text-smaller font-thin'}>University of California San Francisco</h2>
          </Navbar.Brand>

          <Navbar.Toggle className={"navbar-dark py-2"} aria-controls={"header-ucsf-navbar-nav"} ref={navbarToggleRef}/>
          <Navbar.Collapse id="header-navbar-nav" className={"justify-content-end"}>
            <Nav className={"text-small navbar-dark p-1 font-light"}>
              <Nav.Link href="https://www.ucsf.edu/about">About UCSF</Nav.Link>
              <Nav.Link className={'ms-3'} href="https://www.ucsf.edu/search">Search UCSF</Nav.Link>
              <Nav.Link className={'ms-3'} href="https://www.ucsfhealth.org">UCSF Health</Nav.Link>
            </Nav>
          </Navbar.Collapse>

        </Container>
      </Navbar>
    </div>
  )
}

export default HeaderUCSF;
