import './styles.scss';
import React, {useContext, useEffect} from "react";

/* types, constants, utils */
import * as Constants from "../../../../../constants";
import {IViewerRecordContext, ViewerRecordContext} from "../../../../../types/document";

/* bootstrap */
import {Button} from "react-bootstrap";

/* redux */
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux";
import {ISearchState} from "../../../../../types/redux/search";

/* components */
import ViewerCard from "../../../viewer-card";

interface IProps {
  handleSelect(document: any): void
  setNumOfRecords(value:number): void;
}

const ViewerMoreLikeList = (props: IProps):JSX.Element => {
  const {
    handleSelect,
    setNumOfRecords
  } = props;

  const initialSelectedRecord: any = useContext<IViewerRecordContext>(ViewerRecordContext).initialRecord;
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);

  const card = (result: any, index: number):JSX.Element => {
    return <Button
      key={`dc-${index}`}
      className={'viewer-card-wrapper mb-2 text-dark p-0 bg-transparent text-start w-100'}
      active={initialSelectedRecord && (initialSelectedRecord.id === result.id)}
      onClick={()=>handleSelect(result)}
    >
      <ViewerCard
        index={index}
        record={result}
        page={1}
        resultsPerPage={Constants.VW_NUM_ROWS}
      />
    </Button>
  }

  useEffect(()=>{
    setNumOfRecords(Constants.MORE_LIKE_ROWS)
  }, [setNumOfRecords])

  const moreLikeThis = (): any[] => {
    let moreLikeThis:string[] = [];
    if (initialSelectedRecord.moreLikeThis &&
      initialSelectedRecord.moreLikeThis[initialSelectedRecord.id] &&
      initialSelectedRecord.moreLikeThis[initialSelectedRecord.id].docs
    ) {
      if (searchState.industry === Constants.ALL_INDUSTRIES) {
        moreLikeThis = initialSelectedRecord.moreLikeThis[initialSelectedRecord.id].docs;
      } else {
        /* industries specific documents */
        const docs: any[] = initialSelectedRecord.moreLikeThis[initialSelectedRecord.id].docs;
        for (let i = 0; i < docs.length; i++) {
          if (docs[i].industrycode.toLowerCase() === searchState.industry.toLowerCase()) {
            moreLikeThis.push(docs[i])
          }
        }
      }
    }

    return moreLikeThis
  }

  return (
    <div className={'viewer-more-like-list overflow-scroll pt-2'}>
      <div className={'cards d-flex flex-column pb-2'} >
        {card(initialSelectedRecord, -1)}

        {initialSelectedRecord && moreLikeThis().map((result: any, index: number) => {
          return card(result, index)
        })}
      </div>
    </div>
  )
}
export default ViewerMoreLikeList;
