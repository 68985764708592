import './styles.scss';
import React, {Dispatch, useEffect} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../constants";
import Utils from "../../utils";

/* Bootstrap */
import { Container} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {AnyAction} from "@reduxjs/toolkit";
import {addCrumb, removeToCrumb} from "../../redux/slices/breadcrumb-slice";

/* Components */
import ViewSubheader from "../../components/view/view-subheader";
import LoginRegistration from "../../components/login-registration";
import ViewScrollContainer from "../../components/view/view-scroll-container";
import {IBreadCrumbState} from "../../types/redux/breadcrumb";
import {RootState} from "../../redux";

const Login = ():JSX.Element => {
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const breadcrumbState: IBreadCrumbState = useSelector<RootState, IBreadCrumbState>(state => state.breadcrumb);

  useEffect(()=>{
    dispatch(removeToCrumb(breadcrumbState.breadcrumbs[0]));
    dispatch(addCrumb({
      label: Utils.toTitleCase(Constants.LOGIN),
      href: '/' + Constants.LOGIN,
      level: 2,
      active: true
    }));

    sessionStorage.removeItem('csrf');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={'login-view view'}>
      <ViewScrollContainer >
        <ViewSubheader
          heading={Utils.toTitleCase(Constants.LOGIN)}
        />

        <Container className={'px-5'}>
          <div className={'bg-white my-5 pt-4 pb-5 px-5 rounded-md'}>
            <LoginRegistration context={Constants.VIEW}/>
          </div>
        </Container>
      </ViewScrollContainer>
    </div>
  )
}

export default Login;
