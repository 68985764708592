import './styles.scss';
import React from "react";

/* Bootstrap */
import {Button, Modal} from "react-bootstrap";

interface IProps {
  title: string,
  content?: JSX.Element,
  confirmBtnText: string,
  callback():void
  show: boolean;
  onHide(): void;
}

const ConfirmModal = (props: IProps):JSX.Element => {
  const {
    title,
    content,
    confirmBtnText,
    callback,
    show,
    onHide
  } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop={'static'}
      centered
      className={'confirm-modal'}
    >
      <Modal.Header closeButton className={'pb-2 px-4'}>
        <Modal.Title className={'fs-5'}>{title}</Modal.Title>
      </Modal.Header>

      {content && <Modal.Body className={'pt-4 pb-2 px-4'}>
        {content}
      </Modal.Body>}

      <Modal.Footer className={'pb-3 px-4'}>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
        <Button variant="primary" onClick={callback}>{confirmBtnText}</Button>
      </Modal.Footer>
    </Modal>
  )
}
export default ConfirmModal;
