export type IToast = {
  bg: string;
  msg: string;
  id: number;
}
export interface IToasts {
  toasts: IToast[];
  lastId: number;
}
export const UpdateToastAction: string = "update-toast-action";
