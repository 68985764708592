import METADATA from '../assets/data/search.meta.json';

export const SingleDocumentQuery = (id: string ): string => {
  /*
  /*  legacy code: kept to reduce introduce bugs and preserve comments
  */
  let fields: string[] = [];
  for(let key in METADATA["allFields"]){
    if (key === "cn") { //push collectioncode instead of cn because collecitoncode is the name of the field in the index
      fields.push("collectioncode");
    } else if (key !== "ot" && key.indexOf("-") < 0) {
      // all data except the 'ot', the ot will be included in the .viewer-pdf,
      // also don't push any fields with dash (-) these fields don't exists in the index
      fields.push(key);
    }
  }
  fields.push("signature");

  return "wt=json"
    + "&indent=true"
    + "&id=" + id
    + "&fl=" + fields.join(",")
    + "&mlt.count=10";  // "more like this" count set to 10
}