import './styles.scss';
import React, {Dispatch, useContext, useState} from "react";

/* Type, Constants, Utils */
import * as Constants from "../../../constants";

/* Context */
import {CardContext, IContext} from "../../../types/document";
import {IPageContext, PageContext} from "../../../types/app";

/* Bootstrap */
import {Button, Dropdown} from "react-bootstrap";
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";

/* Redux */
import {addToast} from "../../../redux/slices/toast-slice";

/* Components */
import EmailModal from "../../modal/email-modal";

/* third party */
import copy from "copy-to-clipboard";

interface IProps {
  type: string;
  records: any[];
}

export const ShareItems = (props: IProps): JSX.Element => {
  const {
    type,
    records
  } = props;

  const dispatch:Dispatch<AnyAction> = useDispatch();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const cardContext: any = useContext<IContext>(CardContext).context;
  const pageContent: any = useContext<IPageContext>(PageContext).context;

  const openModal = (): void => {
    setModalShow(true);
  }

  const getLink = ():string => {
    let documentLink: string = '';
    if (pageContent === Constants.CONTENT) {
      documentLink = window.location.href;
    } else {
      switch (cardContext) {
        case Constants.SEARCH:
          for (let i=0; i<records.length; i++) {
            documentLink = records[i].url;
            if (i < (records.length - 1)) {
              documentLink += '%0A';
            }
          }
          break;

        default:
          for (let i=0; i<records.length; i++) {
            documentLink += window.location.protocol + "//" + window.location.host + '/viewer/?' + records[i].id;
            if (i < (records.length - 1)) {
              documentLink += '%0A';
            }
          }
          break;
      }
    }

    return documentLink
  }

  const copyLink = (): void => {
    if ((pageContent !== Constants.CONTENT) && (records.length === 0)) {
      return
    }

    try {
      if (copy(getLink())) {
        dispatch(addToast({
          bg: 'bg-success',
          msg: Constants.LINK_COPIED,
          id: -1
        }))
      } else {
        console.log(Constants.UNABLE_TO_COPY)
      }
    } catch (e) {
      throw new Error(Constants.UNABLE_TO_COPY)
    }
  }

  const postTwitter = (): void => {
    const navUrl =
      'https://twitter.com/intent/tweet?text=' + getLink();
    window.open(navUrl, '_blank');
  }

  const postFacebook = (): void => {
    const navUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + getLink();
    window.open(navUrl , '_blank');
  }

  const postLinkedIn = (): void => {
    const navUrl = 'https://www.linkedin.com/shareArticle?mini=true&url=' + getLink();
    window.open(navUrl , '_blank');
  }

  const postReddit = (): void => {
    const navUrl = '//www.reddit.com/submit?url=' + getLink();
    window.open(navUrl , '_blank');
  }

  const items: any[] = [
    {
      label: 'Copy Link',
      icon: 'bi-link',
      onClick: copyLink
    },
    {
      label: 'Email',
      icon: 'bi-envelope',
      onClick: openModal
    },
    {
      label: 'Twitter',
      icon: 'bi-twitter',
      onClick: postTwitter
    },
    {
      label: 'Facebook',
      icon: 'bi-facebook',
      onClick: postFacebook
    },
    {
      label: 'LinkedIn',
      icon: 'bi-linkedin',
      onClick: postLinkedIn
    },
    {
      label: 'Reddit',
      icon: 'bi-reddit',
      onClick: postReddit
    }
  ]


  const itemJSX = (item: any, index: number): JSX.Element => {
    let jsx: JSX.Element;
    if (type === Constants.DROPDOWN_ITEM) {
      jsx = <Dropdown.Item
        key={'si'+index}
        href="#"
        onClick={() => item.onClick.apply()}
      >
        <i className={'bi fst-normal me-2 ' + item.icon}/>
        {item.label}
      </Dropdown.Item>
    } else {
      jsx = <Button
        className={'rounded-1 bg-transparent w-100 my-2' + (index === 0 ? '' : ' text-dark')}
        variant={(index === 0 ? 'primary' : 'secondary')}
        key={'si'+index}
        onClick={() => item.onClick.apply()}
      >
        <i className={'bi fst-normal me-2 ' + item.icon}/>
        {item.label}
      </Button>
    }
    return jsx;
  }

  return (<div className={'shared-items'}>
    {items.map((item, index) => {
      return itemJSX(item, index)
    })}

    {(records.length > 0) && <EmailModal
      records={records}
      show={modalShow}
      onHide={() => setModalShow(false)}
    />}
  </div>)
}
