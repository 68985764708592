import './styles.scss';
import React, {Dispatch, useContext, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import {IRecordsContext, RecordsContext} from "../../../../types/document";

/* Bootstrap */
import {Button, Col, Form, Row} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import {AnyAction} from "@reduxjs/toolkit";
import {ITagState, TSavedDocumentTag} from "../../../../types/redux/saved";
import {addTags} from "../../../../redux/slices/tags-notes-slice";
import {updateSavedDocumentTags} from "../../../../redux/slices/saved-slice";

/* Api */
import TagsNotesService from "../../../../api/tags-notes";

/* Components */
import TagAddRemoveEdit from "../tag-add-remove-edit";
import useSaveSelected from "../../../../hooks/useSaveSelected";

interface IProps {
  appliedTags: TSavedDocumentTag[] | undefined;
  close():void;
}

const TagsApply = (props: IProps):JSX.Element => {
  const {
    appliedTags,
    close
  } = props;

  /* redux state */
  const tagsState: ITagState = useSelector<RootState, ITagState>(state => state.tags);
  const dispatch:Dispatch<AnyAction> = useDispatch();

  /* context */
  const selectedRecords: any = useContext<IRecordsContext>(RecordsContext).records;

  /* state */
  const [newTagName, setNewTagName] = useState<string>('');
  const [tagsNotApplied, setTagsNotApplied] = useState<TSavedDocumentTag[]>([...tagsState.tags])
  const [error, setError] = useState<any>(null);

  /* hooks */
  const saveSelected: Function = useSaveSelected();

  useEffect(()=> {
    if (!appliedTags) return;

    const tags: TSavedDocumentTag[] = [...tagsState.tags];
    appliedTags.forEach(appliedTag => {
      const index: number = tags.findIndex(tag=>tag.id === appliedTag.id);
      if (index !== -1) {
        tags.splice(index, 1)
      }
    })
    setTagsNotApplied(tags);

  }, [appliedTags, tagsState.tags])

  const createTag = async (): Promise<any> =>{
    if (newTagName.length === 0) return;

    const existingTag: TSavedDocumentTag | undefined = tagsState.tags.find((tag:TSavedDocumentTag) => tag.text.toLowerCase() === newTagName.toLowerCase());
    if (existingTag) {
      throw new Error('Name already exists, please choose another.')
    }

    const savedDocumentIdsObjs: any[] = await saveSelected(selectedRecords);
    TagsNotesService.createTag(newTagName, savedDocumentIdsObjs).then(response=>{
      dispatch(addTags(response));
      /* redux requires list of ids */
      const savedDocumentIds: string[] = savedDocumentIdsObjs.map(savedDocumentIdObj => savedDocumentIdObj.id)
      dispatch(updateSavedDocumentTags({ savedDocumentIds: savedDocumentIds, savedTags: response, action: Constants.ADD}))
    }).catch(e=>{
      throw new Error('Sorry, an error occurred')
    });

    setNewTagName('');
  }


  const handleAdd = ():void => {
    try {
      createTag();
    } catch (e) {
      setError(e)
    }
  }

  const handleKeydown = (e: any): void => {
    setError(null);

    if (e.key === Constants.ENTER) {
      e.preventDefault();
      e.stopPropagation();
      handleAdd();
    }
  }

  const applyTag = async (tag: TSavedDocumentTag): Promise<any> => {
    const savedDocumentIdObjs: any[] = await saveSelected(selectedRecords);

    TagsNotesService.applyTag(String(tag.id), savedDocumentIdObjs).then(response=>{
      /* redux requires list of ids */
      const savedDocumentIds: string[] = savedDocumentIdObjs.map(savedDocumentIdObj => savedDocumentIdObj.id)
      dispatch(updateSavedDocumentTags({ savedDocumentIds: savedDocumentIds, savedTags: [tag], action: Constants.ADD}))
    }).catch(e=>{
      throw new Error('Sorry, an error occurred')
    });
  }

  return (
    <div className={'tags-apply w-100 d-inline-block position-relative'}>
      {/* Header - Close button */}
      <Row className={'justify-content-end'}>
        <Button
          className={'close-btn border-0 btn-icon-only position-absolute'}
          onClick={close}
        >
          <i className={'bi bi-x text-dark h4'}/>
        </Button>
      </Row>

      {/* New Tag name input */}
      <Row>
        <Col lg={9}  className={'mt-3 text-center'}>
          <Form.Control
            required
            type={'text'}
            placeholder={'Add a tag'}
            value={newTagName}
            onKeyDown={handleKeydown}
            onChange={e=>setNewTagName(e.target.value)}
          />
          {error && <p className={'text-danger text-small'}>error.message</p>}
        </Col>
        <Col lg={3} className={'px-0 pt-3'}>
          <Button
            className={''}
            onClick={handleAdd}
          >
            Add
          </Button>
        </Col>
      </Row>

      {/* title bar */}
      <Row className={'mt-3'}>
        {(selectedRecords.length === 1) &&
          <Col lg={8}><b>Applied to this record ({appliedTags ? appliedTags.length : 0})</b></Col>}
        {(selectedRecords.length > 1) &&
          <Col lg={8}><b>Applied to these records ({appliedTags ? appliedTags.length : 0})</b></Col>}
        <Col lg={1} className={'ps-0 pe-0 text-center'}>Edit</Col>
        <Col lg={3} className={'ps-0 pe-0 text-center'}>Remove</Col>
      </Row>

      {/* Tags - applied*/}
      {appliedTags &&
        <div className={'tag-scroll-container mt-2 mb-3'}>
          <div>
            {appliedTags.map((tag:TSavedDocumentTag, index: number) =>
              <TagAddRemoveEdit
                key={'tag'+index}
                tag={tag}
              />).reverse()}
          </div>
        </div>
      }

      <hr></hr>

      {/* Tags - Not applied*/}
      {tagsNotApplied &&
        <div className={'tag-scroll-container'}>
          <div>
            {tagsNotApplied.map((tag:TSavedDocumentTag, index: number) =>
              <Row className={'px-3 mt-1'}  key={'aptgs'+index}>
                <Col lg={9}>
                  <Button
                    variant={'link'}
                    className={'p-0 text-smaller link-underline-none text-black'}
                    onClick={e=>applyTag(tag)}
                  >
                    <b>{tag.text}</b>
                  </Button>
                </Col>
                <Col>
                  <Button
                    className={'btn-icon-only'}
                    onClick={e=>applyTag(tag)}
                  >
                    <i className={'bi bi-plus text-secondary h4 m-0'}/>
                  </Button>
                </Col>

              </Row>)}
          </div>
        </div>}

      {/* All tags applied*/}
      {tagsNotApplied && (tagsNotApplied.length === 0) &&
         <p className={'p-0 m-0 mt-2 text-secondary'}>All tags are being used.</p>}
    </div>
  )
}
export default TagsApply;
