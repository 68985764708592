import React, { Fragment } from 'react'

import { Image } from './Image'
import { Props } from './types'
import { Video } from './Video'

export const Media: React.FC<Props> = props => {
  const {  resource} = props
  const isVideo = typeof resource !== 'string' && resource?.mimeType?.includes('video')
  return (
    <>
      {isVideo ? <Video {...props} /> : <Image {...props} />}
    </>
  )
}
