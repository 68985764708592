import './styles.scss';
import React, {Dispatch, useContext, useEffect, useRef, useState} from "react";

/* Types, Constants, Utils*/
import {IViewerRecordContext, ViewerRecordContext} from "../../../types/document";
import * as Constants from "../../../constants";

/* Redux */
import {setPrevZoom} from "../../../redux/slices/viewer-slice";
import {IViewerState} from "../../../types/redux/viewer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {AnyAction} from "@reduxjs/toolkit";

import Spinner from "../../util/spinner";
import DocumentUtils from "../../../utils/document-utils";

const ViewerMedia = (): JSX.Element => {
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const record: any = useContext<IViewerRecordContext>(ViewerRecordContext).viewerRecord;
  const mediaContainerRef: React.MutableRefObject<any> = useRef(null);
  const [url, setUrl] = useState<string | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [dimensions, setDimensions] = useState<{ width: any, height: any }>(
    {width: Constants.VIDEO_DEFAULT_WIDTH, height: Constants.VIDEO_DEFAULT_HEIGHT});
  const [fileType, setFileType] = useState<string>('');

  useEffect(()=>{
    setLoaded(false)
    let _url: string | null = null;
    if (record.hasOwnProperty('access')) {
      const access: any = JSON.parse(record.access)
      if (access.title === "Internet Archive") { /* legacy implementation to avoid introducing bugs */
        _url = "https://archive.org/embed/" + access.id;
      } else {
        _url = access.url;
      }
    }
    setUrl(_url);
    const fileType: string | undefined = DocumentUtils.getFileAttributes(record).type;
    if (fileType) {
      setFileType(fileType);
    }
  }, [record])

  /*
    Zoom
 */
  useEffect(()=> {
    if (!mediaContainerRef.current) return;

    if (viewerState.prevZoom !== viewerState.zoom) {
      let ratioHeight: number = viewerState.zoom;
      let ratioWidth: number = viewerState.zoom;

      if (Constants.VIDEO_DEFAULT_HEIGHT * viewerState.zoom > Constants.VIEWER_MEDIA_HEIGHT) {
        ratioHeight = Constants.VIEWER_MEDIA_HEIGHT/Constants.VIDEO_DEFAULT_HEIGHT;
      }

      if (dimensions.width * viewerState.zoom > Constants.VIEWER_MEDIA_HEIGHT) {
        ratioWidth = mediaContainerRef.current.clientWidth/Constants.VIEWER_MEDIA_WIDTH;
      }

      let ratio: number;
      if (ratioWidth < ratioHeight) {
        ratio = ratioWidth
      } else {
        ratio = ratioHeight
      }

      setDimensions({
        width: Constants.VIDEO_DEFAULT_WIDTH * ratio,
        height: Constants.VIDEO_DEFAULT_HEIGHT * ratio
      })

      dispatch(setPrevZoom());
    }
  }, [viewerState.zoom, viewerState.prevZoom, dispatch, dimensions.width]);

  return (
    <div
      ref={mediaContainerRef}
      className={'viewer-media position-relative ' + (viewerState.panelCollapsed ? ' panel-open': '') +
        ((fileType !== Constants.WEBSITE) ? ' d-flex justify-content-center': '') +
        ((fileType === Constants.AUDIO) ? ' mt-3': '')}
    >
      {!loaded && <div className={'spinner-container mt-5 pt-5 position-absolute start-50'}>
        <Spinner size={Constants.MEDIUM}/>
      </div>}

      {url &&
        <iframe
          title={'media iframe'}
          className={'media-iframe background-white ' + fileType  + (!loaded ? ' not-visible': '')}
          onLoad={()=>setLoaded(true)}
          src={url}
          width={dimensions.width}
          height={dimensions.height}
        />}
    </div>
  )
}

export default ViewerMedia;