import {
  idlAPIClient
} from "./axios";
import {AxiosResponse} from "axios";

const csrf = async ():Promise<any> => {
  const response:AxiosResponse<any, any> = await idlAPIClient.get<any>(
    '/auth/csrf',
    {withCredentials: true}
    );
  return response.data;
}

const getToken = async (): Promise<string | null>  => {
  let token: string | null = sessionStorage.getItem('csrf');
  if (!token) {
    const response: any = await csrf();
    token = (await response).token;
    if (token) {
      sessionStorage.setItem('csrf', token)
    }
  }
  return token
}

const register = async (data: any):Promise<any> => {
  sessionStorage.removeItem('csrf');
  const token: string | null = await getToken();
  if (token) {
    const response: AxiosResponse<any, any> = await idlAPIClient.post<any>(
      '/auth/register',
      data,
      {
        headers: {"x-csrf-token": token},
        withCredentials: true
      });
    return response.data;
  } else {
    throw new Error('Registration token request failed.')
  }
}

const login = async (data: any):Promise<any> => {
  /* token may be a remember me token */
  const token: string | null = await getToken();
  if (token) {
    idlAPIClient.defaults.withCredentials = true;
    const response:AxiosResponse<any, any> = await idlAPIClient.post<any>(
      '/auth/login',
      data,
      {
        withCredentials: true,
        headers: {
          "x-csrf-token" : token,
          "Content-Type": "application/x-www-form-urlencoded"
        }});

    /* refresh token w/ login response's */
    sessionStorage.setItem('csrf', response.data.token)

    return response.data;
  } else {
    throw new Error('Login token request failed.')
  }
}

const logout = async ():Promise<any> => {
  const token: string | null = await getToken();
  if (token) {
    const response:AxiosResponse<any, any> = await idlAPIClient.post<any>(
      '/auth/logout',
      {},
      {
        withCredentials: true,
        headers: { "x-csrf-token" : token}
      });

    sessionStorage.removeItem('csrf');
    return response.data;
  } else {
    throw new Error('Logout token request failed.')
  }
}

const resetPassword = async (data: any):Promise<any> => {
  data.tz = new Date().toTimeString().split(" ")[1];
  const response: AxiosResponse<any, any> = await idlAPIClient.get<any>(
    '/auth/forgot',
    {
      withCredentials: true,
      params: data
    }
  );
  return response.data;
}

const updatePassword = async (data: any):Promise<any> => {
  const token: string | null = await getToken();
  if (token) {
    const response:AxiosResponse<any, any> = await idlAPIClient.post<any>(
      '/auth/update',
      data,
      {
        withCredentials: true,
        headers: { "x-csrf-token" : token}
      }
  );
    return response.data;
  } else {
    throw new Error('UpdatePassword token request failed.')
  }
}


const LoginRegistrationService = {
  updatePassword,
  resetPassword,
  csrf,
  getToken,
  register,
  login,
  logout
}

export default LoginRegistrationService;
