export const SETTINGS = `
  Settings {
    postsPage {
      id
    }
  }
`

export const SETTINGS_QUERY = `
query Settings {
  ${SETTINGS}
}
`
export const PAGINATION_FIELDS =  `
  totalDocs,
  limit,
  totalPages,
  page,
  pagingCounter,
  hasPrevPage,
  hasNextPage,
  prevPage,
  nextPage
`
