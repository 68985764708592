export interface IDropdownItem {
  label: string;
  key: string;
}

export interface IViewerState {
  recordTool: string;
  possibleDuplicates: any[];
  fullscreen: boolean;
  totalPages: number;
  currentPage: number;
  zoom: number;
  prevZoom: number;
  zoom_select: IDropdownItem;
  rotation: number;
  thumbnailsCollapsed: boolean;
  contentView: string | null;
  panelCollapsed: boolean;
  searchPanelOpen: boolean;
  searchTerm: string;
  numSearchResults: number;
  currentSearchResult: number;
  ocrAvailable: boolean;
  highlightAllResults: boolean;
  documentLoaded: boolean;
  documentRendered: boolean;
  viewed: string[];
}
export const UpdateViewerAction: string = "update-viewer-action";
