import './styles.scss';
import React, {Dispatch, useContext} from "react";

/* Types, Constants, Utils */
import {TSort} from "../../../../types/search";
import * as Constants from "../../../../constants";

/* Bootstrap */
import {Dropdown} from "react-bootstrap";

/* Redux*/
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {ISearchState} from "../../../../types/redux/search";
import {RootState} from "../../../../redux";
import {setSort} from "../../../../redux/slices/search-slice";

/* Data */
import {IPageContext, PageContext} from "../../../../types/app";

/* Hooks */
import {IUseSorts} from '../../../../hooks/useSorts';
import {useSorts} from '../../../../hooks';
import {ISettings, IUserState} from "../../../../types/redux/user";
import {updateSettings} from "../../../../redux/slices/user-slice";


interface IProps {
  context: string
}

const SortDropdown = (props: IProps):JSX.Element => {
  const {
    context
  } = props;

  const dispatch:Dispatch<AnyAction> = useDispatch();
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const settings: ISettings = useSelector<RootState, IUserState>(state => state.user).settings;

  const pageContext: string = useContext<IPageContext>(PageContext).context;
  const {getSort, getAvailableSorts}: IUseSorts = useSorts(pageContext);

  const handleSelect = (value: string | null):void => {
    if (value) {
      if (context === Constants.SETTINGS) {
        dispatch(updateSettings({...settings, ...{sort: value}}))
      } else {
        dispatch(setSort(value));
      }
    }
  }

  return (
    <Dropdown onSelect={handleSelect} className={"sort-dropdown"}>
      <Dropdown.Toggle id={'sorted-by-download'} className={'btn' + ((context !== Constants.SETTINGS) ? ' btn-sm btn-light' : '')}>
        {(context === Constants.SETTINGS) && <span>{getSort(settings.sort).label}</span>}
        {(context !== Constants.SETTINGS) && <span><b>Sort by:&nbsp;&nbsp;</b>{getSort(searchState.sort).label}</span>}
      </Dropdown.Toggle>

      <Dropdown.Menu role="menu" className={''}>
        {getAvailableSorts().map((sort:TSort, index:number)=>{
          return (
            <Dropdown.Item
              key={'srt'+index}
              role={'menuitem'}
              eventKey={sort.id}
              className={'ps-4'}
            >
              {(context !== Constants.SETTINGS) &&
                (searchState.sort === sort.id) &&
                <i className={'bi bi-check h5 position-absolute'}/>
              }
              <span>{sort.label}</span>
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}
export default SortDropdown;
