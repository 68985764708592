import './styles.scss';
import React from "react";

/* Types, Constants, Utils */

/* Bootstrap */
import {Button} from "react-bootstrap";

/* redux */
import PostLayout from "../../payload-web/PostLayout";
import Layout from "../../payload-web/Layout";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {useNavigate} from "react-router-dom";

interface IProps {
  post: any,
  index: number
}

const NewsPost = (props: IProps):JSX.Element => {
  const {
    post,
    index
  } = props;

  /* hooks */
  const navigate:NavigateFunction = useNavigate();

  return (
    <div className={'post-wrapper'} key={'ne' + index}>

      {/* post entry  */}
      <div key={'ne' + index}>
        {post && <div className={'post pb-1'} key={'li' + index}>
          <PostLayout post={post}/>
          <Layout layout={post.layout}/>
        </div>}

        {/* View all button */}
        <div>
          <div className={'fade-out'}/>

          <div className={'btn-container d-flex justify-content-end'}>
            <Button
              className={'back-btn border-0 btn-icon-only my-2 text-dark'}
              onClick={()=>navigate('/news/'+ post.id)}
            >
              <div className={'label me-1 d-inline position-relative'}>View Post</div>
              <img src={'/assets/images/arrow-circle-right.svg'} alt="" height="30"/>
            </Button>
          </div>
        </div>

      </div>

      {/* Horizontal Rule */}
      <hr/>
    </div>
  )
}
export default NewsPost;
