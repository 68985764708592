import './styles.scss';
import React, {Dispatch, useEffect, useRef, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../constants";
import { PageContext } from '../../types/app';
import Utils from "../../utils";
import SearchUtils from "../../utils/search-utils";
import { CardContext } from '../../types/document';

/* Bootstrap */
import {Button, Col, Container, Row} from "react-bootstrap";

/* Redux */
import {addCrumb, removeToCrumb} from "../../redux/slices/breadcrumb-slice";
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {IResultsState} from "../../types/redux/result";
import {ISearchEntry, ISearchState} from "../../types/redux/search";
import {setDBSet, setQueryOfIds} from "../../redux/slices/search-slice";
import {clearResults, clearSelectedResults, setResults} from "../../redux/slices/result-slice";
import {IUserState} from "../../types/redux/user";
import {ISavedState} from "../../types/redux/saved";
import {setLoginModalActive} from "../../redux/slices/login-modal-slice";
import {IMyLibraryState} from "../../types/redux/my-library";
import {setLibrarySection} from "../../redux/slices/my-library-slice";
import {updateSavedBibliographies, setSavedDocuments, updateSavedSearches} from "../../redux/slices/saved-slice";
import {IBreadCrumbState} from "../../types/redux/breadcrumb";
import {addToast} from "../../redux/slices/toast-slice";

/* Api */
import SearchService from "../../api/search";
import SavedService from "../../api/saved";

/* Components */
import ViewSubheader from "../../components/view/view-subheader";
import ViewScrollContainer from "../../components/view/view-scroll-container";
import MyLibraryMenu from "./components/my-library-menu";
import PageToolsTop from "../../components/toolbars/page-tools-top";
import ExpandAll from "../../components/toolbars/expand-all";
import Spinner from "../../components/util/spinner";
import DocumentCard from "../../components/cards/document-card";
import BibliographyCard from "../../components/cards/bibliography-card";
import SearchCard from "../../components/cards/search-card";
import LoginPromptModal from "../../components/modal/login-prompt-modal";
import PageToolsBottom from "../../components/toolbars/page-tools-bottom";

/* Third party */
import {useQuery} from "react-query";

/* Hooks */
import {IUseSorts} from "../../hooks/useSorts";
import {useSorts, useCardContext} from "../../hooks";
import FiltersSlideout from "../../components/filters/filters-slideout";

const MyLibrary = ():JSX.Element => {

  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const savedState: ISavedState = useSelector<RootState, ISavedState>(state => state.saved);
  const resultsState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);
  const myLibraryState: IMyLibraryState = useSelector<RootState, IMyLibraryState>(state => state.my_library);
  const breadcrumbState: IBreadCrumbState = useSelector<RootState, IBreadCrumbState>(state => state.breadcrumb);

  /* state */
  const [noResults, setNoResults] = useState<boolean>(false);
  const [loginPromptModal, setLoginPromptModal] = useState<boolean>(false);
  const [promptSearch, setPromptSearch] = useState<boolean>(false);
  const requestedRecordIds = useRef<string[]>([])

  /* hooks */
  const cardContext: string = useCardContext().context;
  const {getSort}:IUseSorts = useSorts(Constants.MY_LIBRARY);

  /* Breadcrumb and url */
  useEffect(()=>{
    dispatch(removeToCrumb(breadcrumbState.breadcrumbs[0]));
    dispatch(addCrumb({
      label: Utils.toTitleCase(Constants.MY_LIBRARY),
      href: '/' + Constants.MY_LIBRARY.toLowerCase(),
      level: 2,
      active: true
    }));

    return ()=> dispatch(setLibrarySection(null))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ])

  useEffect(()=>{
    dispatch(clearSelectedResults());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, myLibraryState.section])

  /* get saved record id's from redux for section selected */
  useEffect(()=> {
    let recordIds: string[] = [];
    let sortValue: string  = getSort(searchState.sort).value;

    switch (myLibraryState.section) {
      case Constants.SAVED_DOCUMENTS:
        const tagIds: string[] = [];
        searchState.applied_filters.forEach(filter=>{
          if (filter.fq_group === Constants.TAGS) {
            tagIds.push(filter.id)
          }
        });

        SavedService.getSavedDocuments(searchState.current_page - 1, searchState.results_per_page, sortValue, tagIds).then((response:any) => {
          setNoResults(false);
          dispatch(setSavedDocuments(response.savedDocument));

          /* set up solr query */
          dispatch(setDBSet(Constants.DOCUMENTS));
          recordIds = response.savedDocument.map((document:any)=>document.documentId);
          requestedRecordIds.current = recordIds;
          dispatch(setQueryOfIds(recordIds));
          setPromptSearch(true);
        }).catch(e => handleError(e));
        break;

      case Constants.SAVED_BIBLIOGRAPHY:
        SavedService.getSavedBibliographies(searchState.current_page - 1, searchState.results_per_page, sortValue).then((response:any) => {
          setNoResults(false);
          dispatch(updateSavedBibliographies(response.savedBiblio));

          /* set up solr query */
          dispatch(setDBSet(Constants.BIBLIOGRAPHY));
          recordIds = response.savedBiblio.map((bibliography: any)=>String(bibliography.biblioId));
          requestedRecordIds.current = recordIds;
          dispatch(setQueryOfIds(recordIds));
          setPromptSearch(true)
        }).catch(e => handleError(e));
        break;

      case Constants.SAVED_SEARCHES:
        SavedService.getSavedSearches(searchState.current_page - 1, searchState.results_per_page, sortValue).then((response:any) => {
          dispatch(updateSavedSearches(response.savedSearch));
          dispatch(setResults({data:{response:{docs:response.savedSearch, numFound:response.totalItems}}, db_set:Constants.SAVED_SEARCHES}));
          dispatch(setDBSet(Constants.SEARCHES));
          setNoResults(response.savedSearch.length === 0);
        }).catch(e => handleError(e));
        break;

      case Constants.SEARCH_HISTORY:
        const searchHistoryCln: ISearchEntry[] = [...savedState.history];
        dispatch(setResults({data:{response:{docs:searchHistoryCln, numFound:savedState.history.length}}, db_set:Constants.SEARCH_HISTORY}));
        dispatch(setDBSet(Constants.SEARCHES));
        setNoResults(searchHistoryCln.length === 0);
        break;

      default:
        dispatch(setLibrarySection(Constants.SAVED_DOCUMENTS));
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myLibraryState.section,  searchState.current_page, searchState.results_per_page, searchState.sort, userState.loggedIn, searchState.applied_filters])

  /*
  *  Fetching records from backend
  */
  const {data, isSuccess, isError, error, refetch} = useQuery<any, Error>(
    ['ml_' + JSON.stringify(searchState)],

    async ({signal}) => {
      switch (searchState.db_set) {
        case Constants.DOCUMENTS:
          return await SearchService.solrMultipleDocumentQuery(searchState, signal);

        case Constants.BIBLIOGRAPHY:
          return await SearchService.solrMultipleBibliographyQuery(searchState, signal);

        default:
          console.log('ERROR: My Library - lost card context ' + cardContext)
          return await SearchService.solrEmptyResult();
      }
    },
    {
      staleTime: Infinity,
      enabled: false
    }
  );

  /* Get records from Solr ( or useQuery cache ) - search history is session bases in redux */
  useEffect(()=> {
    // ignore initial rendering, wait for saved records to load before fetching from solr
    if (!promptSearch) return;

    if (myLibraryState.section !== Constants.SEARCH_HISTORY) {
      if (!(userState.loggedIn)) {
        dispatch(clearResults());
        setNoResults(true);
      } else if ((myLibraryState.section !== Constants.SAVED_SEARCHES)) {
        if ((searchState.query.length === 1) && (searchState.query[0].term === null)) {
          setNoResults(true)
        } else {
          refetch()
        }
      }
    }
    setPromptSearch(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptSearch])

  /* todo: jain - move to error handling hook - used in SAVE button*/
  const handleError = (e: any) => {
    setNoResults(true);

    if ((e.status === 403) || (e.response && ((e.response.status === 403) || (e.response.status === 401)))){
      /* todo: this should only if action initiated, not on initial rendering of view */
      /* not logged in */
      //setLoginPromptModal(true);
    } else {
      // todo: jain - handle generic errors globally
      dispatch(addToast({
        bg: 'bg-danger',
        msg: Constants.ERROR_NOTIFICATION,
        id: -1
      }))
    }
  }

  useEffect(()=> {
    if (isSuccess) {
      const count: number = data.response.docs.length;
      if (!SearchUtils.isDefaultQuery(searchState.query)) {
        /* add search to url for linking and reload ? */
        ///window.history.replaceState(null, '', "?" + currentSearchParams().toString());
      }

      if (count > 0) {
        // sort results based on sort order from backend
        const reOrderedData: any[] = [];
        requestedRecordIds.current.forEach(id=>{
          const doc: any = data.response.docs.find((document: any) => {
            return document.id === id
          });
          reOrderedData.push(doc)
        })
        data.response.docs = reOrderedData;

        /* set results */
        dispatch(setResults({data:data, db_set:searchState.db_set}));
      } else {
        dispatch(clearResults());
        setNoResults(true);
      }

    } else if (isError) {
      handleError(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data])

  const handleNoDocuments = (): JSX.Element => {
    let elem: JSX.Element;
    if (noResults) {
      if ((myLibraryState.section !== Constants.SEARCH_HISTORY) && !userState.loggedIn) {
        elem = <div className={'my-5 text-center'}>
          <h5 className={'p-2'} >You must be logged in for this feature.</h5>
          <Button variant="primary" onClick={()=>dispatch(setLoginModalActive(true))}>Log In or Register</Button>
        </div>
      } else {
        /* done loading with no results */
        elem = <div className={'mt-2 text-center'}>
          <p className={'p-2'} >No results found.</p>
        </div>
      }
    } else if (isError) {
      /* Error messaging  */
      elem = <div className={'mt-2 text-center'}/>
    } else {
      /* loading */
      elem = <div className={'mt-2 text-center'}>
        <Spinner size={Constants.MEDIUM}/>
      </div>
    }
    return elem;
  }

  const getCard = (record: any, index: number): JSX.Element => {
    switch (cardContext) {
      case Constants.DOCUMENT:
        return <DocumentCard
          key={`dc-${index}`}
          index={index}
          record={record}
        />

      case Constants.SEARCH:
        return <SearchCard
          key={'sh'+index}
          searchEntry={record}
        />

      case Constants.BIBLIOGRAPHY:
        return <BibliographyCard
          key={`dc-${index}`}
          index={index}
          record={record}
        />

      default:
        return <></>;
    }
  }

  return (
    <div className={'my-library view'}>
      <PageContext.Provider value={{context: Constants.MY_LIBRARY}}>
        <CardContext.Provider value={{context:cardContext}}>

          <ViewScrollContainer>
            <Container className={'px-4'}>

              <ViewSubheader
                heading={Utils.toTitleCase(Constants.MY_LIBRARY)}
              />

              {/* Menu */}
              <MyLibraryMenu/>

              <Container className={'content-container mx-0 bg-white py-4 mt-3 rounded-md'}>
                {/* Page tools*/}
                <PageToolsTop/>

               {/*  Num of Results*/}
                <Row className={'px-3 py-2'}>
                  <Col className={'p-0 px-2'}>
                    {(resultsState.results.length > 0) &&
                      <div className={'mt-2'}>
                        <b>{resultsState.searchResultMeta && Utils.formatNumber(resultsState.searchResultMeta.num_found)}</b>
                        <span className={'ms-2'}>results</span>
                      </div>
                    }
                  </Col>

                  {/* Expand all records*/}
                  {(!noResults) && (myLibraryState.section === Constants.SAVED_DOCUMENTS) &&
                    <Col className={'p-0 text-end mt-2 mx-2'}>
                      <ExpandAll/>
                    </Col>
                  }
                </Row>

                {(!noResults) && (myLibraryState.section === Constants.SAVED_DOCUMENTS) &&
                  <Row className={'mb-3'}>
                    <FiltersSlideout label={'Filter by: Tag'}/>
                  </Row>
                }

                {/* Results*/}
                <Row className={'mx-0'}>
                  {resultsState.results.map((record:any, index:number) => getCard(record, index))}
                  {noResults && handleNoDocuments()}
                </Row>

                {/* Page tools bottom */}
                {(resultsState.results.length > 0) &&
                  <PageToolsBottom/>}

              </Container>
            </Container>
          </ViewScrollContainer>

          {loginPromptModal && <LoginPromptModal
            title={'Save ' + Utils.toTitleCase(cardContext)}
            show={loginPromptModal}
            onHide={()=>setLoginPromptModal(false)}/>}

        </CardContext.Provider>
      </PageContext.Provider>
  </div>
  )
}

export default MyLibrary;
