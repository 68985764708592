import React, {Dispatch, useEffect, useRef} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";

/* Bootstrap */
import {Button} from "react-bootstrap";

/* Redux */
import {IViewerState} from "../../../../types/redux/viewer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import {AnyAction} from "@reduxjs/toolkit";
import {setCurrentPage, setDocumentRendered} from "../../../../redux/slices/viewer-slice";

/* External Modules */
import {Page, Thumbnail} from "react-pdf";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

/* Components */
import PagePlaceholder from "../components/page-placeholder";
import ThumbnailPlaceholder from "../components/thumbnail-placeholder";

const useLoadPdf = ():
{
  pages: JSX.Element[];
  thumbnails: JSX.Element[];
  loadPages(start: number, count: number): void,
  pagesLoaded: number[],
  loadThumbnails(start: number, count: number): void
} => {
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);
  const dispatch: Dispatch<AnyAction> = useDispatch();

  const thumbnails = useRef<JSX.Element[]>([]);
  const pages = useRef<JSX.Element[]>([]);
  const pagesLoaded = useRef<number[]>([]);
  const thumbnailsLoaded = useRef<number[]>([]);

  /* add placeholders */
  useEffect(() => {
    /* New Record */
    thumbnails.current = [];
    pages.current = [];
    pagesLoaded.current = [];
    thumbnailsLoaded.current = [];

    if (!viewerState.documentLoaded) return;

    for (let i = 0; i < viewerState.totalPages; i++) {
      pages.current.push(
        <PagePlaceholder
          key={'pg-pl' + i}
          pageNum={i + 1}
          totalPages={viewerState.totalPages}
        />
      )

      thumbnails.current.push(
        <ThumbnailPlaceholder
          key={'tn-pl' + i}
          pageNum={i + 1}
        />
      )
    }

  }, [viewerState.documentLoaded, viewerState.totalPages])

  const onPageLoadError = (error: Error): void => {
    console.log('error ' + JSON.stringify(error))
  }

  const generatePage = (pageNum: number, scale: number, rotation: number): JSX.Element => {
    return (
      <Page
        key={'pg' + pageNum}
        onLoadError={onPageLoadError}
        loading={''}
        scale={viewerState.zoom}
        devicePixelRatio={2}
        className={('page_' + pageNum) + ' d-flex border border-medium ' + ((viewerState.totalPages !== pageNum) ? ' mb-2' : '')}
        pageNumber={pageNum}
        rotate={rotation}
        renderTextLayer={true}
        renderAnnotationLayer={true}
        onRenderSuccess={()=>{if (pageNum === 1) dispatch(setDocumentRendered(true))}}
      />
    )
  }

  const generateThumbnail = (pageNum: number, scale: number, rotation: number): JSX.Element => {
    return (
      <div
        key={'tn' + pageNum}
        className={'tn_' + pageNum + ' thumbnail my-2 bg-light border'}
      >
        <Button
          className={'p-0 rounded-0 ' + ((pageNum === viewerState.currentPage) ? Constants.ACTIVE : '')}
          variant={'transparent'}
          onClick={() => dispatch(setCurrentPage(pageNum))}
        >
          <Thumbnail
            height={100}
            loading={''}
            scale={scale}
            pageNumber={pageNum}
          />
        </Button>
        <p className={'text-xs text-secondary mt-1'}>Page {pageNum}</p>
      </div>
    )
  };

  const load = (list: JSX.Element[], factory: any, start: number, count: number, loaded: number[], unload: boolean, rotation: number): void => {
    if (viewerState.totalPages === 0) return;

    if (unload) {
      for (let i = 0; i < loaded.length; i++) {
        list[loaded[i] - 1] = <PagePlaceholder
          key={'pg-pl' + (loaded[i] - 1)}
          pageNum={loaded[i]}
          totalPages={viewerState.totalPages}
        />
      }
      loaded.length = 0;
    }

    // eg: start = 4 --> 5,6,4,7,3,8,2,9,0
    let j: number = 0;
    let sign: number = 0;
    let next: number = 0;
    for (let i = 0; i < count; i++) {
      sign = (i % 2) * -2 + 1;
      j += (i % 2)
      next = start + sign * j;

      if (next <= 0) {
        continue
      } else if (next >= viewerState.totalPages) {
        next = viewerState.totalPages;
      }
      if (!list[next - 1]) {
        // fail case should never occur
        console.error('scrolling out of bounds; index: ' + next)
        continue
      }

      const objectType: any = list[next - 1].type;
      if (objectType === PagePlaceholder) {
        // pages
        loaded.push(next)
        list[next - 1] = factory(next, 1, rotation);
      } else if (objectType !== Page) {
        // thumbnails
        list[next - 1] = factory(next, 1, rotation);
      }
    }
  }

  const loadThumbnails = (start: number, count: number): void => {
    load(thumbnails.current, generateThumbnail, start, count, thumbnailsLoaded.current, false, 0)
  };

  const loadPages = (start: number, count: number): void => {
    load(pages.current, generatePage, start, count, pagesLoaded.current, true, viewerState.rotation);
  };

  return {
    pages: pages.current,
    thumbnails: thumbnails.current,
    loadPages: loadPages,
    pagesLoaded: pagesLoaded.current,
    loadThumbnails: loadThumbnails
  }
}

export default useLoadPdf;