import axios, {AxiosInstance} from "axios";
import * as Constants from "../constants";

const SOLR_URL: string = 'https://solr.idl.ucsf.edu/solr';
const SOLR_STAGE_URL: string = 'https://solrstage.idl.ucsf.edu/solr';

const DEFAULT_HEADERS: {[key:string]: string} = {
  "Content-type": "application/json",
}

export const createAxiosInstance = (params:{[key:string]: string | string[]}, method: string): AxiosInstance => {
  return axios.create({
    baseURL: SOLR_URL + method,
    headers: DEFAULT_HEADERS,
    params: params
  })
}

/* todo: jain - refactor to generalized approach above */
export const solrApiDocumentClient = axios.create({
  baseURL: SOLR_URL + "/ltdl3/",
  headers: DEFAULT_HEADERS,
});

export const solrApiBibliographyClient = axios.create({
  baseURL: SOLR_URL + "/biblio/",
  headers: DEFAULT_HEADERS
});

export const solrStageApiDocumentClient = axios.create({
  baseURL: SOLR_STAGE_URL + "/ltdl3/",
  headers: DEFAULT_HEADERS,
});

export const solrStageApiBibliographyClient = axios.create({
  baseURL: SOLR_STAGE_URL + "/biblio/",
  headers: DEFAULT_HEADERS
});

export const solrApiCollectionClient = axios.create({
  baseURL:  SOLR_URL + "/collection/",
  headers: DEFAULT_HEADERS
});

/* todo: jain development - prod */
export const idlAPIClientLegacy = axios.create({
  baseURL: "https://www.industrydocuments.ucsf.edu",
  headers: {
    "Content-type": "application/x-www-form-urlencoded"
  }
});

export const idlAPIClient = axios.create({
  baseURL: (window.location.hostname === Constants.LOCALHOST) ? "https://stage.libckm.org/api/v1" : (window.location.protocol + "//" + window.location.host +  "/api/v1/"),
  headers: {
    "Content-type": "application/json"
  }
});

export const solrBaseClient = axios.create({
  baseURL:  SOLR_URL,
  headers: DEFAULT_HEADERS
});
