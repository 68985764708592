
import './styles.scss';
import classes from './index.module.scss';

import React from 'react'
import { CMSLink } from '../Link'
import RichText from '../RichText'

export const ContentBlock: React.FC<
  {
    columns?: any,
    id?: string
  }
> = props => {
  const { columns } = props

  return (
    <div className={'content ' + classes.grid}>
      {columns &&
        columns.length > 0 &&
        columns.map((col:any, index:number) => {
          const { enableLink, richText, link, size } = col

          return (
            <div key={index} className={[classes.column, classes[`column--${size}`]].join(' ')}>
              <RichText content={richText} />
              {enableLink && <CMSLink className={classes.link} {...link} />}
            </div>
          )
        })}
    </div>
  )
}
