export const LOCALHOST: string      = 'localhost';
export const PUBLISHED: string      = 'published';
export const POSTS: string          = 'posts'
export const STAGE_POSTS: string    = 'stagePosts';
export const STAGE_POST: string     = 'stagePost';
export const STAGE_PAGES: string    = 'stagePages';
export const STAGE_PAGE: string     = 'stagePage';
export const TOKEN: string          = 'token';
export const TYPE: string           = 'type';
export const SORT: string           = 'sort';
export const START: string          = 'start';
