import './styles.scss';
import React from "react";

/* Bootstrap */
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";

/* Redux */

interface IProps {
  clearField(): void
}

const ClearSearchField = (props: IProps):JSX.Element => {
  const {
    clearField
  } = props;

  return (
    <div className={'clear-search-btn'}>
      <OverlayTrigger overlay={
        <Tooltip className={'mb-3'}>Clear search input</Tooltip>}
      >
        <span>
          <Button
            className={'border-0 bg-white rounded-0 text-black h-100'}
            onClick={()=>clearField()}
          >
            <i className="bi bi-x"/>
            <span className={'text-small'}>Clear</span>
          </Button>
        </span>
      </OverlayTrigger>
    </div>
  )
}

export default ClearSearchField;
