
/* Types, Constants, Utils */
import {
  IContentPage,
  IContentState,
  IDocumentCounts,
  INewsFilter,
  UpdateContentAction
} from "../../types/redux/content";
import * as Constants from "../../constants";

/* Redux */
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

/* Api  */
import {Category, Industry} from "../../api/payload/payload-types";

const initialState = ():IContentState => {
  const newsFilters: INewsFilter[] = [...Constants.INDUSTRY_ITEMS].map(i=>{
    return {
      industry: i.industry,
      label: i.label,
      selected: true
    }
  });

  return {
    category: null,
    categories: [],
    industries: [],
    content_pages: [],
    news_filters: newsFilters,
    release_date: null,
    document_counts: {
      bibliography: [],
      collection: [],
      industry: [],
      pages: [],
      video: []
    }
  }
}

export const contentSlice = createSlice({
  name: UpdateContentAction,
  initialState: initialState,
  reducers: {
    setContentCategories: (state: IContentState, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },

    setContentIndustries: (state: IContentState, action: PayloadAction<Industry[]>) => {
      state.industries = action.payload;
    },

    setContentPages: (state: IContentState, action: PayloadAction<IContentPage[]>) => {
      state.content_pages = action.payload;
    },

    setContentCategory: (state: IContentState, action: PayloadAction<Category | null>) => {
      state.category = action.payload;
    },

    setNewsFilters: (state: IContentState, action: PayloadAction<INewsFilter[]>) => {
     state.news_filters = action.payload;
    },

    setDocumentCounts: (state: IContentState, action: PayloadAction<IDocumentCounts>) => {
      state.document_counts = action.payload;
    },

    setReleaseDate: (state: IContentState, action: PayloadAction<string>) => {
      state.release_date = action.payload;
    }
  }
});

export const {
  setContentCategory,
  setContentCategories,
  setContentIndustries,
  setContentPages,
  setNewsFilters,
  setDocumentCounts,
  setReleaseDate
} = contentSlice.actions;

export default contentSlice.reducer;
