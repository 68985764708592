import { INDUSTRIES_FIELDS } from './industries'
import { LINK_FIELDS } from './link'
import { MEDIA } from './media'
import { META } from './meta'

export const CALL_TO_ACTION = `
...on Cta {
  blockType
  invertBackground
  richText
  links {
    link ${LINK_FIELDS()}
  }
}
`

export const CONTENT = `
...on Content {
  blockType
  invertBackground
  columns {
    size
    richText
    enableLink
    link ${LINK_FIELDS()}
  }
}
`

export const MEDIA_BLOCK = `
...on MediaBlock {
  blockType
  invertBackground
  position
  ${MEDIA}
}
`

export const EMAIL_FORM = `
...on EmailForm {
  blockType
}
`

export const HORIZONTAL_LINE = `
...on HorizontalLine {
  blockType
}
`

export const IFRAME_BLOCK = `
...on IFrameBlock {
  blockType
  iFrameBlockFields {
    alignment
    frameTitle
    sourceUrl
    height
    width
    footnote
  }
}
`


export const CARD_MENU_GRID: string  = `
...on CardMenuGrid {
  blockType
  cardMenuGridFields {
    alphabetize
    items {
      id
      title
      link ${LINK_FIELDS({ disableAppearance: true, disableLabel: true })}
    }
  }
}
`

export const CONTENT_GRID = `
...on ContentGrid {
  blockType
  contentGridFields {
    useLeadingHeader
    leadingHeader
    cellAlignment
    rows {
      columnHeaders
      cells {
        richText
      }
    }
  }
}
`

export const CARD_GRID = `
...on CardGrid {
  blockType
  cardGridFields {
    cards {
      ${MEDIA}
      title
      description
      enableLink
      greyBackground
      link ${LINK_FIELDS({ disableAppearance: true })}
    }
  }
}
`

export const COLLAPSE_BLOCK = `
...on CollapseBlock {
  blockType
  collapseBlockFields {
    collapseToggle
    collapseContent
  }
}
`
export const SEARCHABLE_TABLE: string  = `
...on SearchableTable {
  blockType
  searchableTableFields {
    title
    api
    width
  }
}
`
export const NEWS_FEED_BLOCK: string = `
...on NewsFeedBlock {
  blockType
  id
  newsFeedBlockFields {
    industry
    numOfPosts
  }
}
`

export const ARCHIVE_BLOCK = `
...on Archive {
  blockType
  introContent
  populateBy
  relationTo
  ${INDUSTRIES_FIELDS}
  limit
  selectedDocs {
    relationTo
    value {
      ...on Post {
        id
        title
        ${META}
      }
    }
  }
  populatedDocs {
    relationTo
    value {
      ...on Post {
        id
        title
        ${INDUSTRIES_FIELDS}
        ${META}
      }
    }
  }
  populatedDocsTotal
}
`
