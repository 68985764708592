import React from 'react';
import ReactDOM from 'react-dom/client';

// store
import store from "./redux";
import { Provider } from "react-redux";

import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';

import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';



// react-query provider
import { QueryClient, QueryClientProvider } from "react-query";
import StoreInitializeProvider from "./redux/store-initialize";
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <CacheBuster
    currentVersion={packageInfo.version}
    isEnabled={true}
    isVerboseMode={false}
    metaFileDirectory={'.'}
    onCacheClear={() => {}}>

  <Provider store={store}>
    <StoreInitializeProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </StoreInitializeProvider>
  </Provider>

  </CacheBuster>
);
