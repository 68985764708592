import * as Constants from "../constants";

const GET_PUBLISHED_FQ = () => {
  let fq:string = "&fq=published:true";  //default is only show published

  /* todo: jain - publishedOnly comes from "${grailsApplication.config.publishedOnly}"; */
  /* let publishedOnly:string = PUBLISHED_ONLY.toLowerCase();
  if (publishedOnly.charAt(0) == 'f') {// if user passes in f, then remove the fq
    fq = "";
  }*/

  return fq;
}

export const BrowseQuery = (collectionCode: string[], rows: number, dir: string, docIdInt: number ): string => {
  let query: string = "q=*:*";
  query += "&fq=(collectioncode:\"" + collectionCode.join("\" OR collectioncode:\"") + "\")";
  query += GET_PUBLISHED_FQ()
  query += "&fl=id,id_int,tid,au,ti,bn,dt,dd,availability,pg,attach,access,artifact";
  query += "&wt=json";
  query += "&indent=true";
  query += "&rows=" + rows;

  switch (dir) {
    case Constants.PREV:
      query += "&fq=id_int:[* TO " + docIdInt + "}";
      query += "&sort=id_int desc";
      break;

    case  Constants.NEXT:
      query += "&fq=id_int:{" + docIdInt + " TO *]";
      query += "&sort=id_int asc";
      break;

    default:
  }

  let highlightQuery: string = "&hl=true";
  highlightQuery += "&hl.simple.pre=%3Ch1%3E";
  highlightQuery += "&hl.simple.post=%3C%2Fh1%3E";
  highlightQuery += "&hl.requireFieldMatch=true";
  highlightQuery += "&hl.preserveMulti=true";

  //to allow solr to highlight other fields just add the field code here
  let highlightFields: string[] = ["ot", "bn"];
  for(let i = 0; i < highlightFields.length; i++){
    highlightQuery += "&hl.fl="+ highlightFields[i];
    highlightQuery += "&f."+ highlightFields[i] +".hl.fragsize=6000";
    highlightQuery += "&f."+ highlightFields[i] +".hl.alternateField="+ highlightFields[i];
    highlightQuery += "&f."+ highlightFields[i] +".hl.maxAlternateFieldLength=300";
  }
  query += highlightQuery;

  return query;
}