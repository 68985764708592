import './styles.scss';
import React from "react";

/* Bootstrap */
import {Modal} from "react-bootstrap";

interface IProps {
  title: string,
  message: string;
  show: boolean;
  onHide():void;
}

const DownloadModal = (props: IProps):JSX.Element => {
  const {
    title,
    message,
    show,
    onHide
  } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop={false}
      enforceFocus={false}
      centered
      id={'notify-modal'}
      className={'notify-modal shadow rounded border-3'}
    >
      <Modal.Header className={'px-3 py-2 bg-success bg-opacity-25'} closeButton>
          <Modal.Title className={'h5'}>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body className={'bg-success bg-opacity-25 pt-0'}>
        {message}
      </Modal.Body>

    </Modal>
  )
}
export default DownloadModal;
