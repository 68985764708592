import './styles.scss';
import React, {useEffect, useRef} from "react";

/* Redux */
import {IResultsState} from "../../../types/redux/result";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {IViewerState} from "../../../types/redux/viewer";

/* Components */
import Footer from "../../header-footer/footer";
import ScrollToTop from "../../util/scroll-to-top";

interface IProps {
  children: React.ReactNode;
  className?: string
}

const ViewScrollContainer = (props:IProps):JSX.Element => {
  const {
    children,
    className
  } = props;

  const ref = useRef<HTMLInputElement | null>(null);
  const resultsState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [resultsState.results]);

  return (
    <div ref={ref} className={'view-scroll-container bg-light-blue ' + (className ? className : '') + (viewerState.fullscreen ? ' fullscreen': '')}>
      <ScrollToTop elemRef={ref}/>
      {children}

      <footer className={"mt-5"}>
        <Footer/>
      </footer>
    </div>
  )
}
export default ViewScrollContainer;
