import React from 'react'
import {Props as MediaProps} from '../types'
import classes from './index.module.scss'

const {
  REACT_APP_CMS_HOST,
  REACT_APP_LOCALHOST,
  REACT_APP_LOCAL_CMS_HOST
} = process.env;

export const Image: React.FC<MediaProps> = props => {
  const {
    imgClassName,
    onClick,
    onLoad: onLoadFromProps,
    resource,
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    priority,
    fill,
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    src: srcFromProps,
    alt: altFromProps,
  } = props

  const [isLoading, setIsLoading] = React.useState(true)

  let width: string | undefined | number
  let height: string | undefined | number
  let alt = altFromProps
  let src:  string =  ''

  if (!src && resource && typeof resource !== 'string') {
    const {
      width: fullWidth,
      height: fullHeight,
      filename: fullFilename,
      alt: altFromResource,
    } = resource

    width = (fullWidth === null) ? undefined : fullWidth;
    height = (fullHeight === null) ? undefined : fullHeight;
    alt = altFromResource
    const apiURL: string | undefined = (window.location.host === REACT_APP_LOCALHOST) ? REACT_APP_LOCAL_CMS_HOST : REACT_APP_CMS_HOST;
    src = `${apiURL}/media/${fullFilename}`
  }

  return (
    <img
      className={[isLoading && classes.placeholder, classes.image, imgClassName]
        .filter(Boolean)
        .join(' ')}
      src={src}
      alt={alt || ''}
      onClick={onClick}
      onLoad={() => {
        setIsLoading(false)
        if (typeof onLoadFromProps === 'function') {
          onLoadFromProps()
        }
      }}
      width={!fill ? width : undefined}
      height={!fill ? height : undefined}
    />
  )
}
