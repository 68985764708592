import './styles.scss';
import React from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";

/* Components */
import ViewerPDF from "../viewer-pdf";
import ViewerMedia from "../viewer-media";
import ViewerToast from "../viewer-toast";

import DocumentUtils from "../../../utils/document-utils";
import {IFileAttributes} from "../../../types/document";
import Utils from "../../../utils";

interface IProps {
  fileAttributes: IFileAttributes;
  viewerRecord: any;
}

const ViewerDocument = (props: IProps):JSX.Element => {
  const {
    fileAttributes,
    viewerRecord
  } = props;

  const link = (): string => {
    let url: string = '';
    if (viewerRecord.artifact) {
      const artifact: any  = JSON.parse(viewerRecord.artifact);
      url = Utils.getBucketPath(viewerRecord.id) + '/' + artifact[0].name;
    }
    return url;
  }

  const viewerCmp = (): JSX.Element => {
    if (!fileAttributes) return <></>

    switch (fileAttributes.type) {
      case Constants.PDF:
        return <ViewerPDF/>;

      case Constants.WEBSITE:
      case Constants.AUDIO:
      case Constants.VIDEO:
        return <ViewerMedia/>;

      case Constants.ZIP:
        // eg: khcx0257 w/o text
        // eg: jrxf0257 w/ text
        return (<ViewerToast
          variant={'light'}
          icon={'bi-file-earmark-arrow-down'}
          content={<span><a href={link()}>Download</a>  the source file(s) to view this document.</span>}
        />)

      case Constants.PRIVILEGED:
      case Constants.COPYRIGHT:
      case Constants.CONFIDENTIAL:
        if (DocumentUtils.getPublicVersions(viewerRecord).length > 0) {
          //eg. tmlg0155 - previous confidential and public version available
          return (<ViewerToast
            variant={'success'}
            icon={'bi-info-circle'}
            content={<span><p>This document was previously designated as confidential.
              Document Tools for both public and restricted versions are available</p>
              <p>Learn more about <a href={'https://www.industrydocuments.ucsf.edu'}>privileged and confidential documents</a>.</p> {/*todo: jain - need link*/}
          </span>}
          />)
        } else {
          // eg. prwj0233 - confidential
          return (<ViewerToast
            variant={'light'}
            content={<span>Only metadata available.</span>}
          />)
        }
    }

    return <></>
  }

  return (<>
    {viewerCmp()}
  </>)
}
export default ViewerDocument;
