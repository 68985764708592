import './styles.scss';
import React, {useState} from "react";

/* Bootstrap */
import {Form, Button, Modal} from 'react-bootstrap';

/* API */
import LoginRegistrationService from '../../../api/login-registration';

interface IProps {
  setResetPasswordActive(value: boolean):void
}

const ResetPasswordCmp = (props: IProps): JSX.Element => {
  const {
    setResetPasswordActive
  } = props;

  const [email, setEmail] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [resetPasswordModalActive, setResetPasswordModalActive] = useState<boolean>(false);

  const handleSubmit = (event:any):void=> {
    event.preventDefault();
    event.stopPropagation();

    setError(null);
    setValidated(true);
    const form = event.currentTarget;
    if (form.checkValidity()) {
      localStorage.setItem('email', email);

      LoginRegistrationService.resetPassword({email: email}).then((response: any) => {
        setResetPasswordModalActive(true);
      }).catch(e => {
        setError(e);
      })
    }
  }

  return (
    <div className={'reset-password pb-4'}>
      <h2 className={'font-bold'}>Reset Password</h2>

      <Form
        noValidate
        validated={validated}
        className={'login-form mt-4'}
        onSubmit={handleSubmit}
      >
        {/* email */}
        <Form.Group className={'pb-0'} controlId="loginForm.ControlEmail">
          <Form.Label className={'mb-1 font-light text-secondary'}>Email</Form.Label>
          <Form.Control
            type={"email"}
            value={email}
            onChange={e=>setEmail(e.target.value)}
          />

          <Form.Control.Feedback type={'invalid'}>
            Please enter a your email.
          </Form.Control.Feedback>
        </Form.Group>

        <p className={'pt-3 pb-4 font-light text-secondary font-regular'}>Enter your email and click the 'RESET PASSWORD' button.
          You will be sent an email with a reset password link.</p>

        {/* Buttons */}
        <div className={'btn-container'}>
          <Button
            className={'cancel-btn rounded-pill py-3'}
            variant={'secondary'}
            onClick={()=>setResetPasswordActive(false)}
          >
            Cancel
          </Button>

          <Button
            className={'reset-btn button-rounded rounded-pill py-3'}
            disabled={(email.length === 0)}
            type={'submit'}
          >
            RESET PASSWORD
          </Button>
        </div>
      </Form>

      {/* Reset Password modal*/}
      <Modal
        show={resetPasswordModalActive}
        onHide={()=>setResetPasswordActive(false)}
        backdrop="static"
        keyboard={false}
        centered
        className={"sent-reset-password-modal"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{'Reset Password'}</Modal.Title>
        </Modal.Header>

        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body className={'px-5 py-4'}>
           <h5>An Email was sent to the email address. Click on the link in the email and follow the instructions.</h5>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant={'primary'}
              type={"submit"}
              onClick={()=>setResetPasswordActive(false)}
            >
              Okay
            </Button>
          </Modal.Footer>
        </Form>

        {error &&
          <p className={'text-danger'}>{error.message}</p>}

      </Modal>
    </div>
  )
}
export default ResetPasswordCmp;
