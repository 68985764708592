import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import Utils from '../../../../utils';
import {ISearchState} from "../../../../types/redux/search";

/* Bootstrap*/
import {Dropdown, OverlayTrigger, Tooltip} from "react-bootstrap";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {ISearchEntry} from "../../../../types/redux/search";
import {RootState} from "../../../../redux";
import {setSearch} from "../../../../redux/slices/search-slice";
import {Link} from "react-router-dom";
import {ISavedState} from "../../../../types/redux/saved";
import SearchUtils from "../../../../utils/search-utils";
import {TQueryItem} from "../../../../constants";

const SearchHistoryDropdown = ():JSX.Element => {
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const savedState: ISavedState = useSelector<RootState, ISavedState>(state => state.saved);
  const search: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const [searchEntryByIndustries, setSearchEntryByIndustries] = useState<any>({})

  const handleRecentItemClick = (url: string):void => {
    dispatch(setSearch(SearchUtils.fromDataSource(decodeURIComponent(url.split('?')[1]))));
  }

  const getLabel = (url: string): JSX.Element => {
    const searchParam:URLSearchParams  = new URLSearchParams(url);
    const searchParamValue: string | null = searchParam.get(Constants.QUERY_KEY);
    if (searchParamValue) {
      const queryItems: TQueryItem[] = SearchUtils.convertQueryParams(searchParamValue);
      const query: string = SearchUtils.convertSearchQuery(queryItems);
      return <div
        className={"small"}>
        <b>{query}</b>
      </div>
    }

    return <></>
  }

  useEffect(()=>{
    let entry: ISearchEntry;
    let sorted: any = {};

    for (let i=0; i<savedState.history.length; i++) {
      entry = savedState.history[i];
      const searchParam:URLSearchParams  = new URLSearchParams(entry.url.split('?')[1]);
      let industryParamValue: string | null = searchParam.get(Constants.INDUSTRY_KEY);
      if (!industryParamValue) {
        /* fail case should never happen */
        industryParamValue = Constants.ALL_INDUSTRIES
      }

      if (sorted[industryParamValue]) {
        sorted[industryParamValue].push({...entry})
      } else {
        sorted[industryParamValue] = [entry]
      }
    }

    setSearchEntryByIndustries(sorted);
  }, [savedState.history])

  return (
    <div className={'search-history'}>
      <Dropdown>
        <OverlayTrigger overlay={
          <Tooltip className={'mb-3'}>{(search.db_set === Constants.DOCUMENTS) ? 'Search history' : Constants.TOOLTIP_RECENT_SEARCH}</Tooltip>
        }>
          <span> {/* enable bs tooltip to display when button is disabled */}
            <Dropdown.Toggle
              disabled={(search.db_set !== Constants.DOCUMENTS)}
              id={'dropdown-basic'}
              className={"btn-transparent border-0 rounded-0 h-100 px-0"}
            >
              <i className={"bi-clock fst-normal text-primary ms-2"}/>
            </Dropdown.Toggle>
          </span>
        </OverlayTrigger>

        <Dropdown.Menu aria-expanded="false" className={'border-0 rounded-0 rounded-bottom shadow'}>
          <div className={'border-bottom d-flex justify-content-lg-between py-2 px-3'}>
            <span>
              <i className={"bi-clock fst-normal text-black"}/>
              <h6 className={'ms-2 d-inline-block my-0'}>Search History</h6>
            </span>
            <Link
              className={'link-underline-none'}
              to={'/' + Constants.MY_LIBRARY + '?' + Constants.MY_LIBRARY_SECTION_KEY + '=' + Constants.SEARCH_HISTORY}
            >
              Manage Search History
            </Link>
          </div>

          <div className={'d-flex justify-content-lg-between px-3 pt-3'}>
            <div><b>Query</b></div>
            <div><b>Results</b></div>
          </div>

          {(savedState.history.length === 0) &&
            <Dropdown.Item
              key={'rs-nf'}
              href="#"
              className={"m-0 me-5"}
            >
              {Constants.NO_RECENT}
            </Dropdown.Item>
          }

          {(savedState.history.length  > 0) &&
            Object.keys(searchEntryByIndustries).map((industry: string, iIndex: number) => {
              return (
                <div className={'industry-segment'} key={iIndex}>
                  <h6 className={'ms-3 mt-1 mb-0 pt-1 text-xnormal'}><b>{Utils.toTitleCase(industry)}</b></h6>
                  {searchEntryByIndustries[industry].map((searchEntry: ISearchEntry, qIndex: number) =>{
                    return (
                      <Dropdown.Item
                        key={iIndex+'-'+qIndex}
                        className={'text-primary'}
                        onClick={()=>handleRecentItemClick(searchEntry.url)}
                      >
                        <div className={'ps-2 d-flex justify-content-lg-between'}>
                          <span>{getLabel(searchEntry.url)}</span>
                          <span>{Utils.formatNumber(searchEntry.count)}</span>
                        </div>
                      </Dropdown.Item>
                    )
                  })}
                  <hr className={"dropdown-divider"}/>
                </div>
              )})
          }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
export default SearchHistoryDropdown;
