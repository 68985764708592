import ApiUtils from "./api-utils";
import {AxiosResponse} from "axios";
import {idlAPIClient} from "./axios";

/* URLs */
const NOTIFICATION_URL: string = '/notification-requests';

/*
[
    {
      "targetCode": "juul",
      "targetType": "COLLECTION"
    },
    {
      "targetCode": "DRUG",
      "targetType": "INDUSTRY"
    }]
*/

const createNotification = async (data: any):Promise<any> => {
  return ApiUtils.makePost([data], NOTIFICATION_URL)
}

/*[
  {
    "id": 2,
    "targetCode": "pm",
    "targetType": "COLLECTION"
  },
  {
    "id": 4,
    "targetCode": "OPIOIDS",
    "targetType": "INDUSTRY"
  }
]*/

const updateNotification = async (data: any):Promise<any> => {
  return ApiUtils.makePatch(data, NOTIFICATION_URL)
}

const deleteNotifications = async (ids: string):Promise<any> => {
  return ApiUtils.doDelete(ids, NOTIFICATION_URL)
}

const getNotification = async (id: string):Promise<any> => {
  const token: string = ApiUtils.getToken();
  if (token) {
    const response: AxiosResponse<any, any> = await idlAPIClient.get<any>(NOTIFICATION_URL + '/' + id);
    return response.data;
  }
}

const getAllNotification = async ():Promise<any> => {
  const token: string = ApiUtils.getToken();
  if (token) {
    const response: AxiosResponse<any, any> = await idlAPIClient.get<any>(NOTIFICATION_URL);
    return response.data;
  }
}

const NotificationService = {
  createNotification,
  updateNotification,
  deleteNotifications,
  getNotification,
  getAllNotification
}

export default NotificationService;
