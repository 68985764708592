import './styles.scss';
import React from "react";

/* Types, Constants, Utils */


/* Bootstrap */
import {Button, Modal} from "react-bootstrap";

/* Components */
import ViewerCite from "../../viewer/viewer-cite";

interface IProps {
  show: boolean;
  onHide(): void;
}

const CiteModal = (props: IProps):JSX.Element => {
  const {
    show,
    onHide
  } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop={'static'}
      keyboard={false}
      centered
      className={'cite-modal'}
    >
      <Modal.Header closeButton className={'border-0 pb-0'}>
        <Modal.Title className={'fs-5'}>Cite</Modal.Title>
      </Modal.Header>

      <Modal.Body className={'pt-0 pb-1'}>
        <ViewerCite />
      </Modal.Body>

      <Modal.Footer className={'border-0 pb-4'}>
        <Button className={'px-5  '} variant={'primary'} onClick={onHide}>Done</Button>
      </Modal.Footer>
    </Modal>
  )
}
export default CiteModal;
