import './styles.scss';
import React, {useContext, useEffect, useState} from "react";

/* Types, Constants, Utils */
import {IViewerRecordContext, ViewerRecordContext} from "../../../types/document";
import SEARCH_META_DATA from "../../../assets/data/search.meta.json";
import {Col, Row} from "react-bootstrap";

const ViewerMetadata = ():JSX.Element => {
  const record: any = useContext<IViewerRecordContext>(ViewerRecordContext).viewerRecord;
  const [metaData, setMetaData] = useState<any[]>([]);

  const entry = (label: string, value: string | undefined, i: number): JSX.Element =>{
    if (value) {
      return (
        <React.Fragment key={i}>
          <div className={'entry max-lines-4 d-none d-lg-block'}>
            <span className={'pe-1 word-wrap'}><strong>{label}:&nbsp;</strong>{value}</span>
          </div>

          <Row className={'entry max-lines-4 d-lg-none'}>
            <Col className={'pe-1'}><strong>{label}: </strong></Col>
            <Col className={'px-1'}>{value}</Col>
          </Row>
        </React.Fragment>
      )
    }
    return <></>
  }

  const getFields = (): any[]=>{
    const resultFields: any[] = [...SEARCH_META_DATA.resultFields];
    const allFields: any= {...SEARCH_META_DATA.allFields};
    const fieldPriority: string[] = ['au','dd', 'dt', 'genre', 'industry', 'id', 'ark', 'collection', 'availability', 'case', 'bn', 'w', 'ddmu']
    const fields: {
      field:string,
      label: string,
      value: string
    }[] = [];

    fieldPriority.forEach(f=>{
      if (record[f]) {
        fields.push(
          {
            field: f,
            label: allFields[f][0],
            value: record[f]
          }
        )
      }
      
      if (f === 'ark') {
        fields.push(
          {
            field: 'ark',
            label: 'ARK',
            value: "ark:/88122/" + record.id
          }
        )
      }
    })

    resultFields.forEach(f=> {
      const key: string = Object.keys(f)[0];
      if (record[key] && (fieldPriority.indexOf(key) === -1)) {
        fields.push(
          {
            field: key,
            label: allFields[key][0],
            value: Array.isArray(record[key]) ? record[key].join(' ,') : record[key]
          }
        )
      }
    })

    return fields
  }

  useEffect(()=>{
    if (!record) return;
    setMetaData(getFields());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  return (metaData.length > 0) ? (
    <div className={'viewer-metadata px-3 py-3 py-lg-1'}>
      {metaData.map((f:any, i:number) => {
        return entry(f.label, f.value, i)
      })}
    </div>
  ) : <></>
}
export default ViewerMetadata;
