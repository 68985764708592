import './styles.scss';
import React, {useContext, useEffect, useState} from "react";

/* Types, Constants, Utils */
import {FilterOptionContext, IFilterOptionContext, TFilterOption} from "../../../types/search";

/* Bootstrap */
import {Form} from "react-bootstrap";
import {FormCheckType} from "react-bootstrap/FormCheck";
import Utils from "../../../utils";

interface IProps {
  onHandleChange?: (selectedOption: TFilterOption)=>void | null;
  type: FormCheckType;
  filterOption: TFilterOption;
}

const FiltersCheck = (props: IProps):JSX.Element => {
  const {
    type,
    filterOption,
    onHandleChange
  } = props;

  const [selected, setSelected] = useState<boolean>(false)
  const addRemoveSelectedOptions: Function = useContext<IFilterOptionContext>(FilterOptionContext).addRemoveSelectedOptions;

  useEffect(()=>{
    setSelected(filterOption.invert ? !filterOption.selected : filterOption.selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOption, onHandleChange]); /* onHandleChange forces update on external selection set */

  const handleToggle = (e:any): void =>{
    e.stopPropagation(); // don't close the menu when option selected
    const toggle: boolean = !selected;
    if (onHandleChange) {
      const filterOptionCln: TFilterOption = {...filterOption};
      filterOptionCln.selected = toggle;
      onHandleChange(filterOptionCln);
    } else {
      setSelected(toggle);
      filterOption.selected = toggle; /* todo: jain - this is a mutation - need to rebuild filters-filter w/ new state set in addRemoveSelectedOptions (tbd) */

      let active: boolean = toggle;
      if (filterOption.invert) {
        active = !active;
      }
      addRemoveSelectedOptions(active ? {add:[filterOption], remove:[]} : {add:[], remove:[filterOption]})
    }
  }

  return (
    <Form.Group controlId={filterOption.id + '_controller' } key={filterOption.id + '_fg'}>
      <Form.Check
        key={filterOption.id + '_check'}
        className={'text-larger mt-1 mb-3'}
        type={type}
        label={<span role={'button'} className={'text-smaller'}>
                  <b>{filterOption.label}</b>
                  {filterOption.count &&
                    <span>&nbsp;({Utils.formatNumber(filterOption.count)})</span>}
                </span>}
        checked={selected}
        onChange={()=>{}}
        onClick={e=>handleToggle(e)}
      />
    </Form.Group>
  )
}
export default FiltersCheck;
