import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";

/* Bootstrap */
import {Button, ButtonGroup, Form} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import store, {RootState} from "../../../../redux";
import {IViewerState} from "../../../../types/redux/viewer";
import {AnyAction, Unsubscribe} from "@reduxjs/toolkit";
import {decrementCurrentPage, incrementCurrentPage, setCurrentPage} from "../../../../redux/slices/viewer-slice";
import {addToast} from "../../../../redux/slices/toast-slice";

const PageNavigation = ():JSX.Element => {
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);
  const [pageNumInput, setPageNumInput] = useState<string>(String(viewerState.currentPage))

  useEffect(()=>{
    /* Syncing of page input when external change */
    const handleChange = ()=>{
      const viewerState: IViewerState = store.getState().viewer;
      setPageNumInput(String(viewerState.currentPage))
    }
    const unsubscribe: Unsubscribe = store.subscribe(handleChange);
    return ()=> unsubscribe();
  }, [viewerState.currentPage]);

  const handleKeydown = (event: React.KeyboardEvent): void => {
    if (isNaN(parseInt(event.key)) && (event.key !== Constants.BACKSPACE_KEY)) {
      event.preventDefault();
      event.stopPropagation();

      if (event.key === Constants.ENTER_KEY) {
        const parsedValue: number | null = parseInt(pageNumInput)
        if (!isNaN(parsedValue)) {
          if ((parsedValue > 0) && (parsedValue <= viewerState.totalPages)) {
            /* valid page number */
            dispatch(setCurrentPage(parsedValue))
          } else {
            dispatch(addToast({
              bg: 'bg-danger',
              msg: Constants.INVALID_PAGE_NUMBER,
              id: -1
            }))
          }
        }
      }
    }
  }

  return(
    <div className={'viewer-page-navigation'}>
      <ButtonGroup className={'me-2'} aria-label="page up and down">
        <Button
          className={'bg-transparent text-black border-0'}
          disabled={viewerState.currentPage === 1}
          onClick={()=>dispatch(decrementCurrentPage())}
        >
          <i className={'bi bi-chevron-up'}/>
        </Button>

        <div className={'vr'}/>

        <Button
          className={'bg-transparent text-black border-0'}
          disabled={viewerState.currentPage === viewerState.totalPages}
          onClick={()=>dispatch(incrementCurrentPage())}
        >
          <i className={'bi bi-chevron-down'}/>
        </Button>
      </ButtonGroup>

      <Form className={'d-inline '}>
        <Form.Control
          className={'text-end p-1 px-2 d-inline'}
          type={'number'}
          id={'input-pagenum'}
          value={(viewerState.totalPages > 0) ? pageNumInput : 0}
          onKeyDown={e=>handleKeydown(e)}
          onChange={e=>setPageNumInput(e.target.value)}
          onBlur={()=>setPageNumInput(String(viewerState.currentPage))}
          aria-describedby={'pageNumberInput'}
        />
        <span className={'ms-2'}>of {viewerState.totalPages}</span>
      </Form>
    </div>
  )
}
export default PageNavigation;
