import SORTS from "../assets/data/sorts.json";
import {TSort, TSorts} from "../types/search";
import {useEffect, useState} from "react";
import * as Constants from "./../constants";

export interface IUseSorts {
  getSort (id: string | null): TSort;
  getAvailableSorts (): TSort[];
}

const useSorts = (pageContext: string): IUseSorts => {
  if (pageContext === Constants.SETTINGS) {
    pageContext = Constants.INDUSTRY
  }

  const sortsDB: TSorts = SORTS as TSorts;
  /* @ts-ignore */
  const [sorts, setSorts] = useState<TSort[]>(sortsDB[pageContext]);

  useEffect(()=>{
    /* @ts-ignore */
    setSorts(sortsDB[pageContext]);
  }, [pageContext, sortsDB])

  const getAvailableSorts = (): TSort[] =>{
    return sorts
  }

  const getSort = (id: string | null): TSort => {
    let returnValue: TSort | undefined = sorts.find(sort=>sort.id === id);
    if (!returnValue) {
      returnValue = sorts[0];
    }
    return returnValue
  }

  return {
    getSort,
    getAvailableSorts
  }
};

export default useSorts;