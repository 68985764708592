import './styles.scss';
import React, {useContext} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";

/* Bootstrap */
import {Button, ButtonGroup, Modal} from "react-bootstrap";

/* Components */
import {ShareItems} from "../../util/share-items";
import {IRecordsContext, RecordsContext} from "../../../types/document";

interface IProps {
  show: boolean;
  onHide(): void;
}

const ShareModal = (props: IProps):JSX.Element => {
  const {
    show,
    onHide
  } = props;

  const records: any = useContext<IRecordsContext>(RecordsContext).records;

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop={'static'}
      keyboard={false}
      centered
      className={'share-modal'}
    >
      <Modal.Header closeButton className={'border-0 pb-0'}>
        <Modal.Title className={'fs-5'}>Share</Modal.Title>
      </Modal.Header>

      <Modal.Body className={'pt-0 pb-1'}>
        <div className={'viewer-share px-3'}>
          <ButtonGroup className={'mt-2 d-grid'}>
            <ShareItems
              type={Constants.BUTTON}
              records={records}
            />
          </ButtonGroup>
        </div>
      </Modal.Body>

      <Modal.Footer className={'border-0'}>
        <Button className={'px-5'} variant={'primary'} onClick={onHide}>Done</Button>
      </Modal.Footer>
    </Modal>
  )
}
export default ShareModal;
