import {Config} from "../api/payload/payload-types";

export const PAGES: string          = 'Pages';
export const INDUSTRIES: string     = 'industries';
export const GLOBAL: string         = 'Global';

export const COLLECTIONS_CONFIG_KEY = 'collections' as keyof Config;
export const INDUSTRIES_CONFIG_KEY = 'industries' as keyof Config[typeof COLLECTIONS_CONFIG_KEY];
export const CATEGORIES_CONFIG_KEY = 'categories' as keyof Config[typeof COLLECTIONS_CONFIG_KEY];

export const PROD_SITE_HOST: string = 'prod.libckm.org';
export const PLACEHOLDER: string = 'placeholder';
export const MENU: string = 'menu';

