/* Type, Constants, Utils */
import * as Constants from "../constants";

/* Redux */
import {ISearchState} from "../types/redux/search";
import {useSelector} from "react-redux";
import {RootState} from "../redux";

const useCardContext = (): {context: string} => {
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);

  const getContext = (): string => {
    switch (searchState.db_set) {
      case Constants.BIBLIOGRAPHY:
        return Constants.BIBLIOGRAPHY;

      case Constants.DOCUMENTS:
        return Constants.DOCUMENT;

      case Constants.SEARCHES:
        return Constants.SEARCH;

      default:
        return Constants.DOCUMENT;
    }
  }

  return {
    context: getContext()
  }
};

export default useCardContext;
