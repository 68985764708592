import './styles.scss';
import React, {Dispatch, useEffect, useMemo, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../constants";
import Utils from "../../utils";

/* Bootstrap */
import {Button, Container, Form} from "react-bootstrap";

/* Redux */
import {useDispatch} from "react-redux";
import {AnyAction} from "@reduxjs/toolkit";
import {addCrumb} from "../../redux/slices/breadcrumb-slice";
import {addToast} from "../../redux/slices/toast-slice";

/* Components */
import ViewSubheader from "../../components/view/view-subheader";
import ViewScrollContainer from "../../components/view/view-scroll-container";

/* Hooks */
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {useNavigate} from "react-router-dom";

/* Api */
import LoginRegistrationService from "../../api/login-registration";
import PasswordField from "../../components/fields-buttons/password-field";

const ResetPassword = ():JSX.Element => {
  const dispatch:Dispatch<AnyAction> = useDispatch();

  const searchParams: URLSearchParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const expireDate: Date = Utils.parseUTCDate(searchParams.get(Constants.RESET_PASSWORD_EXPIRE_KEY));
  const expiredLink: boolean = (expireDate.getTime() > new Date().getTime());

  const [password, setPassword] = useState<string>('');
  const [passwordVerify, setPasswordVerify] = useState<string>('');

  const [validated, setValidated] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const navigate: NavigateFunction = useNavigate();

  /* Breadcrumb */
  useEffect(()=>{
    dispatch(addCrumb({
      label: Utils.toTitleCase(Constants.RESET_PASSWORD),
      href: '/' + Constants.RESET_PASSWORD,
      level: 3,
      active: true
    }));
  }, [dispatch])


  const handleSubmit = (event:any):void=>{
    event.preventDefault();
    event.stopPropagation();

    if ((password.length < 5) || (password !== passwordVerify)) {
      setValidated(false);
      setHasErrors(true);
      return;
    } else {
      setValidated(true);
      setHasErrors(false);
    }

    const form = event.currentTarget;
    if (form.checkValidity()) {
      const data: any = {
        email: localStorage.getItem('email'),
        password: password,
        resetToken: searchParams.get(Constants.RESET_PASSWORD_TOKEN_KEY)
      }

      LoginRegistrationService.updatePassword(data).then((response:any) =>{
        navigate('/login');
        dispatch(addToast({
          bg: 'bg-success',
          msg: "You password has been reset.",
          id: -1
        }))
      }).catch(e =>{
        setError(e);
      })
    }
  }

  const handleKeydown = (e: any): void => {
    if (hasErrors) {
      setHasErrors(false);
      setError(null)
    }

    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  return (
    <div className={'reset-password-view view'}>
      <ViewScrollContainer >
        <ViewSubheader
          heading={Utils.toTitleCase(Constants.RESET_PASSWORD)}
        />

        <Container className={'px-5'}>
          <div className={'bg-white my-5 pt-4 pb-5 px-5 rounded-md'}>
            <div className={'password-form'}>
              <p className={'my-3'}>Please enter a new password and click <span className={'font-regular'}>'CHANGE PASSWORD'</span>.</p>

              <Form
                noValidate
                validated={validated}
                className={'new-password-form mt-4'}
                onSubmit={handleSubmit}
              >

                <Form.Group className={'mb-3'} controlId={'newPasswordForm.ControlPassword'}>
                  {/* password */}
                  <PasswordField
                    label={'Password'}
                    setPassword={setPassword}
                    password={password}
                    handleKeydown={handleKeydown}
                    isInvalid={()=>(hasErrors && (password.length < 5))}
                    errorMessage={'A password at least 5 characters long is required.'}
                  />

                  {/* verify password */}
                  <div className={'mt-3'}>
                    <PasswordField
                      label={'Verify Password'}
                      setPassword={setPasswordVerify}
                      password={passwordVerify}
                      handleKeydown={handleKeydown}
                      isInvalid={()=>(hasErrors && (password !== passwordVerify))}
                      errorMessage={'Passwords do not match.'}
                    />
                  </div>
                </Form.Group>

                {error &&
                  <p className={'text-danger'}>{error.message}</p>}

                {/* Submit button */}
                <Button
                  className={'button-rounded rounded-pill bg-light-blue w-100 py-3 mt-4'}
                  disabled={expiredLink}
                  type={'submit'}
                >
                  <h6 className={'m-0'}>CHANGE PASSWORD</h6>
                </Button>
              </Form>

            </div>
          </div>
        </Container>
      </ViewScrollContainer>
    </div>
  )
}

export default ResetPassword;
