import './styles.scss';
import React, {useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import {IResultsState} from "../../../types/redux/result";
import {RecordsContext} from '../../../types/document';

/* Bootstrap */
import {Col, Container, Row} from "react-bootstrap";

/* Redux */
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";

/* Components */
import SelectResults from "../components/select-results";
import SortDropdown from "../components/sort-dropdown";
import Paging from "../components/search-paging";
import CardTools from "../card-tools";

const PageToolsTop = ():JSX.Element => {
  const resultState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);

  useEffect(()=>{
    /* Converts results selected list of ids to list of records */
    const records: any[] = resultState.selected.map(selected => {
      return resultState.results.find((record: any) => {
        return record.id === selected.id
      });
    })
    setSelectedRecords(records);
  }, [resultState.selected, resultState.results])

  return (
    <RecordsContext.Provider value={{records:selectedRecords}}>
      <Container className={'page-tools-top bg-light p-2 rounded-1 border border-1'}>
        <Row className={'w-100 d-flex justify-content-end m-0'}>

          <Col className={'p-0 d-flex text-nowrap'}>
            {/* Select Results */}
            <SelectResults />

            {/* Card(s) Tools */}
            <CardTools context={Constants.TOOLBAR}/>
          </Col>

          <Col className={'d-flex justify-content-end mt-1'}>
            {/* Sort by */}
            <SortDropdown context={Constants.PAGE}/>

            {/* Pagination */}
            <Paging
                previous={'Previous'}
                showPageInput={true}
                next={'Next'}
            />
          </Col>
        </Row>
      </Container>
    </RecordsContext.Provider>
  )
}
export default PageToolsTop;
