import './styles.scss';
import React from "react";
import LinkButton from "../../../button-link";

interface IProps {
  setActiveRecordTool(value: string | null): void;
}

const BackMenu = (props: IProps):JSX.Element => {
  const {
    setActiveRecordTool
  } = props;

  return(
    <div className={'back-menu'}>
      <LinkButton
        label={'Back to Document Tools'}
        underline={false}
        linkArrowReverse={true}
        clickFunc={()=>setActiveRecordTool(null)}
      />
    </div>
  )
}

export default BackMenu;
