import './styles.scss';
import React, {useState, useEffect} from 'react'

import RichText from '../RichText'
import {Col, Container, Row} from "react-bootstrap";

export const ContentGrid: React.FC<
  {
    fields: any
  }
> = props => {
  const {
    fields
  } = props

  const [numColumns, setNumColumns] = useState<number>(0);

  useEffect(()=>{
    let columns: number = 0;
    fields.rows.forEach((r:any)=>{
      columns = r.cells.length > columns ? r.cells.length : columns
    })
    setNumColumns(columns)
  }, [setNumColumns, fields.rows])

  const textAlignment = (): string => {
    switch (fields.cellAlignment) {
      case 'left':
        return 'text-start';
      case 'right':
        return 'text-end';
      default:
        return 'text-center';
    }
  }

  return (
    <Container className={'content-grid ' + textAlignment()}>
      <div className={'my-3'}>
        {fields.useLeadingHeader &&  <RichText content={fields.leadingHeader}/>}
      </div>

      {fields.rows.map((row:any, index: number) => {
        return <Row key={index} className={(row.columnHeaders ? 'column-headers': '')}>
          {row.cells.map((cell:any, i: number) => {
            return <Col lg={10/numColumns} className={'col px-3 py-4 bg-grey-lighter m-2 border-0'} key={i}>
              {cell.richText.map((c:any, j:number) => {
                return <RichText content={[c]} key={j}/>
              })}
            </Col>
          })}
        </Row>
      })}
    </Container>
  )
}
