import { CONTENT, MEDIA_BLOCK } from './blocks'
import {PAGINATION_FIELDS} from "./globals";

const PAGINATION_LIMIT: number = 10;

export const POST_REQUEST_FIELDS = `
  docs {
    id
    stage
    title
    industries {
      title
    }
    createdAt
    publishedAt
    stage_publishedAt
    authors {
      id
    }
    _status
    layout {
      ${CONTENT}
      ${MEDIA_BLOCK}
    }
    relatedPosts {
      id
      title
    }
  }
`

export const POSTS_QUERY = `
  query Posts {
    Posts(
      limit: ${PAGINATION_LIMIT}
      page: $page,
      sort: "-publishedAt"
    ) {
      ${POST_REQUEST_FIELDS},
      ${PAGINATION_FIELDS}
    }
  }
`

export const POST_QUERY = `
  query Posts($id: String) {
    Posts(
        limit: 1, 
        sort: "-publishedAt"
        where: { id: { equals: $id }}, 
        draft:true
      ) {
        ${POST_REQUEST_FIELDS},
        ${PAGINATION_FIELDS}
      }
  }
`

export const POST_BY_INDUSTRY = `
  query Posts($industries: [JSON], $page: Int) ) {
    Posts(
      limit: ${PAGINATION_LIMIT},
      page: $page,
      where: {industries: {in: $industries}},
      sort: "-publishedAt"
    ) {
      ${POST_REQUEST_FIELDS},
      ${PAGINATION_FIELDS}
    }
  }
`

export const STAGE_POSTS_QUERY = `
  query StagePosts {
    StagePosts(
      limit: ${PAGINATION_LIMIT}
      page: $page,
      sort: "-publishedAt"
    ) {
      ${POST_REQUEST_FIELDS},
      ${PAGINATION_FIELDS}
    }
  }
`

export const STAGE_POST_QUERY = `
  query StagePosts($id: String) {
    StagePosts(
        limit: 1, 
        where: { id: { equals: $id }}, draft: true,
        sort: "-publishedAt"
      ) {
        ${POST_REQUEST_FIELDS},
        ${PAGINATION_FIELDS}
    }
  }
`

export const STAGE_POST_BY_INDUSTRY = `
  query StagePosts($industries: [JSON], $page: Int) {
    StagePosts(
        limit: ${PAGINATION_LIMIT},
        page: $page,
        where: {industries: {in: $industries}},
        sort: "-publishedAt"
      ) {
        ${POST_REQUEST_FIELDS},
        ${PAGINATION_FIELDS}
    }
  }
`
