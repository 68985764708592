import {ISearchEntry} from "./search";

export interface ITagState {
  loaded: boolean;
  tags: TSavedDocumentTag[],
  colorDiction: { [key: string]: string};
  lastColorIndex: number
}

export type TSavedDocumentTag = {
  dateCreated: string;
  id: number;
  text: string;
}

export type TSavedDocument = {
  dateCreated: string;
  id: number;
  notes: string | null;
  documentId: string;
  tags: TSavedDocumentTag[];
}

export type TSavedBibliography = {
  id: number;
  biblioId: string;
}

export interface ISavedState {
  documents: TSavedDocument[],
  bibliographies: TSavedBibliography[]
  searches: ISearchEntry[],
  history: ISearchEntry[]
}

export const UpdateSaveAction: string = "update-save-action";
export const UpdateTagNotesAction: string = "update-tag-add-remove-edit-notes-action";
