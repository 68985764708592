import {Context, createContext} from "react";
import * as Constants from "../constants";
import {TSavedDocumentTag} from "./redux/saved";

export interface ITagModalContext  {
  modeState: string;
  setModalState(value: string):void;
  selectedTag: TSavedDocumentTag | null;
  setSelectedTag(value: TSavedDocumentTag): void;
}

const defaultState: any = {
  modeState:Constants.APPLIED_TAGS,
  selectedTag: null
}
export const TagsModalContext: Context<ITagModalContext> = createContext<ITagModalContext>(defaultState)