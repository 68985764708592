import {IToast, IToasts, UpdateToastAction} from "../../types/redux/toast";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: IToasts = {
  toasts: [],
  lastId: 0
};

export const toastSlice = createSlice({
  name: UpdateToastAction,
  initialState: initialState,
  reducers: {
    addToast: (state: IToasts, action: PayloadAction<IToast>) => {
      action.payload.id = ++state.lastId;
      const toasts: IToast[] = [...state.toasts];
      toasts.push(action.payload);
      state.toasts = toasts;
      state.lastId = action.payload.id;
    },

    removeToast: (state: IToasts, action: PayloadAction<number>) => {
      const toasts: IToast[] = [...state.toasts];
      state.toasts = toasts.filter(( toast ) => {
        return toast.id !== action.payload;
      });
    }
  }
});

export const {
  addToast,
  removeToast
} = toastSlice.actions;
export default toastSlice.reducer;
