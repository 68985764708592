import React from 'react'

import serialize from './serialize'
import classes from './index.module.scss'
import './styles.scss';

const RichText: React.FC<{ className?: string; content: any }> = ({ className, content }) => {
  if (!content) {
    return null
  }

  return (
    <span className={'rich-text ' + [classes.richText, className].filter(Boolean).join(' ')}>
      {serialize(content)}
    </span>
  )
}

export default RichText
