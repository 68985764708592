import React, {Fragment} from 'react';

/* Types, Constants, Utils */
import {IContentState} from "../../../../../types/redux/content";

/* Redux */
import {RootState} from "../../../../../redux";
import { useSelector} from "react-redux";

/* Components */
import { Label } from '../Label'
import { LargeBody } from '../LargeBody'

import RichTextUpload from "./Upload";
import {Video} from "./Video";
import DocumentCount from "./DocumentCount";
import LinkButton from "../../../../../components/button-link";

import TextLink from "../../../../../components/text-link";
import {CMSLink} from "../Link";

/* third party */
const escapeHTML = require('escape-html');

type Children = Leaf[]

type Leaf = {
  type: string
  value?: {
    url: string
    alt: string
  }
  children?: Children
  url?: string
  [key: string]: unknown
}

const textAlign = (value: any): any =>{
  if (value){
    return {width: '100%', textAlign: value, display: 'inline-block'}
  } else {
    return {};
  }
}

const serialize = (children?: Children): React.ReactNode[] =>
  children?.map((node, i) => {
    if (node.text || node.bold || node.code || node.italic || node.underline || node.strikethrough) {

      /* @ts-ignore */
      let text = <span dangerouslySetInnerHTML={{ __html: escapeHTML(node.text) }} key={i} />

      if (node.text === '\n') {
        return <Fragment key={i}></Fragment>
      }

      if (node.bold) {
        text = <strong key={i}>{text}</strong>
      }

      if (node.code) {
        text = <code style={{whiteSpace: 'pre-line' }} key={i}>{text}</code>
      }

      if (node.italic) {
        text = <i style={{whiteSpace: 'pre-line' }} key={i}>{text}</i>
      }

      if (node.underline) {
        text = <span style={{ textDecoration: 'underline', whiteSpace: 'pre-line' }} key={i}>
            {text}
          </span>
      }

      if (node.strikethrough) {
        text =  <span style={{ textDecoration: 'line-through' }} key={i}>
            {text}
          </span>
      }

      return <span className={'text'} style={{whiteSpace: 'pre-line' }} key={i + 'wp'}>{text}</span>
    }

    const fields: any = node?.fields;
    const firstChild: any = (node?.children) && (node.children.length > 0) && node?.children[0];

    switch (node.type) {
      case 'indent':
        return <div className={'ms-4 mt-1'} key={i} >{serialize(node?.children)}</div>
      case 'br':
        return <div className={'br'} key={i} />
      case 'lf':
        return <div className={'lf'} key={i} />
      case 'h1':
        return <h1 style={textAlign(node.textAlign)} key={i}>{serialize(node?.children)}</h1>
      case 'h2':
        return <h2 style={textAlign(node.textAlign)} key={i} className={'py-1'} >{serialize(node?.children)}</h2>
      case 'h3':
        return <h3 style={textAlign(node.textAlign)} key={i} className={'py-1'}>{serialize(node?.children)}</h3>
      case 'h4':
        return <h4 style={textAlign(node.textAlign)} key={i} className={'py-1'}>{serialize(node?.children)}</h4>
      case 'h5':
        return <h5 style={textAlign(node.textAlign)} key={i} className={'py-1'}>{serialize(node?.children)}</h5>
      case 'h6':
        return <h6 style={textAlign(node.textAlign)} key={i} className={'py-1'}>{serialize(node?.children)}</h6>
      case 'quote':
        return <blockquote key={i}>{serialize(node?.children)}</blockquote>
      case 'ul':
        return <ul style={textAlign(node.textAlign)} className={'ps-3 mb-2'} key={i}>{serialize(node?.children)}</ul>
      case 'ol':
        return <ol style={textAlign(node.textAlign)}>{serialize(node.children)}</ol>
      case 'li':
        return <li style={textAlign(node.textAlign)} key={i} className={'ps-2 py-1'}>{serialize(node.children)}</li>
      case 'link':
        return (
          <div className={'m-0 p-0 d-inline-block'} style={textAlign(node.textAlign)} key={i}>
            {(node.linkType === 'custom') && (fields && fields.buttonLink) &&
              <LinkButton
                label={firstChild.text}
                underline={true}
                linkArrow={true}
                link={node.url}/>}

              {(node.linkType === 'custom') && (fields && !fields.buttonLink) &&
                <TextLink
                  label={firstChild.text}
                  icon={fields?.icon}
                  link={node.url}/>}

                {(node.linkType === 'internal') &&
                  <CMSLink
                    style={textAlign(node.textAlign)}
                    type={node.linkType === 'internal' ? 'reference' : 'custom'}
                    url={node.url}
                    reference={node.doc as any}
                    newTab={Boolean(node?.newTab)}
                    fields={node?.fields}
                    // @ts-ignore
                    label={node?.children[0].text}
                  >
                    {serialize(node?.children)}
                  </CMSLink>}
          </div>
        )

      case 'label':
        return <Label key={i}>{serialize(node?.children)}</Label>

      case 'large-body': {
        return <LargeBody key={i}>{serialize(node?.children)}</LargeBody>
      }

      case 'document_count':
        return <DocumentCount node={node} index={i} key={i+'dc'}/>

      case 'release-date':
        const releaseDate: string = useSelector<RootState, IContentState>((state: RootState):any => state.content).release_date;
        return <span key={i} className={'release-date'}>{releaseDate}</span>

      case 'upload': {
        return <div className={'m-0 p-0 d-inline-block'} style={textAlign(node.textAlign)} key={i}>
            <RichTextUpload node={node} />
        </div>
      }

      case 'video': {
        const { source, id: videoID } = node
        if (source === 'vimeo' || source === 'youtube') {
          return <div className={'m-0 p-0 d-inline-block'} style={textAlign(node.textAlign)} key={i}>
            <Video key={i} platform={source} id={videoID as string} />
          </div>
        }
        return null
      }

      default:
        return <span style={textAlign(node.textAlign)} key={i}>{serialize(node?.children)}</span>
    }
  }) || []

export default serialize
