import './styles.scss';
import React, {useContext, useEffect, useState} from "react";

/* Types, Constants, Utils */
import {FilterOptionContext, IFilterOptionContext, TFilterOption, TFilterPivot} from "../../../types/search";
import * as Constants from "../../../constants";

/* Bootstrap */
import {Accordion} from "react-bootstrap";
import Utils from "../../../utils";
import FiltersCheck from "../filters-check";
import {ISelectedMutations} from "../filters-slideout";
import Spinner from "../../util/spinner";
import useDateOptions, {IUseDateOptions} from "../hooks/useDateOptions";

interface IProps {
  filterOptionElems(options: TFilterOption[], onHandleChange: any): JSX.Element;
  pivotFilter: TFilterPivot;
}

const FiltersCascade = (props: IProps):JSX.Element => {
  const {
    filterOptionElems,
    pivotFilter
  } = props;

  /* context */
  const addRemoveSelectedOptions: (mutations: ISelectedMutations) => void = useContext<IFilterOptionContext>(FilterOptionContext).addRemoveSelectedOptions;
  const selectedFilters: TFilterOption[] = useContext<IFilterOptionContext>(FilterOptionContext).selectedFilters;

  /* state */
  const [activeKeys, setActiveKeys] = useState<any>([]);
  const [filter, setFilter] = useState<TFilterPivot>({...pivotFilter});
  const [loading, setLoading] = useState<boolean>(false);

  /* hooks */
  const dateOptions: IUseDateOptions = useDateOptions(pivotFilter);

  /* @ts-ignore */
  const [selectAllOption, setSelectAllOption] = useState<TFilterOption>({
    id: pivotFilter.id + '-' + Constants.SELECT_ALL,
    label: Utils.toTitleCase(Constants.SELECT_ALL),
    value: pivotFilter.value,
    field: pivotFilter.field,
    selected: pivotFilter.selected
  })

  /* Handle date years dynamic loading results */
  useEffect(()=>{
    if (dateOptions.pivotOptions.length > 0){
      const filterCln: TFilterPivot = {...filter};
      filterCln.options = dateOptions.pivotOptions;
      setFilter(filterCln);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOptions.pivotOptions])

  useEffect(()=> {
    if (loading) {
      setLoading(false);
      handleToggleItem(filter.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.options ])

  const handleSelectSubordinate = (selectedOption: TFilterOption): void =>{
    const filterOptionCln: TFilterOption = {...selectAllOption};
    const selectedOptions: TFilterOption[] = filter.options.filter(option=> option.selected);
    const selectedMutations: ISelectedMutations = {add: [], remove: []}

    if (selectedOption.selected && (selectedOptions.length === filter.options.length)) {
      /* all subordinates were selected  ==> select all*/
      filterOptionCln.selected = true;
      selectedMutations.add.push(selectAllOption)
      selectedOptions.forEach(option=> selectedMutations.remove.push(option))
    } else if (filterOptionCln.selected) {
      /* select all and subordinate was deselected */
      filterOptionCln.selected = false;
      selectedMutations.remove.push(selectAllOption)
      selectedOptions.forEach(option=> selectedMutations.add.push(option))
    }

    /* update select all option */
    setSelectAllOption(filterOptionCln);

    /* toggle of a single option */
    if (selectedOption.selected) {
      selectedMutations.add.push(selectedOption)
    } else {
      selectedMutations.remove.push(selectedOption)
    }

    /* update selections */
    addRemoveSelectedOptions(selectedMutations);

    /* force filter clicked to select/unselect */
    const filterCln: TFilterPivot = {...filter}
    const index: number = filterCln.options.findIndex(f=>f.id === selectedOption.id);
    filterCln.options[index] = selectedOption;
    setFilter(filterCln);
  }

  const handleSelectAll = (selectedOption: TFilterOption): void =>{
    const selectedMutations: ISelectedMutations = {add: [], remove: []}
    if (selectedOption.selected) {
      /* remove any selected subordinates from applied filters and just apply root filter*/
      const selectedOptions: TFilterOption[] = filter.options.filter(f=>selectedFilters.findIndex(sf=>sf.id===f.id) !== -1);
      selectedOptions.forEach(option=> selectedMutations.remove.push(option));

      selectedMutations.add.push(selectAllOption)
      addRemoveSelectedOptions(selectedMutations);
    } else {
      selectedMutations.remove.push(selectAllOption)
      addRemoveSelectedOptions(selectedMutations);
    }

    /* set all subordinate's states */
    const filterCln: TFilterPivot = {...filter}
    filterCln.options.forEach(f=>f.selected = selectedOption.selected);
    setFilter(filterCln);

    /* set select all state */
    const filterOptionCln: TFilterOption = {...selectAllOption};
    filterOptionCln.selected = selectedOption.selected;
    setSelectAllOption(filterOptionCln);
  }

  const handleToggleItem = (key: string):void => {
    if (filter.options.length === 0) {
      /* currently on date subordinates are loaded dynamically */
      setLoading(true)
      dateOptions.refetch();
    } else {
      const index = activeKeys.indexOf(key);
      if (index > -1) {
        activeKeys.splice(index, 1);
        setActiveKeys([...activeKeys]);
      } else {
        setActiveKeys(activeKeys.concat(key));
      }
    }
  }

  return (
    <div className={'filters-cascade'}>
      <FilterOptionContext.Provider
        value={{
          addRemoveSelectedOptions: addRemoveSelectedOptions,
          selectedFilters: selectedFilters}}
      >
        <Accordion activeKey={activeKeys}>
          <Accordion.Item
            key={filter.id + '_check'}
            eventKey={filter.id}
            className={'mb-3 ms-1'}
            onClick={()=>handleToggleItem(filter.id)}
          >
            <Accordion.Header className={loading ? 'loading' : ''}>
              <span role={'button'} >
                <b>{filter.label}</b>
                <span>&nbsp;({Utils.formatNumber(filter.count)})</span>
              </span>

              {loading &&
                <Spinner size={Constants.TINY}/>}
            </Accordion.Header>

            <Accordion.Body className={'m-0 p-0 mt-2 mx-3 '}>

              {/* select all */}
              <FiltersCheck
                key={selectAllOption.id}
                onHandleChange={(value)=>handleSelectAll(value)}
                type={'checkbox'}
                filterOption={selectAllOption}/>

              {/* subordinate options */}
              {filterOptionElems(filter.options, handleSelectSubordinate)}

            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

      </FilterOptionContext.Provider>
    </div>
  )
}
export default FiltersCascade;
