import {Category, Industry, Page} from "./payload-types";
import {PAGES_BY_CATEGORY, STAGE_PAGES_BY_CATEGORY} from "./_graphql/pages";
import Utils from "../../utils";

const {
  REACT_APP_PROD_HOST,
  REACT_APP_CMS_HOST ,
  REACT_APP_LOCALHOST,
  REACT_APP_LOCAL_CMS_HOST
} = process.env;


export const fetchPagesByCategory = async (args: {
  industry: Industry['id']
  category: Category['id']
  collection: string,
  parent: boolean
}): Promise<Page[]> => {
  const { industry, category, collection, parent } = args || {}
  const apiURL: string | undefined = (window.location.host === REACT_APP_LOCALHOST) ? REACT_APP_LOCAL_CMS_HOST : REACT_APP_CMS_HOST;
  const docs: Page[] = await fetch(`${apiURL}/api/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-store',
    body: JSON.stringify({
      query: (window.location.host === REACT_APP_PROD_HOST) ? PAGES_BY_CATEGORY : STAGE_PAGES_BY_CATEGORY,
      variables: {
        industry,
        category,
        parent
      },
    }),
  })
    ?.then(res => res.json())
    ?.then(res => {
      if (res.errors) throw new Error(res?.errors?.[0]?.message ?? 'Error fetching docs')
      return res?.data[Utils.capitalizeFirstLetter(collection)]?.docs
    })

  return docs
}
