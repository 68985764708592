import {Industry, Page} from "./payload-types";
import * as Constants from "../../constants";
import Utils from "../../utils";
const { REACT_APP_CMS_HOST,
  REACT_APP_LOCALHOST,
  REACT_APP_LOCAL_CMS_HOST
} = process.env;

const CATEGORIES_BY_INDUSTRY = `
  query Pages($industry: [JSON]) {
    Pages(where: { AND: [{industries: { in: $industry }}, {parent: {equals: true}} ]}) {
      docs {
        id
        title
        industries {
          id
          title
        }
        categories {
          id
          title
        }
      }
    }
  }
`
const STAGE_CATEGORIES_BY_INDUSTRY = `
  query StagePages($industry: [JSON]) {
    StagePages(limit: 300, where: { AND: [{industries: { in: $industry }}, {parent: {equals: true}} ]}) {
      docs {
        id
        title
        industries {
          id
          title
        }
        categories {
          id
          title
        }
      }
    }
  }
`
export const fetchCategories = async (args: {
  industry: Industry['id'][]
}): Promise<Page[]> => {
  const { industry} = args || {}
  const apiURL: string | undefined = (window.location.host === REACT_APP_LOCALHOST) ? REACT_APP_LOCAL_CMS_HOST : REACT_APP_CMS_HOST;
  const docs: Page[] = await fetch(`${apiURL}/api/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-store',
    body: JSON.stringify({
      query: (window.location.host === Constants.PROD_SITE_HOST) ? CATEGORIES_BY_INDUSTRY : STAGE_CATEGORIES_BY_INDUSTRY,
      variables: {
        industry
      },
    }),
  })
    ?.then(res => res.json())
    ?.then(res => {
      if (res.errors) throw new Error(res?.errors?.[0]?.message ?? 'Error fetching docs')
      const key: string = (window.location.host === Constants.PROD_SITE_HOST) ? Constants.PAGES : Constants.STAGE_PAGES;
      return res?.data[Utils.capitalizeFirstLetter(key)]?.docs
    })

  return docs
}
