import './styles.scss';
import React, {Dispatch} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import Utils from "../../../utils";

/* Bootstrap */
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {Container} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {IBreadCrumbState, TBreadcrumb} from "../../../types/redux/breadcrumb";
import {useLocation, useNavigate} from "react-router-dom";
import {removeToCrumb} from "../../../redux/slices/breadcrumb-slice";
import {AnyAction} from "@reduxjs/toolkit";
import {setDBSet} from "../../../redux/slices/search-slice";

const BreadCrumb = ():JSX.Element => {
  const breadcrumbState: IBreadCrumbState = useSelector<RootState, IBreadCrumbState>(state => state.breadcrumb);
  const navigate = useNavigate();
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const location = useLocation();

  const goCrumb = (e: any, crumb: TBreadcrumb): void =>{
    e.preventDefault();
    if (!crumb.active) {
      // resetSearch
      dispatch(setDBSet(null))
      dispatch(removeToCrumb(crumb))
      navigate(crumb.href)
    }
  }

  const hide = ():boolean => {
    return (location.pathname === '/' + Constants.HOME) || ((location.pathname === '/') || (location.pathname === '/' + Constants.INDUSTRIES))
  }

  return (
    <div className={'breadcrumb-custom bg-white' +  (hide() ? ' hidden' : '')}>
      <Container className={'py-1'}>
        <Breadcrumb>
          {breadcrumbState.breadcrumbs.map((crumb: TBreadcrumb, index: number) => {
            const label: string = crumb.label.indexOf('/>') === -1 ? Utils.limitTitleLength(crumb.label, 20) : crumb.label;
            return (
              <Breadcrumb.Item
                key={'bc-'+index}
                active={crumb.active}
                onClick={e => goCrumb(e, crumb)}
                href={''}>
                <span dangerouslySetInnerHTML={{__html: label }}/>
              </Breadcrumb.Item>
            )
          })}
        </Breadcrumb>
      </Container>
      <hr className="w-100 bc-all-industries-100 m-0"/>
    </div>
  )
}
export default BreadCrumb;
