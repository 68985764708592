import {Category, Industry} from "../../api/payload/payload-types";

export interface IContentPage {
  id: string;
  industries: Industry;
  categories: Category;
}

export interface INewsFilter {
  label: string;
  industry: string;
  selected: boolean;
}

export type IDocumentCounts = {
  bibliography: IDocumentCountItem[];
  collection: IDocumentCountItem[];
  industry: IDocumentCountItem[];
  pages:IDocumentCountItem[];
  video: IDocumentCountItem[];
}

export interface IDocumentCountItem {
  label: string;
  value: string;
}

export interface IContentState {
  category: Category | null,
  categories: Category[];
  industries: Industry[]; /* todo: could integrate with pre-defined industries constants INDUSTRY_ITEMS, OPIOIDS, TOBACCO ... */
  content_pages: IContentPage[];
  news_filters: INewsFilter[];
  release_date: string | null;
  document_counts: IDocumentCounts;
}

export const UpdateContentAction: string = "update-content-action";
