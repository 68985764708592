import './styles.scss';
import React, {useEffect} from "react";

/* Types, Constants, Utils */
import {TFilter, TFilterOption, TFilterPivot} from "../../../types/search";

/* Bootstrap */
import {Accordion, Button} from "react-bootstrap";
import {FormCheckType} from "react-bootstrap/FormCheck";

/* Components */
import FiltersCheck from "../filters-check";
import Utils from "../../../utils";
import FiltersCascade from "../filters-cascade";

interface IProps {
  filter: TFilter;
  filterIndex: number;
  opened: boolean;
  handleToggleItem(key: string):void;
  handleExpand(filterEntry: TFilter, index: number, value: boolean): void;
}

const INITIAL_OPTIONS_COUNT: number = 9;

const FiltersFilter = (props: IProps):JSX.Element => {
  const {
    filter,
    filterIndex,
    opened,
    handleToggleItem,
    handleExpand
  } = props;

  useEffect(()=>{
    if (filter.initially_opened && !opened) {
      handleToggleItem(filter.id)
    }
  }, [opened, filter.initially_opened, filter.id, handleToggleItem])

  const filterOptionElems = (options: TFilterOption[], onHandleChange: any = null): JSX.Element => {
    return (<>
      {options.map((filterOption: TFilterOption, index: number) =>{
        if ((index <= INITIAL_OPTIONS_COUNT) || (filter.expanded)) {

          /* Check or Toggle switch filter option */
          return <FiltersCheck
              key={filterOption.id + '_fc'}
              type={filter.type as FormCheckType}
              onHandleChange={onHandleChange}
              filterOption={filterOption}/>

        } else if ((index === (INITIAL_OPTIONS_COUNT + 1)) && (!filter.expanded)) {

          /* show more - expand button */
          return (<Button
            key={filter.id + index + '_expand'}
            className={'more-less-link btn-link text-primary ms-4 p-0'}
            onClick={e=>handleExpand(filter, filterIndex, true)}
          >
            + {options.length - INITIAL_OPTIONS_COUNT + 1} more
          </Button>)

        } else {
          /* unexpanded -  hidden items */
          return <div key={filter.id + index} className={'hidden'}></div>
        }
      })}
    </>)
  }

  return (
    <Accordion.Item
      key={filter.id + '_item'}
      eventKey={filter.id}
      className={'filters-filter border-0 p-0'}
    >
      <Accordion.Header
        key={filter.id + '_header'}
        className={'py-3 px-1 text-smaller'  + (opened ? '' : ' border-bottom')}
        onClick={e=>handleToggleItem(filter.id)}
      >
        <b>{filter.heading}</b>
      </Accordion.Header >

      <Accordion.Body key={filter.id + '_body'} className={'p-0 pb-3 border-bottom'}>
        {/* filter options */}
        {filter.options &&
          <div className={'mx-4'}>
            {filterOptionElems(filter.options)}
          </div>}

        {/* or pivot options */}
        {filter.pivot_options &&
          filter.pivot_options.map((pivotFilter:TFilterPivot, index:number) => {
            return(
              <div key={'pf_' + index}>
                {filter.pivot_expandable &&
                  <FiltersCascade
                    filterOptionElems={filterOptionElems}
                    pivotFilter={pivotFilter}/>}

                {!filter.pivot_expandable &&
                  <>
                    <p className={'text-smaller m-0 p-0 ms-4 mb-2 '}> <b>{Utils.toTitleCase(pivotFilter.value)}</b></p>
                    <div className={'ps-4'}>{filterOptionElems(pivotFilter.options)}</div>
                  </>}
              </div>
            )
          })
        }

        {/* less button */}
        {filter.expanded &&
          <Button
            className={'more-less-link btn-link text-primary ms-4 p-0 mb-3'}
            onClick={e=>handleExpand(filter, filterIndex, false)}
          >
            less
          </Button>}

      </Accordion.Body>
    </Accordion.Item>
  )
}
export default FiltersFilter;
