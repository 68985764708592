import './styles.scss';
import React, {Dispatch, useEffect} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../constants";
import {PageContext} from "../../types/app";
import { CardContext } from '../../types/document';
import SearchUtils from "../../utils/search-utils";

/* Bootstrap */
import {Container} from "react-bootstrap";

/* Redux */
import {ISearchState} from "../../types/redux/search";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {AnyAction} from "@reduxjs/toolkit";
import {clearSelectedResults} from "../../redux/slices/result-slice";
import {setSearch} from "../../redux/slices/search-slice";

/* Components */
import IndustryNavbar from "../../components/industry-navbar";
import Search from "../../components/search/search-cmp";
import ViewScrollContainer from "../../components/view/view-scroll-container";
import ContentPage from "../content/components/page";

/* Hooks */
import { useCardContext } from "../../hooks";

const Industries = ():JSX.Element => {
  const searchState: ISearchState = useSelector<RootState, ISearchState>((state: RootState):any => state.search);
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const cardContext: string = useCardContext().context;

  useEffect(()=> {
    const captureBackArrow = (): void => {
      const segments: string[] = window.location.href.split('/');
      if ((segments[segments.length-2].indexOf(Constants.DOCUMENTS) !== -1) || (segments[segments.length-2].indexOf(Constants.BIBLIOGRAPHY)  !== -1)) {
        dispatch(setSearch(SearchUtils.fromDataSource(window.location.search)))
      }
    }
    window.addEventListener('popstate', captureBackArrow);

    return ()=>{
      window.removeEventListener('popstate', captureBackArrow);
    }
  }, [dispatch])

  useEffect(()=> {
    /* reset search based on url params */
    dispatch(setSearch(SearchUtils.fromDataSource(window.location.search)))
  }, [dispatch])

  useEffect(()=>{
    dispatch(clearSelectedResults())
  }, [dispatch, searchState.industry])

  return (
    <div className={'industry view'}>
      <PageContext.Provider value={{context: Constants.INDUSTRY}}>
        <CardContext.Provider value={{context:cardContext}}>
          <ViewScrollContainer>
          <Container className={'px-0 px-lg-4'}>
            <IndustryNavbar  />

            {/* Content page --> db_set === null */}
            {!searchState.db_set && <ContentPage/>}

            {/* Search bar when in a db set*/}
            {searchState.db_set && <Search/>}

            {(searchState.industry === Constants.TOBACCO) &&
              <div className={'partners mt-5 d-flex'}>
                <img className={'me-3'} src="/assets/images/ucsf-logo-with-tag-line.svg" alt="" height="55"/>
                <div className={'vertical-hr ms-2 me-4'}/>
                <img src="/assets/images/truth-logo-2020-color.svg" alt="" height="48"/>
              </div>}

            {(searchState.industry === Constants.OPIOIDS) &&
              <div className={'partners mt-5 d-flex'}>
                <img className={'me-3'} src="/assets/images/ucsf-logo-with-tag-line.svg" alt="" height="55"/>
                <div className={'vertical-hr ms-2 me-4'}/>
                <img src="/assets/images/johns-hopkins-university-logo.svg" alt="" height="160"/>
              </div>}
          </Container>
        </ViewScrollContainer>
        </CardContext.Provider>
      </PageContext.Provider>
    </div>
  )
}

export default Industries;
