import {idlAPIClient, idlAPIClientLegacy} from "./axios";
import {AxiosResponse} from "axios";
import Utils from "../utils";
import LoginRegistrationService from "./login-registration";

const downloadCite = async (format: string, recordId: string):Promise<any> => {
  const response:AxiosResponse<any, any> = await idlAPIClientLegacy.post<any>(
    "/bookmark/download",
    {
      recordKeys: recordId,
      exportFormat: format,
      solrUrl: 'https://solr.idl.ucsf.edu/solr/ltdl3',
      docUrl: 'https://www.industrydocuments.ucsf.edu/'
    });
  return response.data;
}

const downloadFile = async (documentId: string, fileName: string, progressCallback: Function | null = null):Promise<any> => {
  const response:AxiosResponse<any, any> = await idlAPIClient.get<any>(
    "files/" + documentId + '/' + fileName,
    {
      withCredentials: false,
      responseType: 'blob',
      onDownloadProgress: progressEvent => {
        if (progressCallback) {
          progressCallback(fileName, progressEvent);
        }
      }
    });
  return response;
}

const downloadAllFiles = async (documentId: string, filename: string, progressCallback: Function | null = null):Promise<any> => {
  const token: string | null = await LoginRegistrationService.getToken();
  if (token) {
    const response: AxiosResponse<any, any> = await idlAPIClient.get<any>(
      "files/" + documentId,
      {
        headers: {"x-csrf-token": token},
        withCredentials: true,
        responseType: 'blob',
        onDownloadProgress: progressEvent => {
          if (progressCallback) {
            progressCallback(filename, progressEvent);
          }
        }
      });
    return response;
  }
}

const bulkDownload = async (documentIds: string[]):Promise<any> => {
  const token: string | null = await LoginRegistrationService.getToken();
  if (token) {
    const response: AxiosResponse<any, any> = await idlAPIClient.post<any>(
      "files/bulk",
      {
        uid: Utils.generateUUID(),
        ids: documentIds
      },
      {
        headers: {"x-csrf-token": token},
        withCredentials: true
      });
    return response.data;
  }
}

const DownloadService = {
  downloadCite,
  downloadAllFiles,
  bulkDownload,
  downloadFile
}

export default DownloadService;