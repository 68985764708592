import './styles.scss';
import React, {useContext} from "react";

/* Types, Constants, Utils */
import {IViewerRecordContext, ViewerRecordContext} from "../../../types/document";
import * as Constants from "../../../constants";

/* Components */
import {ButtonGroup} from "react-bootstrap";
import {ShareItems} from "../../util/share-items";

const ViewerShare = ():JSX.Element => {
  const currentDocument: any = useContext<IViewerRecordContext>(ViewerRecordContext).viewerRecord;

  return  (currentDocument ?
      <div className={'viewer-share px-3'}>
        <ButtonGroup className={'mt-2 d-grid'}>
           <ShareItems
             type={Constants.BUTTON}
             records={[currentDocument]}
           />
        </ButtonGroup>
      </div>
   : <></>
  )
}
export default ViewerShare;
