import {Dispatch} from "react";
import {ISavedState, TSavedDocument} from "../types/redux/saved";
import SavedService from "../api/saved";
import {addToSavedDocuments} from "../redux/slices/saved-slice";
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux";

const useSaveSelected = (): any => {
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const savedDocuments: TSavedDocument[] = useSelector<RootState, ISavedState>(state => state.saved).documents;

  return async (selectedRecords: any[]): Promise<any> => {
    /* Get saved document id with solr document id */
    const savedDocumentIdsObj: any[] = [];
    const unSaveDocumentsId: string[] = [];
    selectedRecords.forEach((record: any) => {
      const saveDocument: TSavedDocument | undefined = savedDocuments.find((savedDocument: TSavedDocument) => record.id === savedDocument.documentId)
      if (saveDocument) {
        /* backend requires object */
        savedDocumentIdsObj.push({id: String(saveDocument.id)})
      } else {
        unSaveDocumentsId.push(record.id)
      }
    });

    if (unSaveDocumentsId.length !== 0) {
      /* save documents that are not saved */
      const recordsToSave: TSavedDocument[] = unSaveDocumentsId.map((id: string) => {
        return {
          dateCreated: '',
          id: -1,
          documentId: id,
          tags: [],
          notes: null
        }
      });

      const savedDocuments: TSavedDocument[] = await SavedService.saveDocuments(recordsToSave);
      dispatch(addToSavedDocuments(savedDocuments));
      savedDocuments.forEach(saveDocument => {
        savedDocumentIdsObj.push({id: String(saveDocument.id)})
      })
    }

    return savedDocumentIdsObj;
  };
};

export default useSaveSelected;