import './styles.scss';
import React from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import {RecordsContext} from "../../../types/document";

/* Bootstrap */
import {Card, Col, Container, Row} from "react-bootstrap";

/* Components */
import MediaPreview from "../../cards/media-preview";
import {IViewerState} from "../../../types/redux/viewer";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";

interface IProps  {
  record: any;
  index: number;
  page: number;
  resultsPerPage: number;
}

const ViewerCard = (props: IProps):JSX.Element => {
  const {
    record,
    index,
    page,
    resultsPerPage
  } = props;

  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);
  const resultNumber: number = (page - 1) * resultsPerPage + index + 1;

  const getTitle = (): JSX.Element => {
    const title: string = (record.highlighting && record.highlighting.ti) ? record.highlighting.ti : record.ti;
    return (
      <h6 className={"text-small ellipse mb-0"}>
        <b>
          <span>{resultNumber}. </span>
          {title ? title : Constants.NO_TITLE}
        </b>
      </h6>
    )
  }

  const entry = (label: string, value: string | JSX.Element | undefined): JSX.Element => {
    const cssClass: string = label.toLowerCase().replace(' ', '-');
    if (value) {
      return (
        <>
          <div className={'d-none d-lg-block mb-2 px-1 ellipse'}>
            <b>{label}</b>: {value}
          </div>

          <Row className={'d-lg-none px-3'}>
            <Col xs="5" className={'px-1 pb-2 ellipse '}><b>{label}</b></Col>
            <Col xs="7" className={'px-1 pb-2 ellipse ' + cssClass}>{value}</Col>
          </Row>
        </>
      )
    }
    return <></>
  }

  return (
    <RecordsContext.Provider value={{records:[record]}}>
      {record && <Card className={"viewer-card my-0 p-0"} id={'card-' + resultNumber}>
        <Card.Header className={'px-3 py-3'}>
          {getTitle()}
        </Card.Header>

        <Card.Body className={'px-2 py-3'}>
          <Container className={'ps-3 pe-2'}>
            <Col xs="12" className={'text-small'}>
              <Row className={'mb-0'}>
                <Col xs="8" >
                  <Row className={'doc-content mb-0 position-relative'}>
                    {entry('Author', record.au)}
                    {entry('Date', record.dd)}
                    {entry('Type', record.dt ? record.dt.join(', ')  : undefined)}
                    {entry('Genre', record.genre)}
                    {entry('ID', record.id)}
                    {entry('ARK', "ark:/88122/"+record.id)}
                    {entry('Collection', record.collection ? record.collection.join(', ') : undefined)}
                    {record.desc && entry('Description', record.desc)}
                  </Row>

                </Col>

                {/* Preview Image */}
                <Col xs="4" className={'h-100 center'}>
                 <MediaPreview context={Constants.VIEWER}/>
                  {(viewerState.viewed.indexOf(record.id) !== -1) &&
                    <div className={'mt-1 p-1 bg-secondary rounded text-xsmall text-white text-center'}>Viewed</div>
                  }
                </Col>
              </Row>
            </Col>

          </Container>
        </Card.Body>
    </Card>}
    </RecordsContext.Provider>
  )
}

export default ViewerCard;
