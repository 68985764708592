import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";

/* Types, Constants, Utils */
import {RecordsContext} from "../../../types/document";
import Utils from '../../../utils'
import * as Constants from "../../../constants";

/* Bootstrap */
import {Card, Col, Collapse, Container, Row} from "react-bootstrap";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {IResultsState} from "../../../types/redux/result";
import {addToSelectedResults, removeFromSelectedResults} from "../../../redux/slices/result-slice";
import {setQuery} from "../../../redux/slices/search-slice";

/* Components*/
import CardHeader from "../card-header";

/* Hooks */
import useSearchReset from "../../../hooks/useSearchReset";
import useCardField, {IUseCardField} from "../hooks/useCardField";

interface IProps  {
  record: any,
  index: number
}

const CollectionCard = (props: IProps):JSX.Element => {
  const {
    record
  } = props;

  /* const */
  const industry: string = Array.isArray(record.industry) ? record.industry.join(' ,') : record.industry;

  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const resultsState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);

  /* state */
  const [expanded, setExpanded] = useState<boolean>(resultsState.expand_all);
  const [selected, setSelected] = useState<boolean>(false);

  /* hooks */
  const searchReset: Function = useSearchReset();
  const {getFieldContent}: IUseCardField = useCardField();

  useEffect(()=>{
    /* toolbar select all and expand all*/
    setExpanded(resultsState.expand_all);
    setSelected(resultsState.selected.findIndex(result => result.id === record.id) !== -1);

  }, [resultsState.expand_all, resultsState.selected, record.id])

  const handleLinkClick = (e:React.MouseEvent):void=>{
    e.preventDefault();
    e.stopPropagation();

    searchReset(Constants.DOCUMENTS)

    dispatch(setQuery({
      query: [{
        operator: null,
        field: Constants.ALL,
        match: Constants.CONTAINS,
        term: 'collection:"' + record.name +'"'
      }],
      recent_query: false
    }))
  }

  const getLabel = (): JSX.Element => {
    return <NavLink
      to={''}
      onClick={handleLinkClick}
    >
      <b>{getFieldContent(record.name)}</b>
    </NavLink>
  }

  const entry = (label: string, value: string): JSX.Element => {
    return <>
      <Col lg="2" ><b>{label}</b></Col>
      <Col lg="10" >{getFieldContent(value)}</Col>
    </>
  }

  const handleSelected = (selected: boolean): void => {
    setSelected(selected);
    if (selected) {
      dispatch(addToSelectedResults([record.id]))
    } else {
      dispatch(removeFromSelectedResults([record.id]))
    }
  }

  return (
    <RecordsContext.Provider value={{records:[record]}}>
        <Card className={"collection-card m-0 mb-3 p-0"}>
          <CardHeader
            selected={selected}
            handleSelected={handleSelected}
            getLabel={getLabel}
          />

          <Card.Body>
            <Container className={"small"}>
              <Row className={"mb-2"}>
                <Col lg="2" ><b>Industry</b></Col>
                <Col lg="10" >{Utils.toTitleCase(industry)}</Col>
              </Row>

              <Row className={"mb-2"}>
                <Col lg="2" ><b>Document Count</b></Col>
                <Col lg="10" ><a href={'.'}>12,100</a></Col>
              </Row>

              <Row className={"mb-2"}>
                <Col lg="2" ><b>Background</b></Col>
                <Col lg="10" ><div className={'description'}/>{getFieldContent(record.description)}</Col>
              </Row>

              <Collapse in={expanded}>
                <Col>
                  <Row className={'mb-2'}>
                    {entry('Where to Start', '')}
                  </Row>

                  <Row className={'mb-2'}>
                    {entry('Related Resources', '')}
                  </Row>
                </Col>
              </Collapse>

              <Row className="expand">
                <Col lg="12" className="p-0 text-center" >
                  <button
                    className="btn btn-link p-0"
                    onClick={() => setExpanded(!expanded)}
                    aria-controls="document-collapse-text"
                    aria-expanded={expanded}>
                    {expanded ? "collapse" : "expand"}
                  </button>
                </Col>
              </Row>

            </Container>
          </Card.Body>
        </Card>
      </RecordsContext.Provider>
  )
}

export default CollectionCard;