import './styles.scss';
import React, {Dispatch, useContext, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import {CardContext, IContext, IRecordsContext, RecordsContext} from "../../../types/document";

/* Bootstrap*/
import {Button, ButtonGroup, Dropdown, OverlayTrigger, Tooltip} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {IResultsState} from "../../../types/redux/result";

/* Components */
import Save from "../components/save";
import DownloadDropdown from "../components/download-dropdown";
import PageToolsKebab from "../components/page-tools-kebab";

/* API */
import DownloadService from "../../../api/download";
import ShareModal from "../../modal/share-modal";
import {IUserState} from "../../../types/redux/user";
import LoginPromptModal from "../../modal/login-prompt-modal";
import {AnyAction} from "@reduxjs/toolkit";
import {addToast} from "../../../redux/slices/toast-slice";
import NotifyModal from "../../modal/notify-modal";

interface IProps {
  context: string
}

const CardTools = (props: IProps):JSX.Element => {
  /* Context */
  const cardContext: any = useContext<IContext>(CardContext).context;
  const records: any = useContext<IRecordsContext>(RecordsContext).records;

  /* app state */
  const resultState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);

  /* state */
  const [loginPromptModal, setLoginPromptModal] = useState<boolean>(false);
  const [shareModalShow, setShareModalShow] = useState<boolean>(false);
  const [notifyModal, setNotifyModal] = useState<boolean>(false);

  /* Hooks */
  const dispatch:Dispatch<AnyAction> = useDispatch();

  const handleDownloadClick = (): void => {
    if (userState.loggedIn) {
      DownloadService.bulkDownload(resultState.selected.map(result => result.id)).then(()=>{
        setNotifyModal(true);
      }).catch(e=>{
        /* notify user of success */
        dispatch(addToast({
          bg: 'bg-danger',
          msg: "An error occurred. Please try again later.",
          id: -1
        }))
      });
    } else {
      setLoginPromptModal(true)
    }
  }

  return (
    <div className={'card-tools p-0'}>
      <ButtonGroup aria-label="Card Tools">
        {/* Download - Documents only */}
        {(cardContext === Constants.DOCUMENT) &&
          <div className={'download-btns ms-3 me-1'}>
            {(records.length === 0) &&
              <OverlayTrigger overlay={
                <Tooltip className={records.length ? 'hidden' : ''}>{Constants.TOOLTIP_PAGE_PAGE_TOOLS}</Tooltip>
              }>
                <span> {/* enable bootstrap tooltip to display when button is disabled */}
                  <Dropdown.Toggle
                    disabled={true}
                    variant={'success'}
                    className={'btn btn-sm btn-light mt-1'}
                  >
                    <i className={'bi bi-download'}/>
                    <span className={'ms-2 me-1'}>Download</span>
                  </Dropdown.Toggle>
               </span>
            </OverlayTrigger>}

            {/* One document: download artifacts */}
            {(records.length === 1) &&
              <DownloadDropdown/>}

            {/* More than one document: bulk download */}
            {(records.length > 1) &&
              <Button
                className={'btn btn-sm btn-light mt-1'}
                onClick={()=>handleDownloadClick()}
              >
                <i className={'bi bi-download'}/>
                <span className={'ms-2 me-1'}>Bulk download</span>
              </Button>}
          </div>}

        {/* Share */}
        {((cardContext === Constants.BIBLIOGRAPHY) ||
          (cardContext === Constants.COLLECTION) ||
          (cardContext === Constants.SEARCH)) &&
            <Button
              className={'btn btn-sm btn-light rounded m-1 ms-3'}
              onClick={() => setShareModalShow(true)}
              disabled={(records.length === 0)}
            >
              <i className={'bi bi-share'}/>
              <span className={'ms-2 me-1'}>Share</span>
            </Button>}

        {/* Save */}
        {((cardContext === Constants.DOCUMENT) ||
          (cardContext === Constants.BIBLIOGRAPHY) ||
          (cardContext === Constants.SEARCH)) &&
          <div className={'mt-1'}>
            <Save/>
          </div>}

        {/* Kebab Menu */}
        <PageToolsKebab context={props.context}/>
      </ButtonGroup>

      <ShareModal
        show={shareModalShow}
        onHide={() => setShareModalShow(false)}
      />

      <NotifyModal
        title={'Bulk Download'}
        message={'Your selected files are being processed. Check your email for on instructions to download.'}
        show={notifyModal}
        onHide={() => setNotifyModal(false)}
     />

      {loginPromptModal && <LoginPromptModal
        title={'Bulk Download'}
        show={loginPromptModal}
        onHide={()=>setLoginPromptModal(false)}/>}
    </div>
  )
}
export default CardTools;
