import './styles.scss';
import React, {Dispatch, useContext, useEffect, useRef, useState} from "react";

/* Types, Constants, Utils */
import {IRecordsContext, IViewerRecordContext, RecordsContext, ViewerRecordContext} from "../../../types/document";
import * as Constants from "../../../constants";

/* Bootstrap */
import {Button, Dropdown} from "react-bootstrap";
import Utils from "../../../utils";

/* Redux */
import {addToast} from "../../../redux/slices/toast-slice";
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";

/* Modules */
import copy from 'copy-to-clipboard';
import DownloadService from "../../../api/download";

const ViewerCite = ():JSX.Element => {
  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();

  /* context */
  const viewerDocument: any = useContext<IViewerRecordContext>(ViewerRecordContext).viewerRecord;
  const cardDocument: any = useContext<IRecordsContext>(RecordsContext).records[0];

  /* state */
  const [citeStyle, setCiteStyle] = useState<string | null>(Constants.VANCOUVER);
  const [citeFileFormat, setCiteFileFormat] = useState<string | null>(null);
  const [currentDocument, setCurrentDocument] = useState<any>(null);

  /* refs */
  const textFieldRef = useRef(null);

  useEffect(()=>{
    if (viewerDocument) {
      setCurrentDocument(viewerDocument)
    } else  if (cardDocument) {
      setCurrentDocument(cardDocument)
    }
  }, [viewerDocument, cardDocument])

  const handleCopy = ()=> {
    let html: any = textFieldRef.current;
    if (html) {
      const htmlStr: string = html.innerHTML;
      const contentString: string = Utils.removeTags(htmlStr.split('</div>').join('\n'));
      try {
        if (copy(contentString)) {
          dispatch(addToast({
            bg: 'bg-success',
            msg: Constants.CITE_COPIED,
            id: -1
          }))
        } else {
          // todo: jain - handle error
          console.log('Unable to copy to clipboard')
        }
      } catch (e) {
        throw new Error('Unable to copy to clipboard')
      }
      copy(contentString)
    }
  }

  const downloadBlob = (content:any, filename:any, contentType:any) => {
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);

    // Create a link to download it
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
  }

  const handleDownload = ()=>{
    let format: string = 'text';
    let ext: string = 'txt';
    let contentType: string = 'data:text/plain;charset=utf-8;';

    switch (citeFileFormat) {
      case Constants.REFWORKS:
        format = 'refworks';
        break;

      case Constants.ENDNOTE:
        format = 'endnote';
        break;

      case Constants.EXCEL:
        format = 'excel';
        ext = 'xlsx';
        contentType = 'application/vnd.ms-excel;'
        break;

      case Constants.CSV:
        format = 'csv';
        ext = 'csv';
        break;
    }

    DownloadService.downloadCite(format, currentDocument.id).then(result=>{
      downloadBlob(result, 'idl_cite.' + ext, contentType)
    });
  }

  const cite = (): JSX.Element => {
    switch (citeStyle) {
      case Constants.VANCOUVER:
        return <>
          {currentDocument.au && <span>{currentDocument.au}. </span>}
          <span>{currentDocument.ti}. </span>
          <span>UCSF Industry Documents Library, </span>
          {currentDocument.collection && <span>{currentDocument.collection.join(', ')}, </span>}
          <span>{currentDocument.source} </span>
          <span>{currentDocument.dd} [cited: {new Date().toLocaleString('default', {month: 'short', day: 'numeric', year:'numeric'})} ] </span>
          <span>Available: {window.location.protocol + '//' + window.location.host + '/' + Utils.convertToKeyOrId(currentDocument.industry) +'/documents/viewer?id=' + currentDocument.id}.</span>
        </>

      case Constants.AMA:
        return <>
          {currentDocument.au && <span>{currentDocument.au}. </span>}
          <span>{currentDocument.ti}. </span>
          <span>UCSF Industry Documents Library, </span>
          {currentDocument.collection && <span>{currentDocument.collection.join(', ')}, </span>}
          <span>{currentDocument.source} </span>
          <span>{currentDocument.dd}. Accessed: {new Date().toLocaleString('default', {month: 'short', day: 'numeric', year:'numeric'})}. </span>
          <span>{window.location.protocol + '//' + window.location.host + '/' + Utils.convertToKeyOrId(currentDocument.industry) +'/documents/viewer?id=' + currentDocument.id}.</span>
        </>

      case Constants.APA:
        return <>
          {currentDocument.au && <span>{currentDocument.au}. </span>}
          <span>({currentDocument.dd}). </span>
          <span><i>{currentDocument.ti}</i>. </span>
          <span>UCSF Industry Documents Library, </span>
          {currentDocument.collection && <span>{currentDocument.collection.join(', ')}, </span>}
          {currentDocument.source && <span>{currentDocument.source}. </span>}
          <span>{window.location.protocol + '//' + window.location.host + '/' + Utils.convertToKeyOrId(currentDocument.industry) +'/documents/viewer?id=' + currentDocument.id}.</span>
        </>

      case Constants.MLA:
        return <>
          {currentDocument.au && <span>{currentDocument.au}. </span>}
          <span>“{currentDocument.ti}”. </span>
          <span>{currentDocument.dd}. </span>

          <span><i>UCSF Industry Documents Library, </i></span>
          {currentDocument.collection && <span><i>{currentDocument.collection.join(', ')}, </i></span>}
          {currentDocument.source && <span><i>{currentDocument.source}</i>. </span>}
          <span>{window.location.protocol + '//' + window.location.host + '/' + Utils.convertToKeyOrId(currentDocument.industry) +'/documents/viewer?id=' + currentDocument.id}. </span>
          <span>Accessed {new Date().toLocaleString('default', {month: 'short', day: 'numeric', year:'numeric'})}. </span>
        </>
    }

    return <></>
  }
  return  (
    currentDocument ? <div className={'viewer-cite px-3'}>
      <div className={'cite-container mt-4 px-3 py-2 mb-3 border rounded'} ref={textFieldRef}>
        {cite()}
       </div>

      <Dropdown
        className={'cite-format-dropdown pt-2'}
        onSelect={(eventKey: string | null)=>setCiteStyle(eventKey)}
      >
        <Dropdown.Toggle
          variant={'light'}
          id={'select-cita'}
          className={'mt-1 w-100 text-start bg-white border border-dark py-2 text-xnormal'}
          value={citeStyle ? citeStyle : ''}
        >
          <span className={'ms-2 me-1'}>{citeStyle ? citeStyle : 'Select Citation Style'}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu className={'w-100 text-xnormal'}>
          <Dropdown.Item href={''} eventKey={Constants.VANCOUVER}>{Constants.VANCOUVER}</Dropdown.Item>
          <Dropdown.Item href={''} eventKey={Constants.AMA}>{Constants.AMA}</Dropdown.Item>
          <Dropdown.Item href={''} eventKey={Constants.APA}>{Constants.APA}</Dropdown.Item>
          <Dropdown.Item href={''} eventKey={Constants.MLA}>{Constants.MLA}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Button
        className={'w-100 bg-transparent border border-dark mt-2 btn-hover-grey-md'}
        onClick={()=>handleCopy()}
      >
        <i className={'me-2 bi bi-clipboard-plus text-body'}/>
        <span className={'text-body border-dark'}>Copy</span>
      </Button>

      <Dropdown
        className={'download-dropdown pt-4'}
        onSelect={(eventKey: string | null)=>setCiteFileFormat(eventKey)}
      >
        <Dropdown.Toggle
          variant={'light'}
          id={'select-cita'}
          className={'w-100 text-start bg-transparent border border-dark py-2 text-xnormal'}
          value={citeFileFormat ? citeFileFormat : ''}
        >
          <span className={'ms-2 me-1'}>{citeFileFormat ? Utils.toTitleCase(citeFileFormat.replace(/-/g, ' ')) : 'Select Download Format'}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu className={'w-100 border-dark'}>
          <Dropdown.Item href={'#'} eventKey={Constants.ENDNOTE}>{Utils.toTitleCase(Constants.ENDNOTE)}</Dropdown.Item>
          <Dropdown.Item href={'#'} eventKey={Constants.REFWORKS}>{Utils.toTitleCase(Constants.REFWORKS)}</Dropdown.Item>
          <Dropdown.Item href={'#'} eventKey={Constants.EXCEL}>{Utils.toTitleCase(Constants.EXCEL)}</Dropdown.Item>
          <Dropdown.Item href={'#'} eventKey={Constants.CSV}>{Constants.CSV.toUpperCase()}</Dropdown.Item>
          <Dropdown.Item href={'#'} eventKey={Constants.PLAIN_TEXT}>{Utils.toTitleCase(Constants.PLAIN_TEXT)}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Button
        className={'w-100 bg-transparent border-dark border btn-hover-grey-md mt-2'}
        onClick={()=>handleDownload()}
      >
        <i className={'me-2 bi bi-download text-body'}/>
        <span className={'text-body ext-xnormal'}>Download</span>
      </Button>
    </div>
   : <></>
  )
}
export default ViewerCite;
