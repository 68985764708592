import './styles.scss';
import React, {Dispatch} from "react";
import {Button} from "react-bootstrap";

import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {IViewerState} from "../../../../types/redux/viewer";
import {RootState} from "../../../../redux";
import {
  setDocumentRotation,
  setZoom
} from "../../../../redux/slices/viewer-slice";

const RotationButton = ():JSX.Element => {
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);

  return(
    <div className={'rotation-button me-1'}>
      <Button
        onClick={()=>{
          dispatch(setDocumentRotation((viewerState.rotation + 90) % 360))
          dispatch(setZoom(viewerState.zoom + 0.00001)) /*  forcing update after rotation */
        }}
        className={'border-0' + ((viewerState.rotation === 0) ? ' text-black bg-transparent' : '')}
      >
        <i className={'bi bi-arrow-clockwise'}/>
      </Button>
    </div>
  )
}
export default RotationButton;
