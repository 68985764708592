import './styles.scss';
import React, {useState} from "react";

/* Bootstrap */
import {Button, Modal} from "react-bootstrap";
import {IDownload} from "../../toolbars/components/download-dropdown";

interface IProps {
  downloads: IDownload[];
  show: boolean;
}

const DownloadModal = (props: IProps):JSX.Element => {
  const {
    downloads,
    show
  } = props;

  const [minimized, setMinimized] = useState<boolean>(false);

  return (
    <Modal
      show={show}
      backdrop={false}
      enforceFocus={false}
      centered
      id={'download-modal'}
      className={'download-modal shadow rounded border-3 ' + (minimized ? ' minimized' : '')}
    >
      <Modal.Header className={'pt-2 pb-1 ps-3 pe-2 w-100 d-flex justify-content-between'}>
        <div><b>Downloading...</b></div>
        <Button
          className={'btn-icon-only'}
          onClick={()=>setMinimized(!minimized)}
        >
          <i className={'bi bi-dash-square text-black h5'}/>
        </Button>
      </Modal.Header>

      {!minimized &&
        <Modal.Body className={'px-3 pt-1 pb-3'}>

          {downloads.map((dl: IDownload, index:number) =>{
            return (
              <div key={index}>
                <p className={'mb-1 text small'}>{dl.filename}</p>
                <div className={'progress w-100'}>
                  <div className="progress-bar" role="progressbar" style={{width: dl.percentage + '%'}} aria-valuenow={dl.percentage} aria-valuemin={0} aria-valuemax={100}/>
                </div>
              </div>
            )
          })}
        </Modal.Body>
      }

    </Modal>
  )
}
export default DownloadModal;
