import './styles.scss';
import React from "react";
import {Link} from "react-router-dom";
import {Button, ButtonGroup, Container, Nav} from "react-bootstrap";
import packageInfo from '../../../../package.json';

const Footer = ():JSX.Element => {

  return <div className={"footer"}>
    <Container>
      <div className={'top d-flex justify-content-between flex-wrap pb-5'}>
        <div className={'logo'}>
          <Button className={'p-2'} variant={"transparent"} onClick={()=>window.open(' https://www.library.ucsf.edu/', '_blank')}>
            <img src="/assets/images/ucsf-library-logo-white.svg" alt="" height="48"/>
          </Button>
        </div>

        <div className={'text-white'}>
          <p className={'mb-1'}>Follow Industry Documents Library:</p>

          <ButtonGroup className={'social-icons'} aria-label="Social Media">
            <Button className={'p-2'} variant={"transparent"} onClick={()=>window.open('https://www.instagram.com/ucsflibrary/', '_blank')}>
              <img src={"/assets/images/instagram.svg"} alt="" height="30"/>
            </Button>
            <Button className={'p-2'} variant={"transparent"} onClick={()=>window.open('https://www.instagram.com/ucsflibrary/', '_blank')}>
              <img src={"/assets/images/twitter.svg"} alt="" height="30"/>
            </Button>
            <Button  className={'p-2'} variant={"transparent"} onClick={()=>window.open('https://www.facebook.com/ucsflibrary', '_blank')}>
              <img  src={"/assets/images/facebook.svg"} alt="" height="30"/>
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className={'hr w-100'}/>

      <div className={'bottom d-flex justify-content-between flex-wrap pt-1'}>
        <div className={'copyright pt-2 text-grey-dark text-xnormal'}>© 2021 The Regents of The University of California</div>

        <Nav>
          <Nav.Link className={'text-white px-2 text-xnormal'} as={Link} to="/about-idl/privacy-policy/">Privacy Policy</Nav.Link>
          <Nav.Link className={'text-white px-2 text-xnormal'} as={Link} to="/about-idl/copyright/">Copyright & Fair Use</Nav.Link>
          <Nav.Link className={'text-white px-2 text-xnormal'} as={Link} to="/help/tutorial-videos/">Tutorial Videos</Nav.Link>
          <Nav.Link className={'text-white px-2 text-xnormal'} as={Link} to="/help/ask-us/">Ask Us</Nav.Link>
          <Nav.Link className={'text-white px-2 text-xnormal'} as={Link} to="/about-idl/giving/">Donate</Nav.Link>
        </Nav>
      </div>

      <div className={'version text-grey-dark text-small'}>version {packageInfo.version}</div>

    </Container>
  </div>
}
export default Footer;
