import * as Constants from "../constants";
import {
  ADV_SEARCH_DATES,
  ADV_SEARCH_DROPDOWNS,
  TAdvConstraint,
  TAdvSearchDate,
  TConstraint,
  TQueryItem
} from "../constants";
import {ISearchDate, ISearchState} from "../types/redux/search";

/* Data */
import FILTERS_JSON from "../assets/data/filters.json";
import {TFilter, TFilterOption} from "../types/search";
import {defaultFilters} from "../redux/slices/search-slice";
import Utils from "./index";
/* @ts-ignore */
const FILTERS_DB: IFilterDB = FILTERS_JSON as IFilterDB;

const SearchUtils = {
  convertQueryParams: (params: string): TQueryItem[] =>{
    const queries: TQueryItem[] = [];
    const queryArr: string[] = params.split(';');
    for (let i=0; i<queryArr.length; i++) {
      const constraint: string[] = queryArr[i].split(',');
      queries.push({
        operator: constraint[0],
        field: constraint[1],
        match: constraint[2],
        term:  constraint[3],
      })
    }
    return queries
  },

  convertDateParams: (param: string): ISearchDate[] =>{
    const dates: ISearchDate[] = [];
    const datesArr: string[] = param.split(';');
    for (let i=0; i<datesArr.length; i++) {
      const date: string[] = datesArr[i].split(',');
      dates.push({
        field: date[0],
        from: date[1],
        to: date[2]
      })
    }
    return dates
  },

  resetFilters: (appliedFilters: TFilterOption[], context:string): TFilterOption[] => {
    let persistentFilters: string[] = [];
    switch (context) {
      case Constants.QUERY:
        persistentFilters =  [Constants.DISPLAY, Constants.ADVANCED_SEARCH];
        break;

      case Constants.ADVANCED_SEARCH:
        persistentFilters =  [Constants.DISPLAY];
        break;

      case Constants.FILTERS:
        persistentFilters = [Constants.ADVANCED_SEARCH];
        break;
    }
    return appliedFilters.filter(f => persistentFilters.indexOf(f.fq_group) !== -1);
  },

  defaultFilters: (): {default: TFilterOption[], inverted: TFilterOption[]} => {
    const defaultFilterOptions: TFilterOption[] = [];
    const invertedFilterOptions: TFilterOption[] = []
    for (let key in FILTERS_DB) {
      const entry: TFilter[] = FILTERS_DB[key];
      entry.forEach(filter=>{
        filter.options.forEach(option=>{
          if (option.selected) {
            defaultFilterOptions.push({...option})
          }
          if (option.invert) {
            invertedFilterOptions.push({...option})
          }
        })
      })
    }

    const savedSettings: string | null = localStorage.getItem(Constants.SETTINGS_KEY);
    if (savedSettings) {
      const filterSettings: any = JSON.parse(savedSettings).search_results;
      for (let key in filterSettings)  {
        if (filterSettings[key]) {
          const pos: number = defaultFilterOptions.findIndex(f=>f.id === key);
          if (pos !== -1) {
            defaultFilterOptions.splice(pos,1)
          }
        }
      }
    }

    return {
      default: defaultFilterOptions,
      inverted: invertedFilterOptions
    };
  },

  sortDefault: (params: string): string => {
    if (window.location.pathname === Constants.MY_LIBRARY_PATHNAME) {
      return Constants.MY_LIBRARY_DEFAULT_SORT
    } else {
      const searchParams: URLSearchParams = new URLSearchParams(params);
      const sortParam: string | null = searchParams.get(Constants.SORT_KEY);
      if (sortParam) {
        return sortParam
      } else {
        const savedSettings: string | null = localStorage.getItem(Constants.SETTINGS_KEY);
        if (savedSettings) {
          return JSON.parse(savedSettings).sort;
        }
      }
    }
    return Constants.DEFAULT_SORT;
  },

  fromDataSource: (params: string): ISearchState => {
    const searchParams: URLSearchParams = new URLSearchParams(params);
    const filterKey = (): string =>{
      if (window.location.pathname === Constants.MY_LIBRARY_PATHNAME) {
        return Constants.TAGS
      } else {
        /* @ts-ignore*/
        let key: string = searchParams.get(Constants.DB_SET_KEY) ? searchParams.get(Constants.DB_SET_KEY) : Constants.DOCUMENTS;
        const urlSegments: string[] = window.location.pathname.split('/');
        if (urlSegments[2] === Constants.BIBLIOGRAPHY) {
          key = Constants.BIBLIOGRAPHY
        }
        return key
      }
    }

    const dbSetValue = (): string | null =>{
      if (window.location.pathname === Constants.MY_LIBRARY_PATHNAME) {
        return Constants.TAGS
      } else {
        let key: string = searchParams.get(Constants.DB_SET_KEY) ? searchParams.get(Constants.DB_SET_KEY) : Constants.DOCUMENTS;
        const urlSegments: string[] = window.location.pathname.split('/');
        if (urlSegments[2] === Constants.BIBLIOGRAPHY) {
          key = Constants.BIBLIOGRAPHY
        }
        return key;

        //const dbSetParam: string | undefined | null = searchParams.get(Constants.DB_SET_KEY);
        //return (dbSetParam && (dbSetParam !== 'null')) ? dbSetParam : Constants.DOCUMENTS
      }
    }

    const industry = (): string => {
      const {industry } = Utils.parseIndustrySection();
      return (industry === Constants.GLOBAL) ? Constants.ALL_INDUSTRIES : industry
    };

    //industries: searchParams.get(Constants.INDUSTRY_KEY) ? searchParams.get(Constants.INDUSTRY_KEY) : Constants.ALL_INDUSTRIES,
    return {
      /* @ts-ignore */
      industry: industry(),
      db_set: dbSetValue(),
      /* @ts-ignore */ /* query: TQueryItem[];*/
      query: searchParams.get(Constants.QUERY_KEY) ? SearchUtils.convertQueryParams(searchParams.get(Constants.QUERY_KEY).toString()) : [Constants.DEFAULT_SEARCH],
      /* @ts-ignore */
      dates: searchParams.get(Constants.DATES_KEY) ? SearchUtils.convertDateParams(searchParams.get(Constants.DATES_KEY)) : [],
      /* @ts-ignore */
      results_per_page: searchParams.get(Constants.RESULTS_PER_PAGE_KEY) ? parseInt(searchParams.get(Constants.RESULTS_PER_PAGE_KEY)) : Constants.DEFAULT_RESULT_PER_PAGE,
      /* @ts-ignore */
      current_page: searchParams.get(Constants.PAGE_KEY) ? parseInt(searchParams.get(Constants.PAGE_KEY)) : 1,
      sort: SearchUtils.sortDefault(params),
      filters_db: FILTERS_DB,
      /* @ts-ignore */
      filters_key: filterKey(),
      applied_filters: defaultFilters.default,
      recent_query: false
    }
  },

  searchParamsToConstraints: (searchString: string | null):TAdvConstraint[] => {
    const queries: TAdvConstraint[] = [];
    if (searchString) {
      const queryString: string[] = searchString.split(';');

      /* if default search then replace *:* with empty string */
      if (queryString.length === 1) {
        const qsValues: any[] = queryString[0].split(',');
        if (SearchUtils.isDefaultQuery([{
          operator: qsValues[0],
          field: qsValues[1],
          match: qsValues[2],
          term: qsValues[3]
        }])) {
          return [{...Constants.ADV_SEARCH_DEFAULT_CONSTRAINT}]
        }
      }

      /* otherwise parse the query */
      queryString.forEach((qs)=>{
        const values: any[] = qs.split(',');
        if (values.length === 4) { /* todo: short term fix for search on ; then click advanced search */
          const constraint: TAdvConstraint = {...Constants.ADV_SEARCH_DEFAULT_CONSTRAINT};

          const operator: string | null = (!values[0] )|| (values[0] === 'null') ? null : values[0];
          constraint.operator = {
            value: operator,
            label: operator ? ADV_SEARCH_DROPDOWNS.operator.find((item:TConstraint) => {
              return (item.value === operator)
            }).label : operator
          };

          constraint.field = {
            value: values[1],
            description: null,
            label: ADV_SEARCH_DROPDOWNS.field.find((item:TConstraint) =>
                (item.value === values[1])).label
          };

          constraint.match = {
            value: values[2],
            label: ADV_SEARCH_DROPDOWNS.match.find((item:TConstraint) =>
                (item.value === values[2])).label
          };

          constraint.term = (values[3] === 'null' ? '' : values[3])

          queries.push(constraint)
        }
      })
    } else {
      queries.push({...Constants.ADV_SEARCH_DEFAULT_CONSTRAINT})
    }
    return queries
  },

  dateParamToDates: (datesParams: string | null):TAdvSearchDate[] => {
    const dates: TAdvSearchDate[] = [...ADV_SEARCH_DATES];
    if (datesParams) {
      const datesString: string[] = datesParams.split(';');
      datesString.forEach((ds)=>{
        const date:string[] = ds.split(',');
        const dateEntry: TAdvSearchDate | undefined = dates.find(d=>d.field === date[0]);
        if (dateEntry) {
          dateEntry.start = new Date(date[1]).toLocaleDateString();
          dateEntry.end = new Date(date[2]).toLocaleDateString();
        }
      })
    }
    return dates
  },

  searchQueryTerms: (searchQuery: TQueryItem[]): string | null => {
    let terms: string | null = null;
     searchQuery.forEach((queryItem: TQueryItem) => {
       if ((queryItem.operator === null) || (queryItem.operator === Constants.CONTAINS)) {
         terms = (terms === null) ? queryItem.term : (terms + queryItem.term)
       }
    })
    return terms;
  },

  searchQueryToString: (searchQuery: TQueryItem[]):string => {
    return searchQuery.map((queryItem: TQueryItem) => {
      return queryItem.operator + ',' + queryItem.field + ',' + queryItem.match + ',' + queryItem.term;
    }).join(';')
  },

  isDefaultQuery: (searchQuery: TQueryItem[]):boolean => {
    /* todo: jain - this include date, filters or create a constant to denote default */
    return (searchQuery.length === 1) &&
      (String(Constants.DEFAULT_SEARCH.operator) === String(searchQuery[0].operator)) &&
      (Constants.DEFAULT_SEARCH.field === searchQuery[0].field) &&
      (Constants.DEFAULT_SEARCH.match === searchQuery[0].match) &&
      (Constants.DEFAULT_SEARCH.term === searchQuery[0].term)
  },

  /* converts array of TQueryItem to search string for search input field */
  convertSearchQuery: (searchQuery: TQueryItem[]):string => {
    let q: string = '';
    for (let i=0; i <searchQuery.length; i++) {
      const queryItem: TQueryItem = searchQuery[i];
      if (queryItem.term && (queryItem.term.length > 0)) {
        let constraint: string = '';
        const hasField: boolean = (queryItem.field !== Constants.ALL);
        if (hasField) {
          constraint += queryItem.field + ':';
        }

        let term:string;
        if (queryItem.match === Constants.CONTAINS_EXACT) {
          term = '"' +  queryItem.term + '"';
        } else {
          term = queryItem.term;
        }

        /*
          if (term.includes(' ') && (hasField) && (queryItem.match !== Constants.CONTAINS_EXACT)) {
            term = '(' + term + ')';
          } else if ((searchQuery.length > 1) && term.includes(' ')) {
            term = '(' + term + ')';
          }
        */

        constraint += term;

        if (i > 0) { /* ignore initial operator */
          constraint = ' ' + queryItem.operator + ' ' + constraint;
        }

        /* if (i > 1) {
          q = '(' + q + ')'
        }*/

        if (searchQuery.length > 1) {
          q += constraint;
        } else {
          q = constraint;
        }
      }
    }
    return q;
  }
};

export default SearchUtils;
