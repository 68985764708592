import {Page} from "./payload-types";
import Utils from "../../utils";
import {POST_BY_INDUSTRY, STAGE_POST_BY_INDUSTRY} from "./_graphql/posts";

const {
  REACT_APP_PROD_HOST,
  REACT_APP_CMS_HOST ,
  REACT_APP_LOCALHOST,
  REACT_APP_LOCAL_CMS_HOST
} = process.env;

export const fetchPostByIndustry = async (args: {
  industries: string[]
  collection: string,
  page: number
}): Promise<Page[]> => {
  const { industries, collection, page } = args || {}
  const apiURL: string | undefined = (window.location.host === REACT_APP_LOCALHOST) ? REACT_APP_LOCAL_CMS_HOST : REACT_APP_CMS_HOST;

  const docs: Page[] = await fetch(`${apiURL}/api/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-store',
    body: JSON.stringify({
      query: (window.location.host === REACT_APP_PROD_HOST) ? POST_BY_INDUSTRY : STAGE_POST_BY_INDUSTRY,
      variables: {
        industries,
        page
      },
    }),
  })
    ?.then(res => res.json())
    ?.then(res => {
      if ( res.errors ) {
        if (!res.data) {
          throw new Error(res?.errors?.[0]?.message ?? 'Error fetching docs')
        } else {
          console.error('Non fatal error: ' + res?.errors?.[0]?.message ?? 'Error fetching docs')
        }
      }
      return res?.data[Utils.capitalizeFirstLetter(collection)];
    })

  return docs
}
