import './styles.scss';
import React, {Dispatch, useContext, useEffect, useRef, useState} from "react";

/* Types, Constants, Utils*/
import * as Constants from "../../../constants";

/* Bootstrap */
import {Button, Col, Form, Modal, Row} from "react-bootstrap";

/* Redux */
import {useDispatch} from "react-redux";
import {AnyAction} from "@reduxjs/toolkit";
import {addToast} from "../../../redux/slices/toast-slice";

/* Third party */
import ReCAPTCHA from "react-google-recaptcha"

/* Component */
import EmailService from "../../../api/send";
import Utils from "../../../utils";
import {CardContext, IContext} from "../../../types/document";
import {TQueryItem} from "../../../constants";
import SearchUtils from "../../../utils/search-utils";

interface IProps {
  records: any[];
  show: boolean;
  onHide(): void
}

interface IEmailInputs {
  plain_text: boolean;
  recipients: string;
  cc_recipients: string;
  subject: string;
  message: string
}

const EmailModal = (props: IProps):JSX.Element => {
  const {
    records
  } = props;
  const cardContext: any = useContext<IContext>(CardContext).context;
  const dispatch:Dispatch<AnyAction> = useDispatch();

  const [validated, setValidated] = useState(false);
  const captchaRef = useRef<any | null>(null);
  const captchaErrorRef = useRef<HTMLInputElement | null>(null);

  const contextString = (): string =>  {
    let str: string = ' ';
    switch (cardContext) {
      case Constants.BIBLIOGRAPHY:
        str = Constants.BIBLIOGRAPHY;
        break;

      case Constants.COLLECTION:
        str = (records.length > 1) ? Constants.COLLECTIONS : Constants.COLLECTION;
        break;

      case Constants.SEARCH:
        str = (records.length > 1) ? Constants.SEARCHES : Constants.SEARCH;
        break;

      default:
        // Document and Viewer
        str = (records.length > 1) ? Constants.DOCUMENTS : Constants.DOCUMENT;
    }

    return Utils.toTitleCase(str);
  }

  const recaptchaKey = (): string => {
    let siteKey = '6LdGAs0fAAAAAIoDWeaapLYTVmGAh-oqpc0rx151'; //default to localhost
    if (window.location.host.indexOf('libckm.org') !== -1) {
      siteKey = '6LcXfEYpAAAAACzzi39TuyvX4OC86whrNCnUmsqV';
    }
    return siteKey
  }

  const [inputs, setInputs] = useState<IEmailInputs>({
    plain_text: false,
    recipients: '',
    cc_recipients: '', // todo: jain - should be your email
    subject: 'Industry Documents Library — Selected ' + contextString() + ' ' + (Utils.formatDate(new Date()).split(',')[0]),
    message: ''
  });

  useEffect(()=>{
    setValidated(false);
  }, [props.show])

  const captchaChange = (event: any):void => {
    if (captchaRef.current && captchaErrorRef.current) {
      captchaErrorRef.current.classList.remove('invalid');
    }
  }

  const handleSubmit = (event: any):void => {
    event.preventDefault();
    event.stopPropagation();

    if (captchaRef.current && captchaErrorRef.current) {
      captchaErrorRef.current.classList.remove('invalid');
      /* @ts-ignore */
      const token = captchaRef.current.getValue();
      /* todo: backend should validate this token https://blog.logrocket.com/implement-recaptcha-react-application/ */
      if (!token) {
        captchaErrorRef.current.classList.add('invalid');
        /* @ts-ignore */
        captchaRef.current.reset();
        return;
      }
    }

    const form = event.currentTarget;
    if (form.checkValidity()) {
      EmailService.emailDocument({
        sendTo: inputs.recipients.length > 1 ? inputs.recipients.split(',') : [inputs.recipients],
        cc: inputs.cc_recipients.split(','),
        isHtml: !inputs.plain_text,
        messageText: inputs.message,
        subjectText: inputs.subject
      }).then(()=>{
        props.onHide();

        /* notify user of success */
        dispatch(addToast({
          bg: 'bg-success',
          msg: Constants.EMAIL_SENT,
          id: -1
        }))
      }).catch(e=>{
        console.log('error in sending email: ' + e.message)
        // todo: jain - handle error
      })
    }
    setValidated(true);
  };

  const handleChange = (field: string, value: string | boolean):void => {
    const inputsCln: IEmailInputs = {...inputs};
    // @ts-ignore
    inputsCln[field] = value;
    setInputs(inputsCln);
  };

  const entry = (label: string, value: string | JSX.Element | null): JSX.Element => {
    return value ? <p className={"mb-2"}><b>{label}: </b> {value}</p> : <></>
  }

  const documentInfo = (record: any, index: number): JSX.Element => {
    const industry: string = Array.isArray(record.industry) ? record.industry[0] : record.industry;
    const documentLink: string = window.location.protocol + "//" + window.location.host + '/viewer/?id=' + record.id

    return (
      <Row key={'edi' + index} className={(index % 2 ? 'bg-light' : '')}>
        {/* date saved */}
        <Col md={2}>{record.ddu}</Col>

        {/* document info */}
        <Col md={5}>
          {entry('Title', (record.highlighting && record.highlighting.ti) ? record.highlighting.ti : record.ti)}
          {entry('URL', <a href={documentLink}>{documentLink}</a>)}
          {entry('Author', record.au)}
          {entry('Document Date', record.dd)}
          {entry('Type', record.dt ? record.dt.join(', ')  : undefined)}
          {entry('ID [TID]',  record.id)} {/* todo: jain - what is TID and what field is it? */}
          {entry('Collection', record.collection ? record.collection.join(', ') : undefined)}
        </Col>

        {/* industries */}
        <Col md={2}>{industry}</Col>

        {/* notes */}
        <Col md={3}>{record.notes}</Col>
      </Row>
    )
  }

  const bibliographyInfo = (record: any, index: number): JSX.Element => {
    return (
      <Row key={'edi' + index} className={'pt-1 ' + (index % 2 === 0 ? 'bg-light' : '')}>
        <Col md={10}>
          {entry('Title', record.title)}
          {entry('Abstract', record.abstract)}
          {entry('Author', record.author?.join(', '))}
          {entry('Publication Date', record.publicationdate)}
          {entry('Reference Type', record.referencetype)}
          {entry('Reference Type', record.referencetype)}
          {entry('Periodical', record.periodical)}
          {entry('Category', record.category_facet)}
          {entry('Link', <a href={record.link}>{record.link}</a>)}
        </Col>

        {/* industries */}
        <Col md={2}>{Array.isArray(record.industry) ? record.industry.join(', ') : record.industry}</Col>
      </Row>
    )
  }

  const collectionInfo = (record: any, index: number): JSX.Element => {
    return (
      <Row key={'edi' + index} className={'pt-1 ' + (index % 2 === 0 ? 'bg-light' : '')}>
        <Col md={10}>

        </Col>
      </Row>
    )
  }


  // convertSearchQuery
  const searchHistoryInfo = (record: any, index: number): JSX.Element => {
    const searchParams: URLSearchParams = new URLSearchParams(record.url.split('?')[1]);
    /* @ts-ignore */
    const queryParams: TQueryItem[] = SearchUtils.convertQueryParams(searchParams.get(Constants.QUERY_KEY));
    const queryString: string = SearchUtils.convertSearchQuery(queryParams);
    return (
      <Row key={'edi' + index} className={'pt-1 ' + (index % 2 === 0 ? 'bg-light' : '')}>
        <Col md={2}>
          {new Date(record.searchDateTime).toLocaleDateString(Utils.getLocalizationStr(), {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </Col>

        <Col md={3} className={'overflow-hidden'}>
          {entry('Query', queryString)}
          {entry('Date Range', searchParams.get(Constants.DATES_KEY))}
          {entry('Hidden Items', searchParams.get(Constants.HIDDEN_KEY))}
          {/*{entry('Collections Searched', record.collections)}*/} {/* todo: jain - is this still relevant? */}
          {entry('Filters Applied', searchParams.get(Constants.FILTERS_KEY))}
        </Col>

        <Col md={6} className={'overflow-hidden'}>
          <a href={record.url}>{record.url}</a>
        </Col>

        <Col md={1}>{Utils.formatNumber(record.count)}</Col>
      </Row>
    )
  }

  const columnsHeadings = ():JSX.Element => {
    switch (cardContext) {
      case Constants.DOCUMENT:
        return (
          <Row className={"mb-0"}>
            <Col md={2}><b>Date Saved</b></Col>
            <Col md={5}><b>Document</b></Col>
            <Col md={2}><b>Industry</b></Col>
            <Col md={3}><b>Notes</b></Col>
          </Row>
        )

      case Constants.BIBLIOGRAPHY:
        return (
          <Row className={"mb-0"}>
            <Col md={10}><b>Document</b></Col>
            <Col md={2}><b>Industry</b></Col>
          </Row>
        )

      case Constants.COLLECTION:
        return (
          <Row className={"mb-0"}>
          </Row>
        )

      case Constants.SEARCH:
        return (
          <Row className={"mb-0"}>
            <Col md={2}><b>Date</b></Col>
            <Col md={3}><b>Search</b></Col>
            <Col md={6}><b>URL</b></Col>
            <Col md={1}><b>Results</b></Col>
          </Row>
        )
    }

    return  <></>
  }

  return (
    <Modal
      {...props}
      backdrop="static"
      keyboard={false}
      centered
      className={"email-modal"}
    >
      <Modal.Header closeButton>
        <Modal.Title>{'Email Your Selected ' + contextString()}</Modal.Title>
      </Modal.Header>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <div className={"px-3"}>
            {/* Plain text checkbox */}
            <Form.Group className={"mb-3"} controlId="plain-text">
              <Form.Check
                type="checkbox"
                label="Send as plain text"
                checked={inputs.plain_text}
                onChange={e=>handleChange('plain_text', e.target.checked)}
              />
            </Form.Group>

            {/* Recipients */}
            <Form.Group as={Row} className={"mb-2"} controlId="recipients">
              <Col sm={2} className={"text-end"}>
                <Form.Label >To:</Form.Label>
              </Col>
              <Col sm={10} >
                <Form.Control
                  required
                  type="text"
                  placeholder=""
                  value={inputs.recipients}
                  onChange={e=>handleChange('recipients', e.target.value)}
                />

                <Form.Control.Feedback type={'invalid'}>
                   At least one valid email is required.
               </Form.Control.Feedback>

                <Form.Text className={"text-muted"}>
                Separate multiple addresses with commas
              </Form.Text>

              </Col>
            </Form.Group>

            {/* CC Recipients */}
            <Form.Group as={Row}  className={"mb-2"} controlId="cc-recipients">
              <Col sm={2} className={"text-end"}>
                <Form.Label>CC:</Form.Label>
              </Col>

              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={inputs.cc_recipients}
                  onChange={e=>handleChange('cc_recipients', e.target.value)}
                />

                <Form.Text className={"text-muted"}>
                  Separate multiple addresses with commas
                </Form.Text>
              </Col>
            </Form.Group>

            {/* Subject */}
            <Form.Group as={Row} className={"mb-3"} controlId="subject">
              <Col sm={2} className={"text-end"} >
                <Form.Label>Subject:</Form.Label>
              </Col>

              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={inputs.subject}
                  onChange={e=>handleChange('subject', e.target.value)}
                />
              </Col>
            </Form.Group>

            {/* Message */}
            <Form.Group className={"mb-3"} controlId="messagee">
              <Form.Label>Message:</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                placeholder=""
                rows={3}
                value={inputs.message}
                onChange={e=>handleChange('message', e.target.value)}
              />
            </Form.Group>

            <div className={"p-3 pb-1 border border-secondary rounded text-xsmall"} >
              <h6>{'Your Selected ' + contextString()}</h6>

              {/* Column headings */}
              {columnsHeadings()}

              {records.map((record, index) =>{
                switch (cardContext) {
                  case Constants.BIBLIOGRAPHY:
                    return bibliographyInfo(record, index)

                  case Constants.COLLECTION:
                    return collectionInfo(record, index)

                  case Constants.SEARCH:
                    return searchHistoryInfo(record, index)

                  default:
                    // Constants.DOCUMENT & Viewer
                    return documentInfo(record, index)
                }
              })}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <ReCAPTCHA
            className={'recaptcha'}
            sitekey={recaptchaKey()}
            ref={captchaRef}
            onChange={captchaChange}
          />
          <p
            className={'recaptcha-error text-danger'}
            ref={captchaErrorRef}
          >
            * A successful reCAPTCHA is required.
          </p>

          <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
          <Button variant="primary" type={"submit"}>Send</Button>
        </Modal.Footer>
      </Form>

    </Modal>
  )
}
export default EmailModal;
