import * as Constants from "../../../constants";
import METADATA from "../../../assets/data/search.meta.json";
import {ISearchState} from "../../../types/redux/search";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";

const useInsertDBFields = (): { insertField: Function } => {
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);

  const insertField = (str:string):{str:string, fs: string} =>{
    let fs: string = str;

    const terms: RegExpMatchArray | null = str.match(/(?:[^\s"]+|"[^"]*")+/g);
    let fieldSearches: string[] = [];
    if (terms) {
      fieldSearches = terms.filter((s:string)=>s.indexOf(':') !== -1);
    }

    if (fieldSearches.length > 0 ) {
      let fieldData: any;
      let fieldDataReverse: any;
      switch (searchState.db_set) {

        case Constants.BIBLIOGRAPHY:
          fieldData = METADATA.biblioAllFields;
          fieldDataReverse = METADATA.biblioAllFields_reverse;
          break;

        default:
          //Constants.DOCUMENTS:
          fieldData = METADATA.allFields;
          fieldDataReverse = METADATA.allFields_reverse;
          break;
      }

      fieldSearches.forEach(f => {
        const semicolonIndex: number = f.indexOf(":");
        const field: string = f.substring(0, semicolonIndex)
        let data: string = fieldData[field];
        if (data && data[1]) {
          /* field abbreviation found */
          str = str.replace(f, data[1] + f.substring(semicolonIndex, f.length));
          fs = fs.replace(f, '<span>' + data[1] + '</span>' + f.substring(semicolonIndex, f.length));
        } else {
          data = fieldDataReverse[field];
          if (data) {
            /* full field name entered */
            fs = fs.replace(f, '<span>' + field + '</span>' + f.substring(semicolonIndex, f.length));
          }
        }
      })
    }

    return {
      str,
      fs
    }
  }

  return {
    insertField
  }
};

export default useInsertDBFields;
