import './styles.scss';
import React from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import Utils from "../../../utils";

/* Bootstrap */
import {Col, Container, Row} from "react-bootstrap";

/* Redux */
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {IViewerState} from "../../../types/redux/viewer";

/* Components */
import DocumentSearch from "./document-search";
import HidePanel from "./hide-panel";
import PageNavigation from "./page-navigation";
import Zoom from "./zoom";
import ViewerMode from "./viewer-mode";
import FullscreenButton from "./fullscreen-button";
import RotationButton from "./rotation-button";

interface IProps {
  activeRecordTool: string;
  fileType: string | undefined;
}

const ViewerToolbar = (props: IProps):JSX.Element => {
  const {
    activeRecordTool,
    fileType
  } = props;
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);

  return (
    <>
      {/* Mobile */}


      {/* Desktop */}
      <Container className={'d-none d-lg-block viewer-toolbar pt-1 px-0 pb-0 bg-light border-bottom z-0 position-relative rounded-md-top'}>
        <Row>
          <Col lg={2} >
            <p className={'text-normal ps-3 mt-2 pt-1'}><strong>Document Tools</strong></p>
          </Col>

          <Col lg={3}>
            {!viewerState.panelCollapsed &&
              <h6 className={'mt-2 pt-1'}><b>{Utils.toTitleCase(activeRecordTool)}</b></h6>}
          </Col>

          <Col className={'tools d-inline-flex ps-0 pt-1'}>
          <span className={'me-2'}>
             <DocumentSearch />
          </span>

            <RotationButton />
            <FullscreenButton />
            {((fileType === Constants.PDF) || (fileType === Constants.VIDEO)) &&
              <span className={'me-5'}>
              <Zoom />
            </span>
            }
            <span className={'ms-3'}>
            <PageNavigation />
          </span>

            {(fileType === Constants.PDF) &&
              <div className={'d-inline-flex justify-content-between me-2'}>
                <HidePanel />
              </div>
            }

            {(fileType === Constants.PDF) &&
              <ViewerMode />
            }
          </Col>

        </Row>
      </Container>
    </>

  )
}
export default ViewerToolbar;
