import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface IProps {
  elemRef: any
}

export default function ScrollToTop(props: IProps): any {
  const {
    elemRef
  } = props;

  const { pathname } = useLocation();

  useEffect(() => {
    if (elemRef.current) {
      elemRef.current.scrollTop = 0;
    }
  }, [pathname, elemRef]);

  return null;
}
