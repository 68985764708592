import * as Constants from "../constants/search";
import {ISearchState} from "../types/redux/search";
import {useSelector} from "react-redux";
import {RootState} from "../redux";

import SearchUtils from "../utils/search-utils";

export interface ISearchParams {
  currentSearchParams(): URLSearchParams;
}

const useSearchParams = (): ISearchParams => {
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const currentSearchParams = (): URLSearchParams => {
  const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
        searchParams.set(Constants.INDUSTRY_KEY, searchState.industry);
        searchParams.set(Constants.QUERY_KEY, SearchUtils.searchQueryToString(searchState.query));
        searchParams.set(Constants.DB_SET_KEY, searchState.db_set ? searchState.db_set : 'null');
        searchParams.set(Constants.SORT_KEY, searchState.sort);
        searchParams.set(Constants.PAGE_KEY, String(searchState.current_page));
        searchParams.set(Constants.RESULTS_PER_PAGE_KEY, String(searchState.results_per_page));
  return searchParams;
  }

  return {
    currentSearchParams
  }
};

export default useSearchParams;