import React, { useEffect } from "react";
import store from "./index";
import {setContentCategories, setContentIndustries, setContentPages} from "./slices/content-slice";
import {Category, Industry} from "../api/payload/payload-types";
import {fetchDocs} from "../api/payload/fetchDocs";
import {CATEGORIES_CONFIG_KEY, INDUSTRIES_CONFIG_KEY} from "../constants";
import {fetchCategories} from "../api/payload/fetchCategories";

interface IProps {
  children: React.ReactNode;
}

export default function StoreInitializeProvider(props: IProps) {
  const {
    children
  } = props;

  useEffect(() => {
    const industriesCategories = async ():Promise<any> => {
      const categories: Category[] = await fetchDocs<Category>(CATEGORIES_CONFIG_KEY);
      const industries: Industry[] = await fetchDocs<Industry>(INDUSTRIES_CONFIG_KEY);
      const parentContentPages: any = await fetchCategories({industry: industries.map(i=>i.id)});
      return {
        categories,
        industries,
        parentContentPages
      }
    }

    industriesCategories().then(r => {
      store.dispatch(setContentCategories(r.categories));
      store.dispatch(setContentIndustries(r.industries));
      store.dispatch(setContentPages(r.parentContentPages));
    });

  }, []);

  return <>{children}</>
}
