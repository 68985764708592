import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import {Industry} from "../../../../api/payload/payload-types";
import Utils from "../../../../utils";

/* Bootstrap */
import {Button, Col, Row} from "react-bootstrap";

/* Redux */
import {IContentState, INewsFilter} from "../../../../types/redux/content";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux";

/* Components */
import Layout from "../payload-web/Layout";
import Spinner from "../../../../components/util/spinner";
import PostLayout from "../payload-web/PostLayout";
import FilterSidebar from "./filter-sidebar";

/* Hooks */
import { fetchDoc } from "../../../../api/payload/fetchDoc";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {useLocation, useNavigate} from "react-router-dom";

/* API*/
import {fetchPostByIndustry} from "../../../../api/payload/fetchPostByIndustry";
import { useQuery } from 'react-query';
import Paging from "../paging";
import NewsPost from "./post";
import {addCrumb} from "../../../../redux/slices/breadcrumb-slice";
import {AnyAction} from "@reduxjs/toolkit";

const NewsView = ():JSX.Element => {
  const { REACT_APP_PROD_HOST} = process.env;

  const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
  const token: string | null = searchParams.get(Constants.TOKEN);
  const [id, setId] = useState(searchParams.get(Constants.ID) ? searchParams.get(Constants.ID): Utils.parseNewsArticleId());

  const collection: string = (window.location.host === REACT_APP_PROD_HOST) || token ? Constants.POSTS : Constants.STAGE_POSTS;
  const [newsPost, setNewsPost] = useState<any[] | null>(null);

  const [metaData, setMetaData] = useState<any| null>({page:1, totalPages:0});
  const [page, setPage] = useState(metaData.page);

  const filters: INewsFilter[] = useSelector<RootState, IContentState>((state: RootState):any => state.content).news_filters;
  const industries: Industry[] = useSelector<RootState, IContentState>((state: RootState):any => state.content).industries;

  /* Hooks */
  const navigate:NavigateFunction = useNavigate();
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const location = useLocation();

  /* Post */
  const {
    refetch: postRefetch
  } = useQuery<any, Error>(
    ['post' + id],
    async ({signal}) => {
      if (!id) return;

      const doc: any = token ? await fetchDoc(collection, id, true,  token) : await fetchDoc(collection, id);
      setNewsPost([doc]);

      dispatch(addCrumb({
        label: Utils.toTitleCase(doc.title),
        href: location.pathname + '/' + doc.id,
        level: 2,
        active: true
      }));

    },
    {cacheTime: Infinity, staleTime: Infinity}
  );

  /* Feed */
  const {
    refetch: feedRefetch
  } = useQuery<any, Error>(
    ['news-feed_' + page],
    async ({signal}) => {
      const selectedIndustries: INewsFilter[] = filters.filter(nf => nf.selected);

      const industriesIds: string[] =  selectedIndustries.map(f => {
        return industries.filter(i=>{
          return Utils.convertToKeyOrId(i.title) === Utils.convertToKeyOrId(f.label)
        })[0]?.id
      });

      const response: any = await fetchPostByIndustry({industries: industriesIds, collection, page});
      setNewsPost(response?.docs);

      setMetaData({
        totalDocs: response.totalDocs,
        limit: response.limit,
        totalPages: (response.totalDocs === 0) ? 0 : response.totalPages,
      });

      dispatch(addCrumb({
        label: Utils.toTitleCase(Constants.NEWS),
        href: '/' + Constants.NEWS.toLowerCase() +  '/',
        level: 1,
        active: true
      }));

    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: false
    }
  );

  useEffect(()=>{
    const id: string = searchParams.get(Constants.ID) ? searchParams.get(Constants.ID): Utils.parseNewsArticleId();
    setId(id);
    // eslint-disable-next-line
  }, [window.location.href, searchParams])

  useEffect(()=> {
    if (id) {
      postRefetch();
    } else if (industries && (industries.length > 0)) {
      feedRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, id, industries, filters]);

  return (
    <div className={'news-view'}>
      {!id &&
        <Row className={'mt-4 flex-nowrap me-4'}>
          <Col sm={2} className={'mt-4'}>
            <FilterSidebar />
          </Col>

          <Col>
            <div className={'w-100 d-flex justify-content-end mb-4'}>
              <Paging page={!metaData.totalDocs?0:page} totalPages={metaData.totalPages} setPage={setPage}/>
            </div>

            <div className={'bg-white pt-5 pb-3 px-5'}>
              {(!newsPost) && <div className={'text-center'}>
                <Spinner size={Constants.MEDIUM}/>
              </div>}

              {newsPost && <div className={'posts'}>
                {newsPost.map((post: any, index: number) => <NewsPost post={post} index={index} key={index}/>)}
              </div>}
            </div>

            <div className={'w-100 d-flex justify-content-end mt-4'}>
              <Paging page={!metaData.totalDocs?0:page} totalPages={metaData.totalPages} setPage={setPage}/>
            </div>
          </Col>
        </Row>}

      {id && newsPost && (newsPost.length) &&
        <div className={'bg-white mt-3'}>
          <Row className={'btn-container ms-4 pt-4'}>
            <Button
              className={'back-btn border-0 btn-icon-only text-dark'}
              onClick={()=>navigate('/news')}
            >
              <img  src={'/assets/images/arrow-circle-left.svg'} alt="" height="30"/>
              <div className={'label ms-1 d-inline position-relative'}>Back to <strong>{Constants.NEWS}</strong></div>
            </Button>
          </Row>

          <Row>
            <Col className={'article-content'}>
              <PostLayout post={newsPost[0]}/>
              <Layout layout={newsPost[0].layout}/>
            </Col>
          </Row>
        </div>}
      </div>
  )
}

export default NewsView;
