import * as Constants from "../constants";
import {AxiosResponse} from "axios";
import {
  solrStageApiBibliographyClient,
  solrStageApiDocumentClient
} from "./axios";

const documentCountsQuery = async (db_type: string, signal: AbortSignal | undefined):Promise<any> => {
  let query: string;
  let response: AxiosResponse<any, any> | null;
  switch (db_type) {
    case Constants.COLLECTION:
      query = `q=*:*&rows=0&wt=json&facet=true&facet.field=collection_facet&facet.limit=-1`
      response = await solrStageApiDocumentClient.get<any>(
        "select?" + query,
        {signal});
      break;

    case Constants.BIBLIOGRAPHY:
      query = `q=*:*&rows=0&wt=json&facet=true&facet.field=industry_facet&facet.limit=-1`
      response = await solrStageApiBibliographyClient.get<any>(
        'select?' + query,
        {signal});
      break;

    case Constants.INDUSTRIES_DOCUMENTS:
      query = `q=published:true&rows=0&wt=json&facet=true&facet.field=industry_facet&facet.limit=-1`
      response = await solrStageApiDocumentClient.get<any>(
        'select?' + query,
        {signal});
      break;

    case Constants.INDUSTRIES_PAGES:
      query = `q=*:*&rows=0&stats=true&stats.field=pg&stats.facet=industry_facet&facet.limit=-1`
      response = await solrStageApiDocumentClient.get<any>(
        'select?' + query,
        {signal});
      break;

case Constants.VIDEO:
      query = `q=type:video AND access:internet*&wt=json&start=0&rows=0&facet=true&facet.field=industry_facet&facet.limit=-1`
      response = await solrStageApiDocumentClient.get<any>(
        'select?' + query,
        {signal});
      break;

    default:
      response = null;
  }

  if (response && response.data) {
    return response.data;
  }
}

const latestReleaseQuery = async (signal: AbortSignal | undefined):Promise<any> => {
  let query: string = `q=published:true&fl=ddu&rows=1&wt=json&sort=ddudatelatest%20desc`;
  let response: AxiosResponse<any, any> | null = await solrStageApiDocumentClient.get<any>(
    "select?" + query,
    {signal});
  return response.data;
}

const ContentService = {
  documentCountsQuery,
  latestReleaseQuery
}

export default ContentService;
