import './styles.scss';
import React, {useEffect} from "react";

import { useLivePreview } from '@payloadcms/live-preview-react'
import Layout from "../payload-web/Layout";

const Preview = ():JSX.Element => {
  const {
    REACT_APP_LOCALHOST,
    REACT_APP_LOCAL_CMS_HOST,
    REACT_APP_CMS_HOST} = process.env;

  /* @ts-ignore */
  const apiURL: string = (window.location.host === REACT_APP_LOCALHOST) ? REACT_APP_LOCAL_CMS_HOST : REACT_APP_CMS_HOST;

  const data:any = useLivePreview({
    serverURL: apiURL || '',
    depth: 2,
    initialData: null,
  })

  useEffect(()=> {
    const header:HTMLElement = document.getElementsByTagName('header')[0];
    header.style.display = 'none';
  });

  const preview = (): JSX.Element=> {
    if (!data.data) return <></>
    return (
      <div className={'preview p-4'}>
{/*        <p>{format(new Date(data.data.updatedAt), 'EEEE, MMMM do, yyyy')}</p>
        <h4>{data.data.title}</h4>*/}
        <Layout layout={data.data.layout}/>
      </div>
    )
  }

  return (
    <div className={'preview-view'}>
      {data && !data.isLoading && preview()}
    </div>
  )
}

export default Preview;
