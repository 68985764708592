/* Types, Constants, Utils */
import * as Constants from "../../constants";
import {ALL_INDUSTRIES, TQueryItem} from "../../constants";
import {ISearchDate, ISearchState} from "../../types/redux/search";

/* todo: legacy remove */
import {Cookies} from '../../lib/cookies';

/* Data */
import METADATA from './../../assets/data/search.meta.json';
import SORTS from "./../../assets/data/sorts.json";
import {TSorts} from "../../types/search";
import {filters} from "./filters";
import SearchUtils from "../../utils/search-utils";

/*
 * this section of date code is different than transformDatesInQuery()
 * processQueryDates() reforms dates that are in the query itself.
 * this block of code inserts date queries from the date pickers.
 */
/*

const GET_PUBLISHED_FQ = ()=> {
  const fq: string = "&fq=published:true";  //default is only show published
  // todo: jain - set filter
 /!* let publishedOnly: string = PUBLISHED_ONLY.toLowerCase();
  if (publishedOnly.charAt(0) === 'f') {// if user passes in f, then remove the fq
    fq = "";
  }*!/
  return fq;
}
*/

const mapDateField = (field:string, dbSet: string): string | null =>{
  let fieldDataReverse: any;
  let allFields: any;
  switch (dbSet) {
    case Constants.DOCUMENTS:
      fieldDataReverse = METADATA.allFields_reverse;
      allFields = METADATA.allFields;
      break;

    case Constants.BIBLIOGRAPHY:
      fieldDataReverse = METADATA.biblioAllFields_reverse;
      allFields = METADATA.biblioAllFields;
      break;
  }

  const reverseLookup: any = fieldDataReverse;
  let resolvedField: string = null;
  const fieldLookup: string | undefined = reverseLookup[field];
  if (fieldLookup) {
    const terms: string = allFields[fieldLookup[0]];
    if (terms){
      const term: string = terms[0];
      if (term) {
        let value: string[];
        for (let key in  allFields) {
          value = allFields[key];
          if ((value[0] === term) && (value.length === 1)){
            resolvedField = key
          }
        }
      }
    }
  }

  return resolvedField
}

const convertDateToSolr = (date:string):string => {
  const parts: string[] = date.split('/')
  for (let i=0; i<parts.length; i++){
    if (parts[i].length === 1) {
      parts[i] = '0' + parts[i];
    }
  }
  return parts[2] + '-' + parts[0] + '-' + parts[1] + 'T00\\:00\\:00Z'
}

const getDates =(search: ISearchState): string =>{
  return search.dates.map((date: ISearchDate) => {
    /* @ts-ignore */
    return date.field + ':[' + convertDateToSolr(date.from) + ' TO ' + convertDateToSolr(date.to) + ']'
  }).join(' OR ');
}

export const processQuery = (search: ISearchState, isFacetQuery: boolean): string[] => {
  const searchQuery: TQueryItem[] = search.query;
  searchQuery.forEach((q:TQueryItem, i:number) => {
    /* map date field to solr fields */
    if (q.term.indexOf(Constants.DATE) !== -1) {
      let fieldSplit: string[] = q.term.split(':');
      if (fieldSplit.length > 1) {
        const mapping: string | null = mapDateField(fieldSplit[0], search.db_set);
        if (mapping) {
          const date: string = fieldSplit[1];
          const dateFormatted: string = date.substring(0,4) + '-' + date.substring(4,6) + '-' + date.substring(6,8) + 'T00\\:00\\:00Z';
          q.term = mapping + ':' + dateFormatted;
        }
      }
    }
  })

  let query: string = SearchUtils.convertSearchQuery(searchQuery);

  // for “ or ”
  let regex: RegExp = new RegExp("(“|”)", "gi");
  query = query.replace(regex, "\"");

  //expand bates query, replace the old "bn/bates" field code
  regex = new RegExp("(bn|bates):", "gi");
  query = query.replace(regex, "pgmap:");
  if (query.indexOf("pgmap:*") > -1) {
    throw new Error(Constants.SLOW_QUERY_ERROR);
  }

  //allow a range request to use lowercase 'to'
  query = query.replace(" to ", " TO ");

  //allow words to create a boolean search-cmp on a non-boolean field
  regex = new RegExp("(cited:yes)", "gi");
  query = query.replace(regex, "cited:*");

  regex = new RegExp("(cited:no)", "gi");
  query = query.replace(regex, " -cited:* ");

  /* results per page*/
  const resultsPerPage: number = search.results_per_page;

  /* search-paging */
  const currentPage: number = search.current_page;
  const startRow: number = ((currentPage-1)*resultsPerPage);

  /* Sort By */
  const availableSorts: TSorts = SORTS as TSorts;
  /* @ts-ignore */
  const sorts: ISort[] = availableSorts[Constants.INDUSTRY];
  const sortValue: string | undefined = sorts.find(sort=>sort.id === search.sort)?.value;
  let resultsSortOrder: string = "&sort=" + sorts[0].value; // default
  if (sortValue) {
    resultsSortOrder = "&sort=" + sortValue;
  }

  /*
   * construct the query segment that will request solr highlighting.
   * currently we are only allowing solr to highlight the "original text" and "bates-number" field
   * all other highlighting is happening post query on the client
   */
  let highlightQuery: string = "&hl=true";
  highlightQuery += "&hl.simple.pre=%3Ch1%3E";
  highlightQuery += "&hl.simple.post=%3C%2Fh1%3E";
  highlightQuery += "&hl.requireFieldMatch=true";
  highlightQuery += "&hl.preserveMulti=true";

  //to allow solr to highlight other fields just add the field code here
  let highlightFields: string[] = ["ot", "bn"];
  for(let i = 0; i < highlightFields.length; i++){
    highlightQuery += "&hl.fl="+ highlightFields[i];
    highlightQuery += "&f."+ highlightFields[i] +".hl.fragsize=6000";
    highlightQuery += "&f."+ highlightFields[i] +".hl.alternateField="+ highlightFields[i];
    highlightQuery += "&f."+ highlightFields[i] +".hl.maxAlternateFieldLength=300";
  }

  //add a request to solr for it to return facet grouping for this query
  let facetGroupingQuery: string = "";
  for (let index = 0; index < METADATA["facetGroups"].length; index++){
    if(search.industry !== ALL_INDUSTRIES) {  // if we are on a particular industries site, then we don't want the industries facet
      if(METADATA["facetGroups"][index][0] !== "industry"){
        facetGroupingQuery += "&facet.field=" + METADATA["facetGroups"][index][0];
      }
    } else {
      facetGroupingQuery += "&facet.field=" + METADATA["facetGroups"][index][0];
    }
  }

  /*
   * compile the selected facet portion of the query
   */
  let currentFacets: any[] = [];
  const savedCurrentFacets: string | null = Cookies.getItem("iddl_current_facets");
  if(savedCurrentFacets){
    currentFacets = JSON.parse(savedCurrentFacets);
  }

  let facetQuery: any[] = [];
  for(let i = 0; i < currentFacets.length; i++){
    const group: any = currentFacets[i][0];
    const facet: any = currentFacets[i][1];

    for(let j = 0; j < METADATA["facetGroups"].length; j++){
      if (METADATA["facetGroups"][j][0] === group){
        facetQuery.push(group +":\""+ facet +"\"");
      }
    }
  }

  let facetQueryStr: string = '';
  if(facetQuery.length > 0){
    facetQueryStr =  "&fq="+ encodeURIComponent("("+ facetQuery.join(" AND ") +")");
  }

  let currentIndustry: string = "";
  if (search.industry !== Constants.ALL_INDUSTRIES) {
    currentIndustry = "&fq=industrycode:"+ search.industry.replace('-', '');
  }

  /*
   * Compose the full solr query
   */

  //+"&facet.limit=-1"//uncommenting this line will give you ALL facets.
  //+"&facet.pivot=industries,collection_facet"//industries collection, commented out for better performance

  // todo: jain - this legacy needs refactoring once api solidify
  let fullQuery: string = '';
  let dbSet: string | null = search.db_set;
  if (!dbSet) {
    dbSet = Constants.DOCUMENTS;
  }

  let dateRange: string = getDates(search);
  if (dateRange.length > 0) {
    dateRange =  "&fq=" + encodeURIComponent('(' + dateRange + ')'); // + ' OR (*:* AND -dddate:*)';
  }

  const dateDecadesFacet: string = "&indent=true&facet.range=dddate&f.dddate.facet.range.other=before&f.dddate.facet.range.other=after&f.dddate.facet.range.start=1950-01-01T00:00:00.000Z&f.dddate.facet.range.end=NOW&f.dddate.facet.range.gap=%2B10YEARS";
  // const dateYearsFacet: string = "&indent=true&q=*%3A*&rows=0&facet=true&facet.range=dddate&f.dddate.facet.range.other=before&f.dddate.facet.range.other=after&f.dddate.facet.range.start=1950-01-01T00:00:00.000Z&f.dddate.facet.range.end=1960-01-01T00:00:00.000Z&f.dddate.facet.range.gap=%2B1YEARS"

  switch (dbSet) {
    case Constants.DOCUMENTS:
      query = "q=" + encodeURIComponent(query)
        + dateRange
        + "&wt=json"
        + "&start="+ startRow
        + "&rows="+ (isFacetQuery ? "0" : resultsPerPage)
        + "&fq=published:true"
        + highlightQuery
        + resultsSortOrder
        + currentIndustry;

      if (search.applied_filters.length > 0) {
        query += filters(search.applied_filters);
      }

      if (isFacetQuery) {
        query+= "&facet=true"
              + "&facet.mincount=0"
              + "&facet.pivot=availability_facet,availabilitystatus_facet"
              + "&facet.pivot=industry_facet,collection_facet"
              + facetGroupingQuery
              + dateDecadesFacet
      }

      fullQuery = (query + facetQueryStr);
      break;

    case Constants.BIBLIOGRAPHY:
      query = "q=" + encodeURIComponent(query)
        +"&wt=json"
        +"&start="+ startRow
        +"&rows="+ (isFacetQuery ? "0" : resultsPerPage)
        + "&sort=score desc,dateaddediso desc,id asc"
        + currentIndustry

     if (isFacetQuery) {
        query +="&facet=true"
        + "&facet.mincount=0"
        + "&facet.field=industry_facet"
        + "&facet.field=status_facet"
        + "&facet.field=category_facet"
        + "&facet.field=referencetype_facet"
      }

      fullQuery = query;
      break;

    case Constants.COLLECTIONS:
      if (isFacetQuery) {
        query = "q=" + encodeURIComponent('*:*')
          +"&rows=0"
          +"&indent=true"
          +"&q.op=OR"
          +"&facet=true"
          +"&facet.field=collection_facet"
          +"&facet.pivot=industry_facet,collection_facet"
          +"&facet.sort=index"
          + currentIndustry
      } else {
        query = "q=" + encodeURIComponent(query)
          + "&wt=json"
          + "&start=" + startRow
          + "&rows="+  resultsPerPage
          + "&fq=industries:" + search.industry.toUpperCase();
      }
      fullQuery = query;
      break;
  }

  return [fullQuery, query];
}

