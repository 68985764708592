import * as Constants from "../constants";
import {AxiosResponse} from "axios";
import {idlAPIClient} from "./axios";

const ApiUtils = {
  getToken:  ():string => {
    const token: string | null = sessionStorage.getItem(Constants.SESSION_KEY_CSRF);
    if (!token) {
      const error: any = new Error();
      error.message = 'Not logged in';
      error.status = 403;
      throw error;
    }
    return token
  },

  makePut: async (data: any, url: string):Promise<any> => {
    const token: string = ApiUtils.getToken();
    if (token) {
      const response: AxiosResponse<any, any> = await idlAPIClient.put<any>(
        url,
        data,
        {headers: {"x-csrf-token": token}});
      return response.data;
    }
  },

  makePatch: async (data: any, url: string):Promise<any> => {
    const token: string = ApiUtils.getToken();
    if (token) {
      const response: AxiosResponse<any, any> = await idlAPIClient.patch<any>(
        url,
        data,
        {headers: {"x-csrf-token": token}});
      return response.data;
    }
  },

  makePost: async (data: any, url: string):Promise<any> => {
    const token: string = ApiUtils.getToken();
    if (token) {
      const response: AxiosResponse<any, any> = await idlAPIClient.post<any>(
        url,
        data,
        {headers: {"x-csrf-token": token}});
      return response.data;
    }
  },

  doDelete:  async (params: any, url: string):Promise<any> => {
    const token: string = ApiUtils.getToken();
    if (token) {
      const response: AxiosResponse<any, any> = await idlAPIClient.delete<any>(
        url,
        {
          params,
          headers: {"x-csrf-token": token}
        }
      );
      return response.data;
    }
  }
}

export default ApiUtils;