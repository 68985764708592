import {ISearchStateResultMeta} from "../search";

export interface ISelected {
  id: string;
  index: number;
}

export interface IResultsState {
  results: any[];
  expand_all: boolean;
  selected: ISelected[];
  initial_selection: string | null;
  searchResultMeta: ISearchStateResultMeta | null;
  facet_fields: any;
  facet_ranges: any;
  facet_pivot: any;
}
export const UpdateResultAction: string = "update-results-action";