import './styles.scss';
import React, {Dispatch} from "react";

/* Types, Constants, Utils*/
import * as Constants from "../../constants";
import {IIndustryMenuItem} from "./interface";

/* Bootstrap */
import {Nav} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {ISearchState} from "../../types/redux/search";
import {RootState} from "../../redux";
import {setIndustry} from "../../redux/slices/search-slice";

import {AnyAction} from "@reduxjs/toolkit";
import {useNavigate} from "react-router-dom";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {INDUSTRY_ITEMS} from "../../constants";

/* Data */
const IndustryMenu = ():JSX.Element => {
  /* Redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);

  /* Hooks */
  const navigate:NavigateFunction = useNavigate();

  const handleItemClick = (e: React.MouseEvent<HTMLInputElement>, industry: string):void => {
    e.preventDefault();
    dispatch(setIndustry(industry));

    navigate('/' + industry + '/' + Constants.DOCUMENTS + '/')
  }

  return (
    <Nav className={'industry-menu flex-wrap pt-3'}>
      {INDUSTRY_ITEMS.map((item:IIndustryMenuItem, index:number) => {
        const active: boolean = (searchState.industry === item.industry);
        return (
          <Nav.Item
            key={"inb-item"+index}
            className={'p-0 ' + (active ? item.industry + "-select active" : "")}>
            <Nav.Link
              className={"bg-white btn mx-0" + (active ? "text-light " : "text-dark ")}
              onMouseUp={(e:React.MouseEvent<HTMLInputElement>)=>handleItemClick(e, item.industry)}
              eventKey={index}
            >
              {item.icon &&
                <img className={"icon"} alt={''} src={'/assets/images/'  + item.icon} />}
              <h3 className={'text-black'}><strong>{item.label}</strong></h3>

            </Nav.Link>
          </Nav.Item>
         )
      })}
    </Nav>
  )
}

export default IndustryMenu;
