import './styles.scss';
import React from "react";

/*
  size = {'small', 'medium'}
 */
interface IProps {
  size: string
}

const Spinner = (props: IProps):JSX.Element => {
  const numOfSplines: number = 12;
  return (
    <div className={'lds-spinner ' + props.size} >
      {[...Array(numOfSplines)].map((e, i) => <div key={"lds"+i}/>)}
    </div>
  )
}
export default Spinner;
