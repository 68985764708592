import './styles.scss';
import React, {Dispatch} from "react";

/* Bootstrap */
import {Button, Container, Modal} from "react-bootstrap";
import {setLoginModalActive} from "../../../redux/slices/login-modal-slice";
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";

interface IProps {
  title: string;
  show: boolean;
  onHide(): void;
}

const LoginPromptModal = (props: IProps):JSX.Element => {
  const {
    title,
    show,
    onHide
  } = props;

  const dispatch:Dispatch<AnyAction> = useDispatch();

  const handleConfirm = (): void => {
    dispatch(setLoginModalActive(true));
    onHide();
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop={'static'}
      keyboard={false}
      centered
      className={"login-prompt-modal"}
    >
      <Modal.Header closeButton >
        <Modal.Title className={'fs-4'}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <h5>You must be logged in for this feature. Log in now?</h5>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
        <Button variant="primary" onClick={handleConfirm}>Log In or Register</Button>
      </Modal.Footer>
    </Modal>
  )
}
export default LoginPromptModal;
