import  './styles.scss'

import React, {useEffect, useState} from 'react'
import {Col, Container, Dropdown, Form, InputGroup, Row} from 'react-bootstrap';
import {useQuery} from "react-query";
import ContentTables from "../../../../../api/content-tables";
import TABLES_METADATA from "../../../../../assets/data/tables.meta.json";
import Paging from "../../paging";
import * as Constants from "../../../../../constants";
import SearchButton from "../../../../../components/search/search-input/search-button";
import {Link} from "react-router-dom";

interface IDropDownItem {
  label: string,
  value: string
}

export const SearchableTable: React.FC<
  {
    fields: any,
    id?: string
  }
> = props => {
  const [api, setApi] = useState<string>(props.fields.api.trim())
  const tableStructure: any[] = TABLES_METADATA[api.split('/')[0]];
  const [columnHeaders, setColumnHeaders] = useState<string[]>([]);
  const [columnFields, setColumnFields] = useState<string[]>([]);
  const [content, setContent] = useState<any>(null);
  // eslint-disable-next-line
  const [metaData, setMetaData] = useState<any>(null);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<IDropDownItem>({label:'hi', value: ''});
  const [searchInput, setSearchInput] = useState<string>('')

  const getSort =  ():IDropDownItem[]=>{
    if (!tableStructure) return [];

    const sortItems: IDropDownItem[] = [];

    tableStructure.forEach((field:any) => {
      let value: string = field.field.toLowerCase() + '_sort';
      if (value === 'date_sort') {
        value = 'dateiso';
      }

      sortItems.push({
        label: field.label + ' (Ascending)',
        value: value + ' asc'
        },
        {
          label: field.label + ' (Descending)',
          value: value + ' desc'
        })
    });

    return sortItems
  }
  const SEARCHABLE_TABLES_SORT: IDropDownItem[] = getSort();

  const {data, isSuccess,  refetch} = useQuery<any, Error>(
    [api],
    async ({signal}) => {
      return await ContentTables.solrContentTableQuery(api, signal)
    },
    {
      staleTime: Infinity
    }
  );

  const parseHeaders = (key: string):string[] => {
    const returnValue: string[] = [];
    tableStructure.forEach((entry: any) => {
      returnValue.push(entry[key])
    })
    return returnValue
  }

  const sortItem = (value:string): IDropDownItem =>{
    return SEARCHABLE_TABLES_SORT.find(item => item.value === value)
  }

  const searchParams = (): URLSearchParams => {
    const apiSplit: string[] = api.split('?');
    return new URLSearchParams(apiSplit[1]);
  }

  useEffect(()=>{
    setSort(sortItem(searchParams().get(Constants.SORT)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const parseFacets = (fields: (string | number)[]): any => {
    const docs: any[] = [];
    for (let i=0; i < fields.length; i++) {
      docs.push({
        email:fields[i],
        num_email:fields[i+1],
        link: <a href={'https://www.industrydocuments.ucsf.edu'}>Search:{fields[i]}</a> /* todo: make actual link */
      });
      i++;
    }
    return {docs, start:0}
  }

  useEffect(()=> {
    if (!isSuccess || !data) return;

    setColumnHeaders(parseHeaders(Constants.LABEL));
    setColumnFields(parseHeaders(Constants.FIELD));
    setMetaData(data.responseHeader);

    if (data?.facet_counts?.facet_fields?.sender_facet) {
      setContent(parseFacets(data.facet_counts.facet_fields.sender_facet))
    } else {
      setContent(data.response);
    }

    setPage(data.response.start + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(()=> {
    if (!sort) return;
    
    const params:URLSearchParams = searchParams()
    params.set(Constants.SORT, sort.value);
    setApi(api.substring(0, api.indexOf('?') + 1) + params.toString().replaceAll(' ', '+'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  useEffect(()=>{
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  useEffect(()=> {
    const params:URLSearchParams = searchParams();
    params.set(Constants.START, String(page - 1));
    setApi(api.substring(0, api.indexOf('?') + 1) + params.toString().replaceAll(' ', '+'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSubmit = ():void =>{
    const params:URLSearchParams = searchParams();
    if (searchInput.length === 0) {
      params.set('q', '*');
    } else {
      params.set('q', searchInput);
    }
    setApi(api.substring(0, api.indexOf('?') + 1) + params.toString().replaceAll(' ', '+'));
  }

  const hasQuery = ():boolean =>{
    return searchInput.length > 0;
  }

  return (
    <div className={'searchable-table'}>
      <Container className={'search-input p-0'}>
        <Row className={'mx-5 mb-4'}>
          <Container className={'p-0'}>
            <InputGroup
              className={'border rounded'}
            >
              <Form.Control
                className={'search-input-field border-0 bg-white'}
                placeholder={'Search'}
                aria-label={'Search'}
                aria-describedby={'search-input'}
                value={searchInput}

                onChange={e=> {
                  setSearchInput(e.target.value);
                }}

                onKeyDown={e=>{
                  if (e.code === Constants.ENTER) {
                    e.preventDefault();
                    handleSubmit()
                  }
                }}
              />

              <SearchButton
                disabled={!hasQuery()}
                handleSubmit={handleSubmit}/>
            </InputGroup>
          </Container>
        </Row>

        <Row className={'how-to-search mx-5 mb-4 text-normal text-center'}>
          <Link className={'text-decoration-none'} to={'www.google.com'}>
            <span className={'me-1'}>
              <img src={"/assets/images/info-fill.svg"} alt={'info-icon'}/>
            </span>
            <span>How to Search</span>
          </Link>
        </Row>
      </Container>

      <div className={'d-flex justify-content-end mx-5 mb-2 bg-grey-1 border p-2'}>
        <Dropdown
          onSelect={(eventKey: string | null)=>setSort(sortItem(eventKey))}
          className={'px-4'}
        >
          <Dropdown.Toggle className={"btn-transparent border-0 rounded-0 h-100 px-0 text-black text-small"} id="sort-dropdown">
            <span><strong>Sort by:</strong>&nbsp;</span>
            <span className={'me-1'}>{sort?.label}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {SEARCHABLE_TABLES_SORT.map((item: IDropDownItem, i: number) =>{
              return <Dropdown.Item
                key={i}
                href={''}
                eventKey={item.value}
              >{item.label}</Dropdown.Item>
            })}
          </Dropdown.Menu>
        </Dropdown>

        <Paging page={page} totalPages={content?.numFound } setPage={setPage}/>
      </div>

      <div className={'table px-5'}>
        <Container className={'border'}>
          <Row className={'table-headers bg-grey-1 border-bottom py-2'}>
            <Col className={'num-col text-normal'}/>
            {columnHeaders.map((header:string, i: number) =>
              <Col className={'col-header p-3 text-normal text-break'} key={i}><strong>{header}</strong></Col>)
            }
          </Row>

          <div className={'scroll-container'}>
            {content && content.docs.map((entry: any, i: number) => {
              return (<Row className={'border-bottom py-3'} key={i}>
                <Col className={'num-col py-2'}><strong>{content.start + i + 1}</strong></Col>

                {columnFields.map((field:string, i:number)=>{
                  // @ts-ignore
                  return <Col className={'py-2 text-break '} key={i} dangerouslySetInnerHTML={{__html: '<div>'+entry[field]+'</div>'}}/>
                })}
              </Row>)
            })}
          </div>
        </Container>
      </div>
    </div>
  )
}
