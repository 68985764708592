import './styles.scss';
import React, {useMemo} from "react";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {useNavigate} from "react-router-dom";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";

/* Bootstrap */
import {ISearchState} from "../../../../types/redux/search";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import LinkButton from "../../../button-link";

const BackSearch = ():JSX.Element => {
  const searchParams: URLSearchParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const navigate:NavigateFunction = useNavigate();
  const searchState: ISearchState = useSelector<RootState, ISearchState>((state: RootState):any => state.search);

  const handleClick = ()=> {
    if (searchParams.get(Constants.INDUSTRY)) {
      navigate('/' + searchState.industry + '/documents/?' + searchParams)
    } else {
      /* arrive on viewer via document or permalink */
      navigate('/' + Constants.HOME + '?' + searchParams)
    }
  }

  return(
    <div className={'back-search'}>
      <LinkButton
        label={'Back to search'}
        underline={false}
        linkArrowReverse={true}
        clickFunc={handleClick}
      />
    </div>
  )
}

export default BackSearch;
