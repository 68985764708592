import './styles.scss';
import React, {Dispatch, useEffect} from "react";
import { Container, Form} from "react-bootstrap";

import * as Constants from "../../../../../constants";
import {IContentState, INewsFilter} from "../../../../../types/redux/content";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux";
import {AnyAction} from "@reduxjs/toolkit";
import {setNewsFilters} from "../../../../../redux/slices/content-slice";

const FilterSidebar = ():JSX.Element => {
  const filters: INewsFilter[] = useSelector<RootState, IContentState>((state: RootState):any => state.content).news_filters;
  const dispatch:Dispatch<AnyAction> = useDispatch();

  const setAllFilters = (value: boolean): void =>{
    let updatedFilters: INewsFilter[] = [...filters].map((i: INewsFilter) =>{
      const item: INewsFilter = {...i}
      item.selected = value;
      return item
    });
    dispatch(setNewsFilters(updatedFilters))
  }

  useEffect(()=>{
    setAllFilters(true)
    // eslint-disable-next-line
  }, [])

  const handleToggle = (e:any, industry: string): void => {
    const updatedFilters: INewsFilter[] = [...filters].map(i=>{return {...i}});
    const allIndustryFilter: INewsFilter = updatedFilters.filter(i=>i.industry === Constants.ALL_INDUSTRIES)[0];

    if (industry === Constants.ALL_INDUSTRIES) {
      setAllFilters(!allIndustryFilter.selected)
    } else {
      allIndustryFilter.selected = false;
      const filter: INewsFilter = updatedFilters.filter(i=> i.industry === industry)[0]
      filter.selected = !filter.selected;

      const allSelected: boolean =  updatedFilters.filter(i=>{
        return ((i.industry !== Constants.ALL_INDUSTRIES) && (i.selected))
      }).length === (updatedFilters.length - 1);

      if (allSelected) {
        setAllFilters(!allIndustryFilter.selected)
      } else {
        dispatch(setNewsFilters(updatedFilters))
      }
    }
  }

  const processLabel = (item:any): string =>{
    let value: string = item.label;
    if (item.industry === Constants.ALL_INDUSTRIES) {
      if (item.selected) {
        value = '( Unselect All )'
      } else {
        value = '( Select All )'
      }
    }
    return value;
  }

  return (
    <div className={'filter-sidebar'}>
      <p className={'text-normal'}><strong>Filter by</strong></p>

      <Container className={'ms-3 p-0'}>
        <Form.Group key={'industries_fg'}>
          {filters.map((item: any, index: number) => {
            const label: string = processLabel(item);
            return <Form.Check
              key={index+ '_check'}
              className={'text-larger my-3'}
              type={'checkbox'}
              label={
                <div
                  role={'button'}
                  className={'text-smaller mt-1 d-inline-block'}
                  onClick={e=>handleToggle(e, item.industry)}
                >
                  {label}
                </div>
              }
              checked={item.selected}
              onChange={()=>{}}
              onClick={e=>handleToggle(e, item.industry)}
            />
          })}
        </Form.Group>
      </Container>
    </div>

  )
}

export default FilterSidebar;
