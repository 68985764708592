
import {PAGES_QUERY, STAGE_PAGES_QUERY} from './_graphql/pages'
import {POSTS_QUERY, STAGE_POSTS_QUERY} from './_graphql/posts'
import * as Constants from "../../constants";
import {CATEGORIES_QUERY} from "./_graphql/categories";
import {INDUSTRIES_QUERY} from "./_graphql/industries";

const {
  REACT_APP_LOCALHOST,
  REACT_APP_LOCAL_CMS_HOST,
  REACT_APP_CMS_HOST} = process.env;

const queryMap = {
  industries: {
    query: INDUSTRIES_QUERY,
    key: Constants.INDUSTRIES,
  },
  categories: {
    query: CATEGORIES_QUERY,
    key: Constants.CATEGORIES,
  },
  pages: {
    query: PAGES_QUERY,
    key: Constants.PAGES,
  },
  posts: {
    query: POSTS_QUERY,
    key: Constants.POSTS,
  },
  stagePages: {
    query: STAGE_PAGES_QUERY,
    key: Constants.STAGE_PAGES,
  },
  stagePosts: {
    query: STAGE_POSTS_QUERY,
    key: Constants.STAGE_POSTS,
  }
}

export const fetchDocs = async <T>(
  collection: string,
  draft?: boolean,
  token?: string | null,
): Promise<T[]> => {
  // @ts-ignore
  if (!queryMap[collection]) throw new Error(`Collection ${collection} not found`)
  /* @ts-ignore */
  const apiURL: string = (window.location.host === REACT_APP_LOCALHOST) ? REACT_APP_LOCAL_CMS_HOST : REACT_APP_CMS_HOST;
  const docs: T[] = await fetch(`${apiURL}/api/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // @ts-ignore
      ...(token && draft ? { Authorization: `JWT ${token}` } : {}),
    },
    cache: 'no-store',
    // @ts-ignore
    body: JSON.stringify({
      // @ts-ignore
      query: queryMap[collection].query
    }),
  })
    ?.then(res => res.json())
    ?.then(res => {
      if (res.errors) throw new Error(res?.errors?.[0]?.message ?? 'Error fetching docs')

      const key =
        String(collection).split(' ')
        .map(w => w[0].toUpperCase() + w.substring(1))
        .join(' ');
      return res?.data?.[key]?.docs
    })

  return docs
}
