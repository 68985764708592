import * as Constants from "./index";
import {IIndustryMenuItem} from "../components/industry-menu/interface";

export const EMPTY_SEARCH: string         = '*:*';

export const DEFAULT_SEARCH: TQueryItem = {
  operator: null,
  field: 'all',
  match: 'contains',
  term: EMPTY_SEARCH
}

export const MY_LIBRARY_EMPTY_SEARCH: TQueryItem = {
  operator: null,
  field: 'id',
  match: 'contains',
  term: null
}

export const ID: string                   = 'id';
export const INITIAL_ID: string           = 'iid';
export const QUERY_KEY: string            = 'q';
export const FILTERS_KEY: string          = 'f';
export const DATES_KEY: string            = 'dt';
export const DB_SET_KEY: string           = 'db-set';
export const INDUSTRY_KEY: string         = 'industry';
export const SORT_KEY: string             = 'sort';
export const PAGE_KEY: string             = 'pg';
export const RESULTS_PER_PAGE_KEY: string = 'npp';
export const ADV_KEY: string              = 'adv';
export const DEFAULT_SORT                 = 'relevance';
export const HIDDEN_KEY: string           = 'hd';

export const NO_RECENT: string                = 'No recent searches';
export const DEFAULT_RESULT_PER_PAGE: number  =  20;
export const MAX_NUM_RESULTS: number          =  10000;

export const PLACEHOLDER_DOCUMENTS: string    = "Search Documents";
export const PLACEHOLDER_COLLECTIONS: string  = "Search Collections";
export const PLACEHOLDER_BIBLIOGRAPHY: string = "Search Publications";

export const ABSTRACT: string         = 'abstract';
export const CITED_DOCUMENTS: string  = 'cited-documents';

export const DATE_SAVED_OLDEST: string         = 'date-saved-oldest';
export const DATE_SAVED_NEWEST: string         = 'date-saved-newest';

export const HOME_SEARCH_MENU_ITEMS = [
  {
    label: Constants.DOCUMENTS_TITLE,
    db_set: Constants.DOCUMENTS
  },
  {
    label: Constants.BIBLIOGRAPHY_TITLE,
    db_set: Constants.BIBLIOGRAPHY
  }
]

export type TQueryItem = {
  operator: string | null;
  field: string | null;
  match: string | null;
  term:  string | null;
}

/* from filters.json */
export const CHECKBOX: string   = 'checkbox';
export const SWITCH: string     = 'switch';
export const DATES: string      = 'dates';
export const DATE: string       = 'date';
export const DATE_FIELD: string       = 'dddate';

export const QUERY: string      = 'query';
export const PREPEND: string    = 'prepend';
export const APPEND: string     = 'append';


export const ABOVE: string    = 'above';
export const BELOW: string    = 'below';

export const DATE_FACET_START_KEY: string = 'f.dddate.facet.range.start';
export const DATE_FACET_END_KEY: string   = 'f.dddate.facet.range.end'

export const DEFAULT_DATE_YEARS_SOLR: {
  [key:string]: string | string[]
} = {
  'q':'*:*',
  'rows':'0',
  'facet.range':'dddate',
  'indent':'true',
  'facet':'true',
  'f.dddate.facet.range.other':['before','after'],
  'f.dddate.facet.range.start':'1950-01-01T00:00:00.000Z',
  'f.dddate.facet.range.end':'2024-01-01T00:00:00.000Z',
  'f.dddate.facet.range.gap':'+1YEARS'
}
export const SOLR_DOC_PATH: string = '/ltdl3/';

export const INDUSTRY_ITEMS: Array<IIndustryMenuItem> = [
  {
    label: Constants.ALL_INDUSTRIES_TITLE,
    icon: '388676_IDL-ALL.svg',
    industry: Constants.ALL_INDUSTRIES
  },
  {
    label: Constants.TOBACCO_TITLE,
    icon: '388676_IDL-Tobacco.svg',
    industry: Constants.TOBACCO
  },
  {
    label: Constants.OPIOIDS_TITLE,
    icon: '388676_IDL-OPIODS.svg',
    industry: Constants.OPIOIDS
  },
  {
    label: Constants.CHEMICAL_TITLE,
    icon: '388676_IDL-CHEM.svg',
    industry: Constants.CHEMICAL
  },
  {
    label: Constants.DRUG_TITLE,
    icon: '388676_IDL-Drug.svg',
    industry: Constants.DRUG
  },
  {
    label: Constants.FOOD_TITLE,
    icon: '388676_IDL-FOOD.svg',
    industry: Constants.FOOD
  },
  {
    label: Constants.FOSSIL_FUEL_TITLE,
    icon: '388676_IDL-FossilFuel.svg',
    industry: Constants.FOSSIL_FUEL
  }
]
