import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Location} from "@remix-run/router";
import {NavigateFunction} from "react-router/dist/lib/hooks";

/* Types, Constants, Utils*/
import * as Constants from "../../../constants";

const NoPathMatch = ():JSX.Element => {
  const location:Location = useLocation();
  const navigate:NavigateFunction = useNavigate();

  /* todo: jain - handle legacy links https://www.industrydocuments.ucsf.edu/tobacco/docs/#id=yfkk0281 */

  useEffect(()=> {
    const handlePermalink = (): void=> {
      const pathnameParts: string[] = location.pathname.split('/');
      if (pathnameParts[1] === Constants.DOCS) {
        const recordId: string = pathnameParts[2];
        navigate(`/${pathnameParts[1]}/documents/${Constants.VIEWER}/?${Constants.ID}=${recordId}`)
      }
    }

    if (location.pathname.includes(`/${Constants.DOCS}/`)) {
      handlePermalink();
    } else{
      navigate(`/${Constants.HOME}`)
    }

  }, [location.pathname, navigate])

  return <></>
}
export default NoPathMatch;
