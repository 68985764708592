import './styles.scss';
import React, {useContext, useRef, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import Utils from "../../../../utils";

/* Bootstrap */
import Form from 'react-bootstrap/Form';
import {Dropdown} from "react-bootstrap";
import {ConstraintDropdownContext, IConstraintDropdown} from "../../../../types/search";

interface IProps {
  constraint: string;
  selected: string
}

const ConstraintDropdown = (props: IProps):JSX.Element => {
  const {
    constraint,
    selected
  } = props;

  const {line, updateConstraints} = useContext<IConstraintDropdown>(ConstraintDropdownContext);
  const values: any[] = Constants.ADV_SEARCH_DROPDOWNS[constraint];

  const [fieldSearchInput, setFieldSearchInput] = useState<string>('');
  const [fieldSearchSelected, setFieldSearchSelected] = useState<HTMLElement | null>(null);
  const fieldSearchScrollRef = useRef<HTMLInputElement | null>(null);

  const handleInputChange = (e:any): void => {
    if (!fieldSearchScrollRef.current) return;

    if (fieldSearchSelected) {
      fieldSearchSelected.classList.remove('search-select')
    }

    const searchTerm = e.target.value;
    setFieldSearchInput(searchTerm);

    if (searchTerm.length > 0) {
      /* Scroll to any matching starting substring */
      const filteredItems = Constants.ADV_SEARCH_DROPDOWNS.field_search.filter((term: any) =>
        term.label.substring(0, searchTerm.length).toLowerCase() === searchTerm.toLowerCase()
      );

      if (filteredItems.length > 0) {
        const element: HTMLElement | null = document.getElementById(filteredItems[0].value);
        if (element) {
          element.classList.add('search-select');
          setFieldSearchSelected(element);

          fieldSearchScrollRef.current.scrollTo({
            top: element.offsetTop - fieldSearchScrollRef.current.offsetTop,
            behavior: 'smooth',
          });
        }
      }
    } else {
      /* Empty field scroll to top */
      fieldSearchScrollRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  const reset = ():void => {
    setFieldSearchInput('');
    if (fieldSearchSelected && fieldSearchScrollRef.current) {
      fieldSearchScrollRef.current.scrollTo({top: -1000});
      fieldSearchSelected.classList.remove('search-select');
      setFieldSearchSelected(null)
    }
  }

  return (
    <Dropdown
      className={'constraint-dropdown'}
      onSelect={(eventKey: string | null)=>updateConstraints(line, constraint, eventKey)}
      onToggle={()=>reset()}
    >
      <Dropdown.Toggle
        className={'constraint-toggle w-100 text-start bg-transparent pb-1 ms-1 ps-2 border-0'}
        variant={'light'}
      >
        {selected}
        <div className={'underline'}/>
      </Dropdown.Toggle>

      <Dropdown.Menu className={'constraint-menu text-start shadow'}>
        {/* Search Fields */}
        {(constraint === Constants.FIELD) &&
          <>
            <div className={'search-field px-2 text-nowrap pb-2'}>
              <i className={'bi bi-search me-2 py-1'}/>
              <Form.Control
                className={'border-0 rounded d-inline-block p-1'}
                type={'text'}
                aria-label={'term'}
                aria-describedby={'term-input'}
                placeholder={'Search'}
                value={fieldSearchInput}
                onChange={e=>handleInputChange(e)}
                onClick={e=>e.stopPropagation()}
              />
            </div>
            <Dropdown.Divider className={'m-0'}/>
          </>
        }

        {/* Fields */}
        <div
          ref={fieldSearchScrollRef}
          className={'scroll-container overflow-scroll'}
        >
          {/* Most used fields */}
          {values.map((entry: any) => {
            return (
              <Dropdown.Item
                key={Utils.convertToKeyOrId(entry.value)}
                href={''}
                eventKey={entry.value}
              >
                {entry.label}
              </Dropdown.Item>
            )
          })}

          {/* All fields */}
          {(constraint === Constants.FIELD) &&
            <>
              <Dropdown.Divider className={'my-1'}/>

              {Constants.ADV_SEARCH_DROPDOWNS[Constants.FIELD_SEARCH].map((entry: any) => {
                return (
                  <Dropdown.Item
                    key={Utils.convertToKeyOrId(entry.value)+'_srch'}
                    id={entry.value}
                    href={''}
                    eventKey={entry.value}
                  >
                    {entry.label}
                  </Dropdown.Item>
                )
              })}
            </>
          }
        </div>

      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ConstraintDropdown;
