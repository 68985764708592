import './styles.scss';
import React, {Dispatch} from "react";

/* Bootstrap */
import {Button, ButtonGroup} from "react-bootstrap";
import {setExpandAll} from "../../../redux/slices/result-slice";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {IResultsState} from "../../../types/redux/result";
import {AnyAction} from "@reduxjs/toolkit";

const ExpandAll = ():JSX.Element => {
  const resultsState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const dispatch:Dispatch<AnyAction> = useDispatch();

  return (
    <ButtonGroup className={'expand-btn-group'}>
      <Button
        className={"btn-sm btn-transparent text-grey border border-0 text-nowrap text-smaller"}
        onClick={()=>dispatch(setExpandAll(!resultsState.expand_all))}
        >
        {!resultsState.expand_all &&
            <b>Expand all results</b>}

        {resultsState.expand_all &&
            <b>Collapse all results</b>}
        <i className={"ms-2 bi " + (resultsState.expand_all ? "bi-chevron-bar-contract" : "bi-chevron-bar-expand")}></i>
      </Button>
    </ButtonGroup>
  )
}

export default ExpandAll;
