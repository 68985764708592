import './styles.scss';
import React, {useContext, useEffect, useRef} from "react";

/* types, constants, utils */
import * as Constants from "../../../../../constants";
import {IViewerRecordContext, ViewerRecordContext} from "../../../../../types/document";

/* bootstrap */
import {Button} from "react-bootstrap";

/* api */
import {useQuery} from "react-query";
import SearchService from "../../../../../api/search";

/* components */
import ViewerCard from "../../../viewer-card";
import Spinner from "../../../../util/spinner";
import {BrowseQuery} from "../../../../../api/browse.js";
import {BatesQuery} from "../../../../../api/bates.js";

interface IProps {
  context: string;
  recordId: string | null;
  handleSelect(record: any): void
  setNumOfRecords(value:number): void;
}

const ViewerBatesBrowseList = (props: IProps):JSX.Element => {
  const {
    context,
    recordId,
    handleSelect,
    setNumOfRecords
  } = props;

  const initialRecord: any = useContext<IViewerRecordContext>(ViewerRecordContext).initialRecord;
  const prevResultsContainer = useRef<HTMLInputElement | null>(null);
  const resultsContainer = useRef<HTMLInputElement | null>(null);

  /* Previous */
  const {
    isSuccess: prevIsSuccess,
    isLoading: prevIsLoading,
    data: prevData,
  } = useQuery<any, Error>(
    [context + '_prev_' + recordId],
    async ({signal}) => {
      let query: string;
      if (context === Constants.BROWSE) {
        query = BrowseQuery(initialRecord.collectioncode, Constants.VW_NUM_ROWS, Constants.PREV, initialRecord.id_int);
      } else {
        query = BatesQuery(initialRecord.collectioncode, Constants.VW_NUM_ROWS, Constants.PREV, initialRecord.bn);
      }
      return await SearchService.solrQuery(query, signal)
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  useEffect(()=>{
    if (prevResultsContainer.current && resultsContainer.current) {
      resultsContainer.current.scrollTop = prevResultsContainer.current.clientHeight;
    }
  }, [prevResultsContainer, prevData, resultsContainer])

  /* Next */
  const {
    isSuccess: nextIsSuccess,
    isLoading: nextIsLoading,
    data: nextData
  } = useQuery<any, Error>(
    [context + '_next_' + recordId],
    async ({signal}) => {
      let query: string;
      if (context === Constants.BROWSE) {
        query = BrowseQuery(initialRecord.collectioncode, Constants.VW_NUM_ROWS, Constants.NEXT, initialRecord.id_int);
      } else {
        query = BatesQuery(initialRecord.collectioncode, Constants.VW_NUM_ROWS, Constants.NEXT, initialRecord.bn);
      }
      return await SearchService.solrQuery(query, signal)
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );

  useEffect(()=>{
    if (nextIsSuccess && prevIsSuccess) {
      setNumOfRecords(nextData.response.numFound + prevData.response.numFound)
    }
  }, [nextData, prevData, nextIsSuccess, prevIsSuccess, setNumOfRecords])

  const card = (result: any, index: number):JSX.Element => {
    return <Button
      key={`dc-${index}`}
      className={'viewer-card-wrapper mb-2 text-dark p-0 bg-transparent text-start w-100'}
      active={initialRecord && (initialRecord.id === result.id)}
      onClick={()=>handleSelect(result)}
    >
      <ViewerCard
        index={index}
        record={result}
        page={1}
        resultsPerPage={Constants.VW_NUM_ROWS}
      />
    </Button>
  }

  return (
    <div className={'viewer-bates-list overflow-scroll pt-1'} ref={resultsContainer}>
      <div className={'cards d-flex flex-column pb-2'} >

        {/* Loading Spinner */}
        {prevIsLoading &&
          <div className={'text-center pt-4'}>
            <Spinner size={Constants.SMALL}/>
          </div>}

        {/* Document Cards */}

        {/* previous */}
        <div className={'prev-container'} ref={prevResultsContainer}>
          {prevData && prevData.response.docs.map((result: any, index: number) => {
            return card(result, index - prevData.response.docs.length - 1)
          })}
        </div>

        {/* initial card */}
        {card(initialRecord, -1)}

        {/* next */}
        {nextData && nextData.response.docs.map((result: any, index: number) => {
          return card(result, index)
        })}

        {nextIsLoading &&
          <div className={'text-center pt-4'}>
            <Spinner size={Constants.SMALL}/>
          </div>}
      </div>
    </div>
  )
}
export default ViewerBatesBrowseList;
