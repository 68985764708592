import './styles.scss';
import React, {Dispatch, useRef, useState} from "react";

/* Types, Constants, Utils*/
import * as Constants from "../../../constants";

/* Bootstrap */
import {Button, Form, Row} from "react-bootstrap";

/* Redux */
import {useDispatch} from "react-redux";
import {AnyAction} from "@reduxjs/toolkit";
import {addToast} from "../../../redux/slices/toast-slice";

/* Third party */
import ReCAPTCHA from "react-google-recaptcha"

/* Component */
import EmailService from "../../../api/send";


interface IEmailInputs {
  subject: string;
  name: string;
  email: string;
  message: string;
}

const AskUsEmail = ():JSX.Element => {
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const [validated, setValidated] = useState(false);

  const captchaRef = useRef<any | null>(null);
  const captchaErrorRef = useRef<HTMLInputElement | null>(null);

  const recaptchaKey = (): string => {
    let siteKey = '6LdGAs0fAAAAAIoDWeaapLYTVmGAh-oqpc0rx151'; //default to localhost
    if (window.location.host.indexOf('libckm.org') !== -1) {
      siteKey = '6LcXfEYpAAAAACzzi39TuyvX4OC86whrNCnUmsqV';
    }
    return siteKey
  }

  const [inputs, setInputs] = useState<IEmailInputs>({
    subject: '',
    name: '',
    email: '',
    message: ''
  });

  const captchaChange = (event: any):void => {
    if (captchaRef.current && captchaErrorRef.current) {
      captchaErrorRef.current.classList.remove('invalid');
    }
  }

  const handleSubmit = (event: any):void => {
    event.preventDefault();
    event.stopPropagation();

    if (captchaRef.current && captchaErrorRef.current) {
      captchaErrorRef.current.classList.remove('invalid');
      /* @ts-ignore */
      const token = captchaRef.current.getValue();
      /* todo: backend should validate this token https://blog.logrocket.com/implement-recaptcha-react-application/ */
      if (!token) {
        captchaErrorRef.current.classList.add('invalid');
        /* @ts-ignore */
        captchaRef.current.reset();
        return;
      }
    }

    const form = event.currentTarget;
    if (form.checkValidity()) {
      EmailService.emailDocument({
        sendTo: '',
        cc: '',
        isHtml: false,
        messageText: inputs.message,
        subjectText: inputs.subject
      }).then(()=>{
        //props.onHide();
        /* notify user of success */
        dispatch(addToast({
          bg: 'bg-success',
          msg: Constants.EMAIL_SENT,
          id: -1
        }))
      }).catch(e=>{
        console.log('error in sending email: ' + e.message)
        // todo: jain - handle error
      })
    }
    setValidated(true);
  };

  const handleChange = (field: string, value: string | boolean):void => {
    const inputsCln: IEmailInputs = {...inputs};
    // @ts-ignore
    inputsCln[field] = value;
    setInputs(inputsCln);
  };

  return (
    <div className={'ask-us-email'} >
      <h2>Email</h2>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className={''}>
          {/* Subject */}
          <Form.Group as={Row} className={"mb-3"} controlId="subject">
            <span className={''} >
              <Form.Label>Subject:</Form.Label>
            </span>

            <span>
              <Form.Control
                type="text"
                placeholder=""
                value={inputs.subject}
                onChange={e=>handleChange('subject', e.target.value)}
              />
            </span>
          </Form.Group>

          {/* Name */}
          <Form.Group as={Row} className={"mb-3"} controlId="name">
            <span className={''} >
              <Form.Label>Name:</Form.Label>
            </span>

            <span>
              <Form.Control
                  type="text"
                  placeholder=""
                  value={inputs.name}
                  onChange={e=>handleChange('name', e.target.value)}
              />
            </span>
          </Form.Group>

          {/* Email */}
          <Form.Group as={Row} className={"mb-3"} controlId="email">
            <span className={''} >
              <Form.Label>Email:</Form.Label>
            </span>

            <span>
              <Form.Control
                  type={'email'}
                  placeholder={''}
                  value={inputs.email}
                  onChange={e=>handleChange('email', e.target.value)}
              />
            </span>
          </Form.Group>

          {/* Message */}
          <Form.Group className={"mb-3"} controlId="messagee">
            <Form.Label>Message:</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              placeholder=""
              rows={3}
              value={inputs.message}
              onChange={e=>handleChange('message', e.target.value)}
            />
          </Form.Group>
        </div>

        <div>
          <ReCAPTCHA
            className={'recaptcha'}
            sitekey={recaptchaKey()}
            ref={captchaRef}
            onChange={captchaChange}
          />
          <p className={'recaptcha-error text-danger'} ref={captchaErrorRef}>
            * A successful reCAPTCHA is required.
          </p>

          <div className={'text-end'}>
            <Button className={'px-4'} variant="secondary" onClick={()=>{}}>Cancel</Button>
            <Button className={'px-4 ms-2'} variant="primary" type={"submit"}>Send</Button>
          </div>

        </div>
      </Form>
    </div>
  )
}

export default AskUsEmail;
