import {createAxiosInstance, solrApiBibliographyClient, solrApiCollectionClient, solrApiDocumentClient} from "./axios";
import {AxiosInstance, AxiosResponse} from "axios";
import {ISearchState} from "../types/redux/search";
import * as QueryHelpers from "./legacy-helpers/query";
import {SingleDocumentQuery} from "./document.js";
import {queryMultipleRecords} from "./legacy-helpers/query-multiple";

import * as Constants from "../constants";

/* todo: jain - refactor w/ generalized approach and refactored processQuery */
const solrQuery = async (queryStr: string, signal: any):Promise<any> => {
  const response:AxiosResponse<any, any> = await solrApiDocumentClient.get<any>(
    "select?"+ queryStr,
    {
      signal
    });
  return response.data;
}

const solrMultipleDocumentQuery = async (searchState: ISearchState,signal: AbortSignal | undefined):Promise<any> => {
  const response:AxiosResponse<any, any> = await solrApiDocumentClient.get<any>(
    "select?" + queryMultipleRecords(searchState, Constants.DOCUMENT),
    {
      signal
    });
  return response.data;
}

const solrSingleDocumentQuery = async (id: string, signal: AbortSignal | undefined):Promise<any> => {
  const response:AxiosResponse<any, any> = await solrApiDocumentClient.get<any>(
    "document?" + SingleDocumentQuery(id),
    {
      signal
    });
  return response.data;
}

const solrDocumentQuery = async (search: ISearchState, signal: AbortSignal | undefined, isFacetQuery: boolean = false):Promise<any> => {
  const response:AxiosResponse<any, any> = await solrApiDocumentClient.get<any>(
    "select?"+ QueryHelpers.processQuery(search, isFacetQuery)[1],
    {
      signal
    });
  return response.data;
}

const solrDuplicatesQuery = async (signature: string, docId: string, signal: AbortSignal | undefined, isFacetQuery: boolean = false):Promise<any> => {
  const query = "q=(signature:" + signature + " AND NOT id:" + docId +  ")"
    + "&wt=json"
    + "&fl=au,dt,id,tid,availability,pg,attach,access"
    + "&indent=true"
    + "&start=0"
    + "&rows=20"
    + "&fq=published:true";

  const response:AxiosResponse<any, any> = await solrApiDocumentClient.get<any>(
    "select?" + query,
    {
      signal
    });
  return response.data;
}

const solrMultipleBibliographyQuery = async (searchState: ISearchState, signal: AbortSignal | undefined):Promise<any> => {
    const response:AxiosResponse<any, any> = await solrApiBibliographyClient.get<any>(
        "select?" + queryMultipleRecords(searchState, Constants.BIBLIOGRAPHY),
        {
            signal
        });
    return response.data;
}

const solrBibliographyQuery = async (search: ISearchState, signal: AbortSignal | undefined, isFacetQuery: boolean = false):Promise<any> => {
  const response:AxiosResponse<any, any> = await solrApiBibliographyClient.get<any>(
    "select?"+ QueryHelpers.processQuery(search, isFacetQuery)[1]);
  return response.data;
}

const solrCollectionQuery = async (search: ISearchState, signal: AbortSignal | undefined, isFacetQuery: boolean = false):Promise<any> => {
  if (isFacetQuery) {
    const response:AxiosResponse<any, any> = await solrApiDocumentClient.get<any>(
      "select?"+ QueryHelpers.processQuery(search, isFacetQuery)[1]);
    return response.data;
  } else {
    const response:AxiosResponse<any, any> = await solrApiCollectionClient.get<any>(
      "select?"+ QueryHelpers.processQuery(search, isFacetQuery)[1]);
    return response.data;
  }
}

const solrEmptyResult = async ():Promise<any> => {
  return new Promise((resolve, reject) => {
    const response: any = {}
          response.num_found = 0;
          response.count = 0;
          response.docs = []
    resolve({response})
  });
}

const solrDateQuery = async (params: any, signal: AbortSignal | undefined):Promise<any> => {
  const axiosInstance: AxiosInstance = createAxiosInstance(params, Constants.SOLR_DOC_PATH)
  const response:AxiosResponse<any, any> = await axiosInstance.get<any>(
    "select",
    {
      signal
    });
  return response.data;
}

const SearchService = {
  solrQuery,
  solrSingleDocumentQuery,
  solrMultipleDocumentQuery,
  solrDocumentQuery,
  solrBibliographyQuery,
  solrMultipleBibliographyQuery,
  solrCollectionQuery,
  solrDateQuery,
  solrEmptyResult,
  solrDuplicatesQuery
}

export default SearchService;
