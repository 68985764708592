import './styles.scss';
import React from 'react'

export const IFrameBlock: React.FC<
  {
    configs: any
  }
> = props => {
  const {
    configs
  } = props

  const getDimensions = (widthStr: string, heightStr: string): {width: number, height: number} => {
    const widthInViewDim: number = 69;
    let width:number = widthInViewDim;
    switch (widthStr) {
      case 'twoThirds':
        width *= .6667;
        break;

      case 'half':
        width *= .5;
        break;

      case 'oneThird':
        width *= .3333;
        break;

      default:
      // 'full'
    }

    let height: number = width;
    switch (heightStr) {
      case 'wide':
        height *= .5628;
        break;

      case 'wideVertical':
        height *= 1.7778;
        break;

      case 'standard':
        height *= .75;
        break;

      default:
      // 'square'
    }

    return {
      width,
      height
    }
  }

  const dimensions: {
    width: number,
    height: number
  } = getDimensions(configs.width, configs.height);

  return  (
    <div className={'iframe-block w-100 my-2'}>
      <div className={'inner align-' + configs.alignment}>
        <div style={{width: dimensions.width +'vw', height: dimensions.height + 'vw'}} >
          {/* header */}
          {configs.frameTitle && <h4>{configs.frameTitle}</h4>}

          {/* iFrame */}
          <iframe
            title={configs.frameTitle}
            src={configs.sourceUrl}
            width={'100%'} height={'100%'}
            allow="fullscreen"/>
        </div>

        {/* footnote */}
        {configs.footnote &&
          <p
            style={{width: dimensions.width +'vw'}}
            className={'mt-1 text-smaller fst-italic'}
          >
            {configs.footnote}
          </p>}
      </div>
    </div>
  )
}
