/* jain: todo: Use generalize content search-paging and delete this component*/

import './styles.scss';
import React, {Dispatch, useCallback, useEffect, useRef, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import Utils from "../../../../utils";

/* Bootstrap */
import {Button, Form, Modal, Pagination} from "react-bootstrap";

/* redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import {ISearchState} from "../../../../types/redux/search";
import {setCurrentPage} from "../../../../redux/slices/search-slice";
import {IResultsState} from "../../../../types/redux/result";

interface IProps {
  previous?: string;
  next?:string;
  showPageInput?: boolean;
}

const SearchPaging = (props: IProps):JSX.Element => {
  const {
    previous,
    next,
    showPageInput = false,
  } = props;

  const dispatch:Dispatch<AnyAction> = useDispatch();
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const result: IResultsState = useSelector<RootState, IResultsState>(state => state.result);

  const [numOfPages, setNumOfPages] = useState<number>(0);
  const [pageInput, setPageInput] = useState<string>('');
  const inputRef = useRef(null);
  const [modalDisplayed, setModalDisplayed] = useState<boolean>(false);
  const [prevDisabled, setPrevDisabled] = useState<boolean>(false);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);

  /* todo: jain - on next page do not scroll the page!! */

  useEffect(()=>{
    setPrevDisabled((result.searchResultMeta === null) || (searchState.current_page === 1))
  }, [result.searchResultMeta, searchState.current_page])

  const lastPage = useCallback(():number =>{
    return Math.min(numOfPages, Constants.MAX_NUM_RESULTS/searchState.results_per_page);
  },[numOfPages, searchState.results_per_page]);

  useEffect(()=>{
    let disabled: boolean = (numOfPages === 0);
    if (lastPage() !== Constants.MAX_NUM_RESULTS/searchState.results_per_page) {
      disabled = (searchState.current_page === lastPage()) || (numOfPages === 0)
    }
    setNextDisabled(disabled)
  }, [lastPage, numOfPages, searchState.current_page, searchState.results_per_page])

  useEffect(() => {
    if (result.searchResultMeta === null) {
      return;
    }

    let _numOfPages: number = Math.floor(result.searchResultMeta.num_found/searchState.results_per_page);
    if (_numOfPages !== 0) {
      _numOfPages++
    }
    setNumOfPages(_numOfPages);

    /* todo: jain - asap, now broken with document scroll */
    let currentPage: number = searchState.current_page;
    const last: number = Math.min(_numOfPages, Constants.MAX_NUM_RESULTS/searchState.results_per_page);
    if ((last > 0 ) && (searchState.current_page  > last)) {
      // edge case: user selected display more per page exceeds max allowed results
      currentPage = last;
      dispatch(setCurrentPage(currentPage));
    }
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
    searchParams.set(Constants.PAGE_KEY, String(currentPage));

    setPageInput(String(currentPage));

  }, [searchState, dispatch, result.searchResultMeta, searchState.current_page])

  const handleKeydown = (e: any): void => {
    const pgNum: number = parseInt(pageInput);
    if (e.key === Constants.ENTER) {
      e.preventDefault();
      e.stopPropagation();

      if (pgNum > lastPage()) {
        setModalDisplayed(true)

      } else {
        dispatch(setCurrentPage(pgNum))
      }
    }
  }

  const handleFocus = (elem:any): void => {
    elem.setSelectionRange(0, elem.value.length)
    elem.classList.add("float-top")
  }

  const handleBlur = (elem:any): void =>{
    elem.classList.remove("float-top")
  }

  const handleNext = (): void => {
    if (searchState.current_page === lastPage()) {
      setModalDisplayed(true);
    } else {
      dispatch(setCurrentPage(searchState.current_page + 1))
    }
  }

  return <div className={"search-paging d-inline-flex justify-content-center"}>
    <Pagination size="sm" className={"m-0"}>
      {/* Previous */}
      {previous && <Pagination.Item
        disabled={prevDisabled}
        onClick={()=>dispatch(setCurrentPage(searchState.current_page - 1))}
      >
        <div className={'arrow arrow-up d-inline-block p-0 me-1' + (prevDisabled ? ' disabled':'')}/> {Utils.toTitleCase(previous)}
      </Pagination.Item>}

      {/* Page input */}
      {showPageInput && <li>
        <Form>
          <Form.Control
            ref={inputRef}
            className={"rounded-0 border-0 border-top border-bottom text-center p-1"}
            type={"text"}
            maxLength={3}
            value={pageInput}
            onChange={e=>setPageInput(e.target.value)}
            onKeyDown={e=>handleKeydown(e)}
            onFocus={e=>handleFocus(e.target)}
            onBlur={e=>handleBlur(e.target)}
          />
        </Form>
      </li>}

      {/* Next */}
      {next && <Pagination.Item
        onClick={()=>handleNext()}
        disabled={nextDisabled}
      >
        <div className={'arrow arrow-down d-inline-block p-0 me-1' + (nextDisabled ? ' disabled':'')}/> {Utils.toTitleCase(next)}
      </Pagination.Item>}
    </Pagination>

    {showPageInput &&
      <span className={"align-self-center mx-2 text-small hidden" + (numOfPages ? "hidden" : "")}>
        of {Utils.formatNumber(lastPage()) + ((numOfPages > lastPage()) ? '+' : '')} Pages
      </span>}

    {/* Page input exceeds max pages allowed */}
    <Modal show={modalDisplayed} onHide={()=>setModalDisplayed(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Search Invalid</Modal.Title>
      </Modal.Header>

      <Modal.Body className={"border-0"}>
        The page number inputted exceeds maximum allowed. Try refining the search or filtering the results.
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={()=>setModalDisplayed(false)}>
         Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
}
export default SearchPaging;
