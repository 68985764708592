import './styles.scss';
import React, {Dispatch, useCallback, useContext, useEffect, useState} from "react";
import Form from 'react-bootstrap/Form';
import * as Constants from "../../../constants";
import {IRecordsContext, RecordsContext} from "../../../types/document";
import useSaveSelected from "../../../hooks/useSaveSelected";
import {updateSavedDocuments} from "../../../redux/slices/saved-slice";
import SavedService from "../../../api/saved";
import {ISavedState, TSavedDocument} from "../../../types/redux/saved";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {AnyAction} from "@reduxjs/toolkit";
import {Button} from "react-bootstrap";
import {IUserState} from "../../../types/redux/user";
import LoginPromptModal from "../../modal/login-prompt-modal";
import Utils from "../../../utils";
import {addToast} from "../../../redux/slices/toast-slice";

interface IProps {
  context: string
}

const Note = (props: IProps):JSX.Element => {
  const {
    context
  } = props;

  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const savedState: ISavedState = useSelector<RootState, ISavedState>(state => state.saved);
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);

  /* context */
  const selectedRecords: any = useContext<IRecordsContext>(RecordsContext).records;

  /* state */
  /* @ts-ignore */
  const [content, setContent] = useState<string>('');
  const [pendingNote, setPendingNote] = useState<boolean>(false);
  const [loginPromptModal, setLoginPromptModal] = useState<boolean>(false);

  /* hooks */
  const saveSelected: Function = useSaveSelected();

  useEffect(()=> {
    /* update note for single document */
    if (context === Constants.DOCUMENT) {
      const savedDocumentData: TSavedDocument | undefined = savedState.documents.find((savedDocument: TSavedDocument) => savedDocument.documentId === selectedRecords[0].id)
      if (savedDocumentData && savedDocumentData.notes) {
        setContent(savedDocumentData.notes)
      }
    }
  }, [savedState.documents, context, selectedRecords])

  const saveNote = useCallback(async (): Promise<any> =>{
    if (!userState.loggedIn) {
      setPendingNote(true)
      setLoginPromptModal(true);
      return;
    }

    /* save any records not saved*/
    await saveSelected(selectedRecords);

    /* get full save record data */
    const savedDocumentData: TSavedDocument[] = [];
    selectedRecords.forEach((record:any) => {
      const savedDocument: TSavedDocument | undefined =  savedState.documents.find((savedDocument: TSavedDocument) => savedDocument.documentId === record.id);
      if (savedDocument) {
        savedDocumentData.push(savedDocument);
      }
    })

    if (savedDocumentData.length === 0) {
      // wasn't saved before creating a note, will get updated on useEffect above once redux registers the addition
      setPendingNote(true);
      return;
    }

    if (context === Constants.DOCUMENT) {
      /* from document card => replaces notes */
      const saveDocumentClone: TSavedDocument = {...savedDocumentData[0]};
      saveDocumentClone.notes = content;
      SavedService.setDocumentNote(saveDocumentClone).then(response=>{
        dispatch(updateSavedDocuments(response));
        dispatch(addToast({
          bg: 'bg-success',
          msg: 'Note saved.',
          id: -1
        }))
      })
    } else {
      /* bulk add note from toolbar ==> append note */
      const savedDocumentId: string[] = savedDocumentData.map(savedDocument => String(savedDocument.id));
      SavedService.appendDocumentNotes(savedDocumentId, content).then(response=>{
        dispatch(updateSavedDocuments(response))
        setContent('');
        dispatch(addToast({
          bg: 'bg-success',
          msg: 'Note saved.',
          id: -1
        }))
      })
    }
  }, [content, context, dispatch, saveSelected, savedState.documents, selectedRecords,  userState.loggedIn]);

  useEffect(()=>{
    if (userState.loggedIn && pendingNote){
      setPendingNote(false)
      saveNote();
    }
  }, [userState.loggedIn, pendingNote, saveNote])

  return ( <>
    <span className={'notes'}>
      <Form.Control
          className={'note-input text-small d-inline-block align-top'}
          as={'textarea'}
          type={'text'}
          placeholder={Constants.ADD_NOTE_PLACEHOLDER}
          rows={6}
          value={content}
          onChange={e=>setContent(e.target.value)}
      />
      <div className={'text-end mt-2'}>
        <Button
          className={'text-normal border-0 w-100 rounded-1' + (!userState.loggedIn ? ' dimmed' : '')}
          onClick={saveNote}
        >Save Notes
        </Button>
      </div>
    </span>

  {loginPromptModal && <LoginPromptModal
    title={'Tag ' + Utils.toTitleCase(Constants.DOCUMENT)}
    show={loginPromptModal}
    onHide={()=>setLoginPromptModal(false)}/>}
  </>)
}
export default Note;
