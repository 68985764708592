import './styles.scss';
import React, {useContext} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";

/* Redux */
import {ITagState, TSavedDocumentTag} from "../../../../types/redux/saved";

/* Bootstrap */
import {Button, Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import {ITagModalContext, TagsModalContext} from "../../../../types/saved";

interface IProps {
  tag: TSavedDocumentTag
}

const TagAddRemoveEdit = (props: IProps):JSX.Element => {
  const {
    tag
  } = props;
  const tagsState: ITagState = useSelector<RootState, ITagState>(state => state.tags);
  const tagsModalContext: ITagModalContext = useContext<ITagModalContext>(TagsModalContext);

  return (
    <div className={'tag-apply mt-2'}>
      <Row className={'px-0'}>
        {/* Tag */}
        <Col lg={9} className={'pe-3'}>
          <div
            className={'rounded ps-3 pe-1 d-flex justify-content-between'}
            style={{backgroundColor: tagsState.colorDiction[tag.id]}}
          >
            <span className={'name'}><b>{tag.text}</b></span>
            <i className={'bi bi-check text-dark h4 m-0'}/>
          </div>
        </Col>

        {/* Edit */}
        <Col lg={1} className={'p-0 text-center'}>
          <Button
            className={'btn-icon-only align-items-center'}
            onClick={e=> {
              tagsModalContext.setSelectedTag(tag)
              tagsModalContext.setModalState(Constants.EDIT_TAG)
            }}
          >
            <i className={'bi bi-pencil-square text-dark h5 m-0'}/>
          </Button>
        </Col>

        {/* Remove */}
        <Col lg={2} className={'text-center p-0'}>
          <Button
            className={'btn-icon-only'}
            onClick={e=>{
              tagsModalContext.setSelectedTag(tag)
              tagsModalContext.setModalState(Constants.REMOVE_TAG)
            }}
          >
           <i className={'bi bi-trash3 text-dark h5 m-0'}/>
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default TagAddRemoveEdit;
