import {combineReducers, configureStore, PreloadedState} from '@reduxjs/toolkit';
import searchReducer from './slices/search-slice';
import userReducer from './slices/user-slice';
import toastReducer from './slices/toast-slice';
import resultReducer from './slices/result-slice';
import savedReducer from './slices/saved-slice';
import breadcrumbReducer from './slices/breadcrumb-slice'
import viewerReducer from './slices/viewer-slice';
import loginModalReducer from './slices/login-modal-slice';
import myLibraryReducer from './slices/my-library-slice';
import tagsReducer from './slices/tags-notes-slice';
import contentReducer from './slices/content-slice';

const rootReducer = combineReducers({
  user:  userReducer,
  saved: savedReducer,
  search: searchReducer,
  toasts: toastReducer,
  result: resultReducer,
  breadcrumb: breadcrumbReducer,
  viewer: viewerReducer,
  login_modal: loginModalReducer,
  my_library: myLibraryReducer,
  tags: tagsReducer,
  content: contentReducer
})

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}

const store = configureStore({
  reducer: rootReducer
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>
