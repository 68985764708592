import {ISearchState} from "../../types/redux/search";
import SearchUtils from "../../utils/search-utils";
import * as Constants from "../../constants";

export const queryMultipleRecords = (searchState: ISearchState, dbSet: string): string => {
  let queryString = '';

  const recordsString: string = SearchUtils.convertSearchQuery(searchState.query);
  queryString+='q=('+recordsString + ')';

  /* date format */
  queryString+='&wt=json';

  /* results per page*/
  const resultsPerPage: number = searchState.results_per_page;
  queryString+='&rows='+resultsPerPage;

  /* search-paging - none */
  queryString+='&start=0'; //+startRow; // search-paging is done when requesting saved documents, this request just fills in data for render

  if (dbSet === Constants.BIBLIOGRAPHY) {
    queryString+='&facet=true';
    queryString+='&facet.mincount=0'
    queryString+='&sort=score+desc,dateaddediso+desc,id+asc'
    queryString+='&facet.field=industry_facet';
    queryString+='&facet.field=status_facet';
    queryString+='&facet.field=category_facet';
    queryString+='&facet.field=referencetype_facet';
  }

  return queryString
}
