import './styles.scss';
import React, {Dispatch, useContext, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import {ITagModalContext, TagsModalContext} from "../../../../types/saved";
import {IRecordsContext, RecordsContext} from "../../../../types/document";

/* Bootstrap */
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";

/* Redux */
import {ISavedState, ITagState, TSavedDocument, TSavedDocumentTag} from "../../../../types/redux/saved";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import {AnyAction} from "@reduxjs/toolkit";
import {addTags, changeTagText} from "../../../../redux/slices/tags-notes-slice";
import {addToast} from "../../../../redux/slices/toast-slice";
import {updateSavedDocumentTags} from "../../../../redux/slices/saved-slice";

/* api */
import TagsNotesService from "../../../../api/tags-notes";
import SavedService from "../../../../api/saved";

interface IProps {
  close(): void;
}

const TagEdit = (props: IProps):JSX.Element => {
  const {
    close
  } = props;

  const dispatch:Dispatch<AnyAction> = useDispatch();
  const selectedRecords: any = useContext<IRecordsContext>(RecordsContext).records;
  const savedDocuments: TSavedDocument[] = useSelector<RootState, ISavedState>(state => state.saved).documents;

  const tagsState: ITagState = useSelector<RootState, ITagState>(state => state.tags);
  const tag: TSavedDocumentTag | null = useContext<ITagModalContext>(TagsModalContext).selectedTag;

  const [checked, setChecked] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [documentUsedIn, setDocumentUsedIn] = useState<string[]>([]);
  const [tagName, setTagName] = useState<string>('');

  useEffect(()=> {
    if (!tag) return;
    const recordsUsedIn = async (): Promise<any> => {
      const response: any = await SavedService.getSavedDocumentsViaTags(tag.id);
      const savedDocumentIds = response.savedDocument.map((savedDocument: TSavedDocument) => String(savedDocument.id));
      setDocumentUsedIn(savedDocumentIds)
    }

    setTagName(tag.text);
    recordsUsedIn();
  }, [tag])

  const handleKeydown = (event: any): void => {
    if (event.key === Constants.ENTER) {
      handleSubmit(event)
    }
  }

  const applyChangeToAll = (tag:TSavedDocumentTag): Promise<any> =>{
    return TagsNotesService.updateTag(String(tag.id), tagName).then(response=>{
      /* redux update tag name*/
      dispatch(changeTagText({id:String(tag.id), text: tagName}));

      /* redux update all references in documents */
      const updatedTag: TSavedDocumentTag = {...tag};
      updatedTag.text = tagName;
      dispatch(updateSavedDocumentTags({ savedDocumentIds: documentUsedIn, savedTags: [updatedTag], action: Constants.UPDATE}))
    }).catch(e=>{
      dispatch(addToast({
        bg: 'bg-danger',
        msg: 'Sorry, an error occurred',
        id: -1
      }))
    });
  }

  const applyChangeToSelected = async (tag:TSavedDocumentTag): Promise<any> =>{
    /* update tag for all records (keep color and position )*/
    await applyChangeToAll(tag);

    /* find all other records currently being displays also have tag but not selected*/
    const savedDocumentIdsObjs: any[] = [];
    savedDocuments.forEach((savedDocument:TSavedDocument)=>{
      if (!selectedRecords.find((record:any) => (record.id === savedDocument.documentId))) {
        // not selected but saved
        if (savedDocument.tags.findIndex(savedTag => savedTag.id === tag.id) !== -1) {
          // using this tag
          savedDocumentIdsObjs.push({id:String(savedDocument.id)})
        }
      }
    })

    if (savedDocumentIdsObjs.length > 0) {
      /* Create a new tag an apply to subset of save records */
      TagsNotesService.createTag(tag.text, savedDocumentIdsObjs).then(response=>{
        dispatch(addTags(response));
        /* Add new tag to records subset */
        const savedDocumentIds: string[] = savedDocumentIdsObjs.map(savedDocumentIdObj => savedDocumentIdObj.id)
        dispatch(updateSavedDocumentTags({ savedDocumentIds: savedDocumentIds, savedTags: response, action: Constants.ADD}))

        /* remove old tag from records subset */
        dispatch(updateSavedDocumentTags({ savedDocumentIds: savedDocumentIds, savedTags: [tag], action: Constants.REMOVE}))
      }).catch(e=>{
        dispatch(addToast({
          bg: 'bg-danger',
          msg: 'Sorry, an error occurred',
          id: -1
        }))
      });
    }
  }

  const handleSubmit = (event: any): void => {
    event.preventDefault();
    event.stopPropagation();

    if (!tag) return;
    setSubmitted(true)

    if (tagName.toLowerCase() !== tag.text.toLowerCase()) {
      const existingTag: TSavedDocumentTag | undefined = tagsState.tags.find((tag:TSavedDocumentTag) => tag.text.toLowerCase() === tagName.toLowerCase());
      if (!existingTag) {
        if (checked === Constants.APPLY_ALL) {
          applyChangeToAll(tag);
        } else {
          applyChangeToSelected(tag)
        }
        close();

      } else {
        dispatch(addToast({
          bg: 'bg-danger',
          msg: 'Please choose another name, this one already exists.',
          id: -1
        }))
      }
    }

  }

  return (
    <div className={'tag-remove w-100'}>
      {/* header */}
      <h6 className={'pt-2 pb-1 px-0'}>
        <b>Edit tag label</b>
      </h6>

      <hr/>

      {/* New Tag name input */}
      <Row>
        <Col lg={9}  className={'mt-3 pe-3 text-center'}>
          <Form.Control
            required
            type={'text'}
            placeholder={'Add a tag'}
            value={tagName}
            maxLength={100}
            onKeyDown={handleKeydown}
            onChange={e=>setTagName(e.target.value)}
          />
        </Col>
      </Row>

      <Form className={'my-3 text-normal'} noValidate onSubmit={e => handleSubmit(e)}>
        <InputGroup hasValidation>
          <Form.Check
            type={'radio'}
            label={<div className={'pt-1'}>Apply change to this record only</div>}
            id={'remove-record-only'}
            checked={checked === Constants.APPLY_ONLY}
            onChange={e=>setChecked(Constants.APPLY_ONLY)}
          />

          <Form.Check
            className={'mt-1 mb-2'}
            type={'radio'}
            label={<div className={'pt-1'}>
              <span className={'me-1'}>Apply everywhere</span>
              <b>({documentUsedIn.length}{(documentUsedIn.length === 1) ? ' record' : ' records'})</b>
            </div>}
            id={'remove-everywhere'}
            checked={checked === Constants.APPLY_ALL}
            onChange={e=>setChecked(Constants.APPLY_ALL)}
          />

          <Form.Control.Feedback type={'invalid'} className={'mt-1 mb-3 text-normal ' + ((submitted && (checked === null)) ? 'show' : '')}>
            Please select one of the options above.
          </Form.Control.Feedback>
        </InputGroup>
        <hr/>

        <div className={'d-flex justify-content-end pt-3'}>
          <Button
            variant={'secondary border-0 me-2'}
            onClick={close}
          >
            Cancel
          </Button>

          <Button 
            type={'submit'}
            variant={'primary'}
            disabled={!checked}
          >
            Apply
          </Button>
        </div>
      </Form>

    </div>
  )
}
export default TagEdit;
