import {IResultsState, UpdateResultAction} from "../../types/redux/result";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import * as Constants from "../../constants";
import Utils from "../../utils";

const searchParams: URLSearchParams = new URLSearchParams(window.location.search);

const initialState: IResultsState = {
  results: [],
  expand_all: false,
  /* @ts-ignore */
  selected: searchParams.get(Constants.ID) ? [{
    id: searchParams.get(Constants.ID),
    index: -1
  }] : [],
  /* @ts-ignore */
  initial_selection: searchParams.get(Constants.INITIAL_ID) ? searchParams.get(Constants.INITIAL_ID) : null,
  searchResultMeta: null,
  facet_fields: null,
  facet_pivot: null,
  facet_ranges: null
};

export const resultSlice = createSlice({
  name: UpdateResultAction,
  initialState: initialState,
  reducers: {
    setResults: (state: IResultsState, action: PayloadAction<any>) => {
      /* todo: jain - need reset function here - prone to bugs */
      const dbSet: any = action.payload.db_set;
      const data: any = action.payload.data;

      if (dbSet === Constants.DOCUMENTS) {
        const documents: any[] = JSON.parse(JSON.stringify(data.response.docs));
        for (let i=0; i<documents.length; i++) {
          if (data.highlighting) {
            documents[i].highlighting = data.highlighting[documents[i].id]
          }
        }
        state.results = documents;
      } else {
        state.results = data.response.docs;
      }
      state.searchResultMeta = {num_found: data.response.numFound};
    },

    setResultsFacetFields: (state: IResultsState, action: PayloadAction<any>) => {
      const data: any = action.payload.data;
      state.facet_fields = data.facet_fields ? data.facet_fields : null;
      state.facet_pivot = data.facet_pivot ? data.facet_pivot : null;
      state.facet_ranges = data.facet_ranges ? data.facet_ranges : null
    },

    removeFromResults: (state: IResultsState, action: PayloadAction<string[]>) => {
      const origLength: number = state.results.length;
      state.results = Utils.removeFromList(state.results, action.payload, Constants.ID );
      if (state.searchResultMeta) {
        state.searchResultMeta.num_found = state.searchResultMeta.num_found - (origLength - state.results.length);
      }
    },

    clearFacets: (state: IResultsState) => {
      state.facet_fields = null;
      state.facet_pivot = null;
      state.facet_ranges = null
    },

    clearResults: (state: IResultsState) => {
      state.results = [];
      state.initial_selection = null;
      state.searchResultMeta = null;
    },

    setExpandAll: (state: IResultsState, action: PayloadAction<boolean>) => {
      state.expand_all = action.payload;
    },

    clearSelectedResults: (state: IResultsState) => {
      state.selected = []
    },

    /* Document viewer Bates, Browse, 'More Like This' do not update on selections and are based on the initial selection */
    setInitialSelection: (state: IResultsState, action: PayloadAction<string>) => {
      state.initial_selection = action.payload;
    },

    /*
      NOTE: Index of selected record in state.results is needed to facilitate search-paging in the viewer list
    */
    setSelectedResults: (state: IResultsState, action: PayloadAction<string[]>) => {
      state.selected = action.payload.map(id => {
        return {
          id: id,
          index: state.results.findIndex(result => result.id === id)
        }
      })
    },

    addToSelectedResults: (state: IResultsState, action: PayloadAction<string[]>) => {
      action.payload.forEach(selectedId => {
        state.selected.push({
          id: selectedId,
          index: state.results.findIndex(result => result.id === selectedId)
        });
      })
    },

    removeFromSelectedResults: (state: IResultsState, action: PayloadAction<string[]>) => {
      state.selected = Utils.removeFromList(state.selected, action.payload, Constants.ID )
    },

    updateSearchResult: (state: IResultsState, action: PayloadAction<any>) => {
      const index:number = state.results.findIndex((result: any) => result.searchDateTime === action.payload.searchDateTime);
      if (index !== -1) {
        state.results[index] = action.payload;
      }
    },
  }
});

export const {
  setResults,
  setResultsFacetFields,
  removeFromResults,
  clearResults,
  updateSearchResult,
  setExpandAll,
  setInitialSelection,
  clearSelectedResults,
  setSelectedResults,
  addToSelectedResults,
  removeFromSelectedResults,
  clearFacets
} = resultSlice.actions;
export default resultSlice.reducer;
