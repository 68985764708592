import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../constants";
import {PageContext} from "../../types/app";
import Utils from "../../utils";

/* Bootstrap */
import {Col, Container, Row} from "react-bootstrap";

/* Redux */
import {ISearchState} from "../../types/redux/search";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {AnyAction} from "@reduxjs/toolkit";
import {addCrumb} from "../../redux/slices/breadcrumb-slice";

/* Components */
import ViewSubheader from "../../components/view/view-subheader";
import ViewScrollContainer from "../../components/view/view-scroll-container";
import NewsView from "./components/news";

/* Hooks */
import ContentPage from "./components/page";

interface IProps {
  context: string
}

const Content = (props: IProps):JSX.Element => {
  const {
    context
  } = props;

  const searchState: ISearchState = useSelector<RootState, ISearchState>((state: RootState):any => state.search);
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const [heading, setHeading] = useState(props.context);
  const [hideSubHeader, setHideSubHeader] = useState<boolean>(false)

  useEffect(()=> {
    let _heading: string = Utils.toTitleCase(context);
    let level: number = 2;
    if (context === Constants.CATEGORY) {
      const { section} = Utils.parseIndustrySection();
      _heading = Utils.toTitleCase(section);
      _heading = Utils.capitalizeWord(_heading, 'idl'); // special case - capitalize IDL in heading
      level = 1;
    }

    if (level === 1) {
      dispatch(addCrumb({
        label: _heading,
        href: '/' + _heading.toLowerCase().replaceAll(' ', '-') + '/',
        level: 1,
        active: true
      }));
    }
    setHeading(_heading);

    if (context === Constants.NEWS) {
      setHideSubHeader(Utils.parseNewsArticleId()!== null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchState.industry, context, window.location.pathname])

  return (
    <div className={'content-view view'}>
      <PageContext.Provider value={{context: Constants.CONTENT}}>
        <ViewScrollContainer>
          {!hideSubHeader && <ViewSubheader
            heading={heading}
          />}

          <Container className={"page-container" + (hideSubHeader? " no-header" : "")}>
            <Row>
              <Col lg={12}>
                {context === Constants.NEWS ? <NewsView/> : <ContentPage />}
              </Col>
            </Row>
          </Container>
        </ViewScrollContainer>
      </PageContext.Provider>
    </div>
  )
}

export default Content;
