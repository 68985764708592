import './styles.scss';
import React from "react";

/* Bootstrap */
import {NavLink} from "react-router-dom";

const HeaderFeedback = ():JSX.Element => {
  return (
    <div className={'header-feedback bg-blue-med w-100 text-center text-white text-normal'}>
      <div className={'content-text'}>
        <span className={'me-2'}>Welcome to industrydocuments.ucsf.edu's beta phase.</span>
        <NavLink to="/about" className={'text-white'}>
          <b>Learn more</b>
        </NavLink>
        <span>&nbsp;or&nbsp;</span>
        <NavLink to="" onClick={()=>window.open('https://ucsf.co1.qualtrics.com/jfe/form/SV_eh4s1FyneKjbIzk', '_blank')} className={'text-white '}>
          <b>share your feedback</b>
          <i className={'bi bi-box-arrow-up-right ms-1 '}/>
        </NavLink>

        <span>&nbsp;with us.</span>
      </div>
    </div>
  )
}

export default HeaderFeedback;
