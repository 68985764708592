import './styles.scss';
import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";

/* Bootstrap */
import {Container, Nav, Navbar} from "react-bootstrap";

/* Components */
import MyLibraryDropdown from "../my-library-dropdown";

const HeaderIDL = ():JSX.Element => {
  const [toggleActive, setToggleActive] = useState<boolean>(false);
  const navbarToggleRef:React.MutableRefObject<any> = useRef(null);
  const activeRef = useRef<HTMLInputElement | null>(null);
  const dropdownRef = useRef<HTMLInputElement | null>(null);


  /* hooks */
  const navigate = useNavigate();

  /* todo: jain - bug select when collapsed, expand - selector is off */

  useEffect(()=> {
    if (window.location.pathname !== Constants.MY_LIBRARY_PATHNAME) {
      if (activeRef.current) {
        activeRef.current.classList.add('hidden');
      }
    } else {
      handleClick(dropdownRef.current)
    }
  })

  const handleToggle = ():void => {
    if (toggleActive) {
      navbarToggleRef.current.blur();
    }
    setToggleActive(!toggleActive);
  }

  const handleClick = (target:any): void => {
    if (activeRef.current) {
      activeRef.current.classList.remove('hidden');
      activeRef.current.style.left = target.offsetLeft  + 'px';
      activeRef.current.style.width = target.clientWidth + 'px'
    }
  }

  const navLink = (label: string, link: string): JSX.Element => {
    return <Nav.Link
      onClick={e=>handleClick(e.currentTarget)}
      className={"py-1 pe-4 text-xnormal"} as={Link} to={link}
    >
      {label}
    </Nav.Link>
  }

  return (
    <div className={'header-idl bg-white'}>
      <Navbar className={"pt-lg-4 pb-lg-3 "} expand="lg" onToggle={handleToggle}>
        <Container>
          <h1
            className={'d-inline-block text-black font-thin p-0 m-0'}
            role={'button'}
            onClick={()=>{
              navigate('/home')
            }}
          >
            Industry Documents Library
          </h1>

          <Navbar.Toggle aria-controls={"header-ucsf-navbar-nav"} ref={navbarToggleRef}/>
          <Navbar.Collapse id="header-navbar-nav" className={"justify-content-end"}>
            <Nav className={"px-1 mt-2"}>
              {/* todo: jain - move to constants */}
              {navLink('Industries', '/industries')}
              {navLink('News', '/news')}
              {navLink('Resources', '/resources')}
              {navLink('About IDL', '/about-idl')}
              {navLink('Help', '/help')}
              <div ref={dropdownRef} className={"py-1"} >
                <MyLibraryDropdown/>
              </div>
            </Nav>
            <div ref={activeRef} className={'active_select'}/>
          </Navbar.Collapse>

        </Container>
      </Navbar>
    </div>
  )
}

export default HeaderIDL;
