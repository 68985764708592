import './styles.scss';
import React, {useState} from "react";

import {Button} from "react-bootstrap";

import * as Constants from "../../../../constants";
import {TAdvSearchDate} from "../../../../constants";
import DateField from "../date-field";

interface IProps {
  data: TAdvSearchDate;
  updateDates(data: any): void;
  closePickers:boolean;
  setClosePickers(value: boolean): void;
}

const DatePicker = (props: IProps):JSX.Element => {
  const {
    data,
    updateDates,
    closePickers
  } = props;

  const [error, setError] = useState<boolean>(false);

  return (
    <div className={'date-picker position-relative mt-4'}>
      <p className={'mb-1'}>{data.label}</p>
      <div className={'border rounded text-nowrap d-flex'}>
        <div className={'field-container d-flex justify-content-evenly w-100'}>

          <DateField
            {...props}
            closePickers={closePickers}
            dataKey={'start'}
            placeholder={Constants.START_DATE_PLACEHOLDER}
            setError={setError}
          />

          <div className={'mt-1 pt-2 mx-1'}>
            <i className={'bi bi-arrow-right'}/>
          </div>

          <DateField
            {...props}
            dataKey={'end'}
            placeholder={Constants.END_DATE_PLACEHOLDER}
            setError={setError}
          />

        </div>

        {/* Clear Range button-link */}
        {(data.start || data.end) &&
          <Button
              className={'clear-btn btn-transparent border-0 px-1 py-0 my-2 me-1'}
              onClick={e=> {
                e.stopPropagation();
                data.start = null;
                data.end = null;
                updateDates(data);
              }}
          >
            <i className={'bi bi-x text-black'}/>
          </Button>}
      </div>

      {error &&
        <p className={'p-0 mt-2 text-danger text-small'}>Please enter a valid date (MM/DD/YYYY)</p>}

    </div>
  )
}

export default DatePicker;
