import './styles.scss';
import React, {useState} from "react";

/* Bootstrap */
import Form from 'react-bootstrap/Form';
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";

interface IProps {
  label: string,
  password: string
  setPassword(value: string):void;
  handleKeydown(e:any): void;
  errorMessage: string;
  isInvalid(password: string): boolean;
}

const PasswordField = (props: IProps):JSX.Element => {
  const {
    label,
    password,
    setPassword,
    handleKeydown,
    isInvalid,
    errorMessage
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
      <div className={'password-field position-relative'}>
        <Form.Label className={'mb-1 font-light text-secondary'}>{label}<sup>*</sup></Form.Label>
        <Form.Control
          required={true}
          type={showPassword ? 'text': 'password'}
          value={password}
          isInvalid={isInvalid(password)}
          onChange={e=>setPassword(e.target.value)}
          onKeyDown={handleKeydown}
        />

        <OverlayTrigger overlay={
          <Tooltip >{showPassword ? 'Hide password' : 'Show password'}</Tooltip>
        }>
          <Button
            className={'btn-transparent text-grey-darker border-0 position-absolute py-1 px-2'}
            onClick={()=>setShowPassword(!showPassword)}>
            {(showPassword ? <i className={'bi-eye-slash-fill'}/>: <i className={'bi bi-eye-fill'}/>)}
          </Button>
        </OverlayTrigger>

        <Form.Control.Feedback type={'invalid'}>
          {errorMessage}
        </Form.Control.Feedback>
      </div>
  )
}
export default PasswordField;
